import { useState, useEffect } from "react";
import {
  Title,
  Space,
  Box,
  Button,
  Text,
  Modal,
  Grid,
  Flex,
} from "@mantine/core";
import { useLazyQuery } from "@apollo/client";
import JSZip from "jszip";

import BoxOrderView from "./BoxOrderView";
import ItemsDescription from "./itemDescription";
import TranslateCommentModal from "./modals/translateCommentModal";
import { STYLE } from "../types/constants";
import {
  File as SharedFile,
  Order,
  Product,
  User,
  OrderComment,
} from "../types/interfaces";
import { ItemType, FileType, UserType } from "../types/enums";
import { isUserStaff } from "../utils/user.utils";
import {
  get3OXZFile,
  getTraceabilitiesFile,
  getImagesFile,
} from "../utils/file.utils";
import { socket } from "../../labs/src/socket";

import { GET_FILE_DOWNLOAD_URL } from "../gql/files";
import { useFiles } from "../utils/useFiles";
import { IconCloudUpload } from "@tabler/icons-react";
import { FileList } from "../stories/FileList/FileList";
import { Dropzone } from "@mantine/dropzone";
import DoctorNotes from "./DoctorNotes";

interface OrderViewOrderDetailProps {
  order: Order;
  selectedProduct: Product | null;
  setSelectedProduct: (product: Product | null) => void;
  user: User;
  refetch: () => void;
  fileWithPath: SharedFile[];
  initialOrderComments: any[];
  setPictureToMarkAsRead: (picture: any) => void;
}

interface NewHelpMessageEvent {
  orderComment: OrderComment;
  order: Order;
  files: SharedFile[];
}

const OrderViewOrderDetail = ({
  order,
  selectedProduct,
  setSelectedProduct,
  user,
  refetch,
  fileWithPath,
  initialOrderComments,
  setPictureToMarkAsRead,
}: OrderViewOrderDetailProps) => {
  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [commentToTranslate, setCommentToTranslate] = useState<any>(null);
  const [allFiles, setAllFiles] = useState<SharedFile[]>([]);
  const [isFilesLoading, setIsFilesLoading] = useState<boolean>(false);
  const [orderCommentList, setOrderCommentList] = useState<OrderComment[]>(
    initialOrderComments ?? []
  );

  const [getFileDownloadUrl] = useLazyQuery(GET_FILE_DOWNLOAD_URL);

  const getPresignedDownloadUrl = async (key: string) => {
    const res = await getFileDownloadUrl({ variables: { key } });
    window.open(res?.data?.getPresignedFileDownloadUrl, "_blank");
  };

  const { uploadFileToS3, deleteOneFile } = useFiles(order.id);

  const createFileFromUrl = async (url: string) => {
    const req = await fetch(url);
    const data = await req.blob();
    const metadata = {
      type: "application/x-zip-compressed",
    };
    return new File([data], "case.zip", metadata);
  };

  const getStaffFiles = (files: SharedFile[]): SharedFile[] => {
    return files.filter(
      file => file.fileType.toLowerCase() !== FileType.CHAT_FILE.toLowerCase()
    );
  };

  const getDCM = async (allFiles: SharedFile[]): Promise<SharedFile[]> => {
    const orderFiles = allFiles.filter(
      file =>
        file.fileType.toLowerCase() ===
        FileType.ORDER_FILE.toLowerCase().replace("_", "")
    );
    if (!orderFiles) {
      return [];
    }
    const output: SharedFile[] = [];
    await Promise.all(
      orderFiles.map(async element => {
        if (element.key.toLowerCase().includes("3oxz")) {
          const zip = new JSZip();
          const zipfile = await createFileFromUrl(element.url);
          const unzip = await zip.loadAsync(zipfile);
          await Promise.all(
            Object.keys(unzip.files).map(async (zipname, index) => {
              const zipnamesplit = zipname.split(".");
              if (
                zipnamesplit[zipnamesplit.length - 1].toLowerCase() === "dcm"
              ) {
                const file = await Object.values(unzip.files)[index];
                const blogFile = await file.async("blob");
                const blob = new Blob([blogFile], { type: "octet/stream" });
                output.push({
                  url: window.URL.createObjectURL(blob),
                  key: zipname,
                  id: zipname,
                } as SharedFile);
              }
            })
          );
        }
      })
    );
    return output;
  };

  const loadFiles = async () => {
    setIsFilesLoading(true);
    const staffFiles = await getStaffFiles(fileWithPath);
    const dcmFiles = await getDCM(fileWithPath);
    setAllFiles([...staffFiles, ...dcmFiles]);

    setIsFilesLoading(false);
  };

  useEffect(() => {
    loadFiles();
  }, [JSON.stringify(fileWithPath), fileWithPath.length]);

  useEffect(() => {
    socket.emit("joinRoom", { room: "help-" + order.id });
    return () => {
      socket.emit("leaveRoom", { room: "help-" + order.id });
    };
  }, []);

  useEffect(() => {
    setOrderCommentList(initialOrderComments);
  }, [initialOrderComments]);

  useEffect(() => {
    socket.on("helpMessage", (newHelpMessage: NewHelpMessageEvent) => {
      if (newHelpMessage) {
        const newOrderComment: OrderComment = newHelpMessage.orderComment;
        newOrderComment.order = newHelpMessage.order ?? {};
        newOrderComment.files = newHelpMessage.files ?? [];

        setOrderCommentList(oldOrderCommentList => [
          ...oldOrderCommentList,
          newOrderComment,
        ]);
      }
    });
    return () => {
      socket.off("helpMessage");
    };
  }, [order.id]);

  return (
    <>
      <Modal
        opened={openTranslationModal}
        onClose={() => setOpenTranslationModal(false)}
      >
        <TranslateCommentModal
          order={order}
          closeModal={() => setOpenTranslationModal(false)}
          refetch={refetch}
          comment={commentToTranslate}
        />
      </Modal>
      <BoxOrderView
        span={7}
        minHeight={54}
      >
        <Title
          order={4}
          style={{
            color: STYLE.primary,
          }}
        >
          {`Order detail${
            order?.products && order?.products.length > 1
              ? " (clic on a product to select it)"
              : ""
          }`}
        </Title>
        <Box>
          <Space h="md" />
          <Box>
            {order?.products &&
              (order.products ?? []).map((product: Product) => (
                <Box key={product.id}>
                  <Button
                    variant={
                      selectedProduct?.id === product.id ? "filled" : "outline"
                    }
                    color={
                      selectedProduct?.id === product.id
                        ? STYLE.primary
                        : "black"
                    }
                    styles={{
                      inner: { justifyContent: "start" },
                    }}
                    h="auto"
                    p={8}
                    mb="xs"
                    w="100%"
                    onClick={() => setSelectedProduct(product)}
                  >
                    <ItemsDescription
                      key={product.id}
                      product={product}
                      shouldTranslate={true}
                    />
                  </Button>
                  {isUserStaff(user) &&
                    selectedProduct &&
                    selectedProduct.id === product.id &&
                    selectedProduct?.removableItem &&
                    selectedProduct.removableItem[0] &&
                    selectedProduct.removableItem[0].itemType ===
                      ItemType.PARTIAL_DENTURE &&
                    selectedProduct.removableItem[0].dentistNotes && (
                      <>
                        <Text size="sm">
                          {selectedProduct.removableItem[0].dentistNotes !== ""
                            ? `Commentaire sur les crochets: ${selectedProduct.removableItem[0].dentistNotes}`
                            : "Commentaire sur les crochets: Aucun"}
                        </Text>
                        <Space h="sm" />
                      </>
                    )}
                </Box>
              ))}
          </Box>
          <Space h="xl" />
          <DoctorNotes
            user={user}
            orderCommentList={orderCommentList}
            orderStatus={order.status}
            setCommentToTranslate={setCommentToTranslate}
            setOpenTranslationModal={setOpenTranslationModal}
            getPresignedDownloadUrl={getPresignedDownloadUrl}
          />
        </Box>
        <Space h="xl" />
        <Title order={5}>Files</Title>
        <Grid gutter="sx">
          {isUserStaff(user) && (
            <>
              <Grid.Col
                key={"3OXZ"}
                span={6}
              >
                {get3OXZFile(allFiles).map(file => {
                  return (
                    <a
                      key={file.id}
                      download={file.key ?? file.name}
                      href={file.url}
                    >
                      OrderFile (3OXZ)
                    </a>
                  );
                })}
              </Grid.Col>
              <Grid.Col
                key={"traceability"}
                span={6}
              >
                {getTraceabilitiesFile(allFiles).map(file => (
                  <a
                    key={file.id}
                    download={file.key ?? file.name}
                    href={file.url}
                  >
                    {file.name ?? file.fileType} -{" "}
                    {(file?.createdAt
                      ? new Date(file?.createdAt)
                      : new Date()
                    ).toLocaleString()}{" "}
                    <br />
                  </a>
                ))}
              </Grid.Col>
              {[...getTraceabilitiesFile(allFiles), ...get3OXZFile(allFiles)]
                .length !== 0 && (
                <hr
                  style={{
                    width: "100%",
                    borderBottom: "2px solid rgb(67, 98, 208)",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                />
              )}
            </>
          )}
          <Grid.Col
            style={{ marginTop: "15px" }}
            key={"pictures"}
            span={12}
          >
            <FileList
              files={getImagesFile(allFiles)}
              deleteOneFile={deleteOneFile}
              refetch={refetch}
              deleteFilter={[FileType.LABS_FILE]}
              showTitle={false}
              blueLink={true}
              notificationFilter={[FileType.ORDER_ADDON_FILE]}
              onclick={setPictureToMarkAsRead}
            />
          </Grid.Col>
        </Grid>

        {isFilesLoading && <Text>Loading files...</Text>}
        {user.type !== UserType.SUPPLIER && (
          <Dropzone
            accept={{
              "image/*": [],
              "application/dicom": [".DCM", ".dcm"],
              "model/stl": [".stl", ".STL"],
            }}
            onDrop={async files => {
              await uploadFileToS3([...files], FileType.LABS_FILE);
              refetch();
            }}
            style={{
              border: "2px dashed gray",
              borderRadius: "5px",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <Flex
              justify="center"
              align="center"
            >
              <IconCloudUpload />
              <Box ml="sm">
                <Text
                  size="sm"
                  style={{ fontWeight: "bold" }}
                >
                  {" "}
                  {"Add a file to this order"}
                </Text>
              </Box>
            </Flex>
          </Dropzone>
        )}
      </BoxOrderView>
    </>
  );
};

export default OrderViewOrderDetail;
