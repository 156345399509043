import { useState, useEffect } from "react";
import { Text, Space, Button } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";

import SelectorButtonRef from "../SelectorButtonRef";
import SelectInputRef from "../SelectInputRef";
import ShadeSelection from "../shadeSelection";
import { TeethShadeType, TeethShadeSide, ItemType } from "../../types/enums";
import { STYLE, YES_OR_NO_CHOICE } from "../../types/constants";
import {
  GET_SCAN_POST_LIST,
  DELETE_MANY_ANATOMY_ITEMS,
  CREATE_ANATOMY_ITEM,
} from "../../gql/items";
import {
  formatChoicesData,
  deduceShadeType,
  checkisInlayCoreOnly,
  INLAY_CORE_SCAN_POST,
} from "../../utils/item.utils";
import {
  removeTeethFromBridgeOnInlayCore,
  getAllNonSplintedCrown,
  getAllPossibleSplintedCrown,
  isBridgeOnInlayCore,
} from "../../utils/product.utils";

const CreateNewInlayCoreComponent = ({
  productType,
  order,
  product_id,
  refetch,
  materialFormChoices,
  anatomyItemChoicesData,
  accountProductPreferences,
}) => {
  const [deleteManyAnatomyItem] = useMutation(DELETE_MANY_ANATOMY_ITEMS);
  const [createAnatomyItem] = useMutation(CREATE_ANATOMY_ITEM);

  const navigate = useNavigate();
  const location = useLocation();

  const savedItem = (location?.state?.product?.anatomyItem ?? []).find(
    item => item.itemType === ItemType.INLAY_CORE
  );
  const savedCrownItem = (location?.state?.product?.anatomyItem ?? []).find(
    item =>
      item.itemType === ItemType.CROWN ||
      item.itemType === ItemType.SPLINTED_CROWN
  );

  const product = (order?.products ?? []).find(
    product => product.id === product_id
  );

  const isNotBridgeOnInlayCore =
    !isBridgeOnInlayCore(order?.products ?? [], product) ||
    productType === ItemType.BRIDGE;

  const { data: getScanPostsData, loading: getScanPostsDataLoading } =
    useQuery(GET_SCAN_POST_LIST);

  const rhf = useForm({
    defaultValues: {
      isInlayCoreClaveted: savedItem?.inlayCoreHasClavette ?? false,
      inlayCoreMaterial: savedItem?.inlayCoreMaterial?.id,
      inlayCoreScanPost: savedItem?.inlayCoreScanPost,
      material: savedItem?.itemMaterial?.id,
      isMultiShade:
        savedCrownItem?.teethshadeType === TeethShadeType.MULTI_SHADE,
      gingivalShade:
        (savedCrownItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.GINGIVAL
        )?.itemShade?.id ?? undefined,
      baseShade:
        (savedCrownItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.BASE
        )?.itemShade?.id ?? undefined,
      incisalShade:
        (savedCrownItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.INCISAL
        )?.itemShade?.id ?? undefined,
      shade: savedItem?.itemShade?.id,
      inlayCoreScanPostType: savedItem?.inlayCoreScanPost
        ? savedItem?.inlayCoreScanPost.split("_")[0]
        : undefined,
      isInlayCoreOnly: checkisInlayCoreOnly(product, isBridgeOnInlayCore),
      isRichmondCrown: (location?.state?.product?.anatomyItem ?? []).find(
        item => item.itemType === ItemType.RICHMOND_CROWN
      )
        ? true
        : false,
      shadeType: deduceShadeType(
        savedItem?.itemShade?.id,
        order?.user?.id,
        accountProductPreferences
      ),
      isSplintedCrown: (location?.state?.product?.anatomyItem ?? []).find(
        item => item.itemType === ItemType.SPLINTED_CROWN
      )
        ? true
        : false,
    },
  });

  const watchInlayCoreClaveted = rhf.watch("isInlayCoreClaveted");
  const watchIsInlayCoreOnly = rhf.watch("isInlayCoreOnly");
  const watchIsRichmondCrown = rhf.watch("isRichmondCrown");
  const watchInlayCoreScanPostType = rhf.watch("inlayCoreScanPostType");
  const watchShadeType = rhf.watch("shadeType");
  const watchIsSplintedCrown = rhf.watch("isSplintedCrown");
  const watchIsMultiShade = rhf.watch("isMultiShade");
  const watchCrownMaterial = rhf.watch("material");

  const inlayCoreMaterialFormChoices = formatChoicesData(
    (anatomyItemChoicesData?.getItemMaterialsWhere ?? []).filter(
      (m: any) => m.inlayCore === true
    ),
    watchInlayCoreClaveted
  );

  const deduceItemTypeFromOptions = (
    data: AnatomyItemFormData,
    product: ProductFromGetOrderByUniqueAttributeQuery
  ) => {
    if (data.isInlayCoreOnly) {
      return ItemType.INLAY_CORE;
    }
    if (data.isRichmondCrown) {
      return ItemType.RICHMOND_CROWN;
    }
    return productType ?? undefined;
  };

  const onSubmit = async data => {
    const scanPostSelected =
      watchInlayCoreScanPostType === "ANATOMICAL"
        ? watchInlayCoreScanPostType
        : (data?.inlayCoreScanPost ?? watchInlayCoreScanPostType);
    await deleteManyAnatomyItem({
      variables: {
        where: {
          productId: {
            equals: product_id,
          },
        },
      },
    });
    const allNonPossibleSplintedCrown: number[] =
      removeTeethFromBridgeOnInlayCore(
        getAllNonSplintedCrown(product?.teeth ?? []),
        order?.products ?? []
      );
    const allSplinteCrown: number[][] = getAllPossibleSplintedCrown(
      product?.teeth ?? []
    );
    const productToUpdate = [];
    if (
      data.isSplintedCrown &&
      !data.isInlayCoreOnly &&
      isNotBridgeOnInlayCore
    ) {
      allSplinteCrown.map(splintedCrown => {
        const splintedCrownTeeth = removeTeethFromBridgeOnInlayCore(
          splintedCrown,
          order?.products ?? []
        );
        if (splintedCrownTeeth.length > 0) {
          productToUpdate.push({
            inlayCoreHasClavette: data.isInlayCoreClaveted,
            inlayCoreScanPost: scanPostSelected,
            bridgeType: data.bridgeType ?? undefined,
            inlayCoreMaterial: data.inlayCoreMaterial
              ? {
                  connect: {
                    id: data.inlayCoreMaterial,
                  },
                }
              : undefined,
            itemMaterial:
              data.material && !watchIsInlayCoreOnly
                ? {
                    connect: {
                      id: data.material,
                    },
                  }
                : undefined,
            itemShade:
              data.shade && !watchIsMultiShade
                ? {
                    connect: {
                      id: data.shade,
                    },
                  }
                : undefined,
            itemType: ItemType.SPLINTED_CROWN,
            teeth: splintedCrownTeeth,
            product: {
              connect: {
                id: product_id,
              },
            },
            teethshadeType: watchIsMultiShade
              ? TeethShadeType.MULTI_SHADE
              : TeethShadeType.SINGLE_SHADE,
            multiShadeInfo: watchIsMultiShade
              ? {
                  createMany: {
                    data: [
                      ...(data.gingivalShade
                        ? [
                            {
                              itemShadeId: data.gingivalShade,
                              teethShadeSide: TeethShadeSide.GINGIVAL,
                            },
                          ]
                        : []),
                      ...(data.baseShade
                        ? [
                            {
                              itemShadeId: data.baseShade,
                              teethShadeSide: TeethShadeSide.BASE,
                            },
                          ]
                        : []),
                      ...(data.incisalShade
                        ? [
                            {
                              itemShadeId: data.incisalShade,
                              teethShadeSide: TeethShadeSide.INCISAL,
                            },
                          ]
                        : []),
                    ],
                  },
                }
              : undefined,
          });
        }
      });
      if (allNonPossibleSplintedCrown.length > 0 && isNotBridgeOnInlayCore) {
        productToUpdate.push({
          inlayCoreHasClavette: data.isInlayCoreClaveted,
          inlayCoreMaterial: data.inlayCoreMaterial
            ? {
                connect: {
                  id: data.inlayCoreMaterial,
                },
              }
            : undefined,
          itemShade:
            data.shade && !watchIsMultiShade
              ? {
                  connect: {
                    id: data.shade,
                  },
                }
              : undefined,
          inlayCoreScanPost: scanPostSelected ?? undefined,
          itemType: ItemType.CROWN ?? undefined,
          bridgeType: data.bridgeType ?? undefined,
          teeth: allNonPossibleSplintedCrown,
          itemMaterial:
            data.material && !watchIsInlayCoreOnly
              ? {
                  connect: {
                    id: data.material,
                  },
                }
              : undefined,
          product: {
            connect: {
              id: product.id,
            },
          },
          teethshadeType: watchIsMultiShade
            ? TeethShadeType.MULTI_SHADE
            : TeethShadeType.SINGLE_SHADE,
          multiShadeInfo: watchIsMultiShade
            ? {
                createMany: {
                  data: [
                    ...(data.gingivalShade
                      ? [
                          {
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.GINGIVAL,
                          },
                        ]
                      : []),
                    ...(data.baseShade
                      ? [
                          {
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.BASE,
                          },
                        ]
                      : []),
                    ...(data.incisalShade
                      ? [
                          {
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.INCISAL,
                          },
                        ]
                      : []),
                  ],
                },
              }
            : undefined,
        });
      }
    }
    const itemTypeToUpdate = deduceItemTypeFromOptions(data, product);
    if (productType === ItemType.CROWN ? !data.isSplintedCrown : true) {
      productToUpdate.push({
        inlayCoreHasClavette: data.isInlayCoreClaveted,
        inlayCoreMaterial: data.inlayCoreMaterial
          ? {
              connect: {
                id: data.inlayCoreMaterial,
              },
            }
          : undefined,
        itemShade:
          data.shade &&
          !watchIsMultiShade &&
          data.material !== "METAL_NON_PRECIOUS"
            ? {
                connect: {
                  id: data.shade,
                },
              }
            : undefined,
        inlayCoreScanPost: scanPostSelected ?? undefined,
        itemType: itemTypeToUpdate ?? undefined,
        bridgeType: data.bridgeType ?? undefined,
        teeth: product?.teeth ?? [],
        itemMaterial:
          data.material && !watchIsInlayCoreOnly
            ? {
                connect: {
                  id: data.material,
                },
              }
            : undefined,
        product: {
          connect: {
            id: product.id,
          },
        },
        teethshadeType:
          watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
            ? TeethShadeType.MULTI_SHADE
            : TeethShadeType.SINGLE_SHADE,
        multiShadeInfo:
          watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
            ? {
                createMany: {
                  data: [
                    ...(data.gingivalShade
                      ? [
                          {
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.GINGIVAL,
                          },
                        ]
                      : []),
                    ...(data.baseShade
                      ? [
                          {
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.BASE,
                          },
                        ]
                      : []),
                    ...(data.incisalShade
                      ? [
                          {
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.INCISAL,
                          },
                        ]
                      : []),
                  ],
                },
              }
            : undefined,
      });
    }
    const crownTeeths = removeTeethFromBridgeOnInlayCore(
      product?.teeth ?? [],
      order?.products ?? []
    );
    if (
      product.productType === ItemType.INLAY_CORE &&
      !data.isInlayCoreOnly &&
      !data.isRichmondCrown &&
      !data.isSplintedCrown &&
      isNotBridgeOnInlayCore &&
      crownTeeths.length > 0
    ) {
      productToUpdate.push({
        inlayCoreHasClavette: data.isInlayCoreClaveted,
        inlayCoreScanPost: scanPostSelected ?? undefined,
        bridgeType: data.bridgeType ?? undefined,
        inlayCoreMaterial: data.inlayCoreMaterial
          ? {
              connect: {
                id: data.inlayCoreMaterial,
              },
            }
          : undefined,
        itemMaterial:
          data.material && !watchIsInlayCoreOnly
            ? {
                connect: {
                  id: data.material,
                },
              }
            : undefined,
        itemShade:
          data.shade &&
          !watchIsMultiShade &&
          data.material !== "METAL_NON_PRECIOUS"
            ? {
                connect: {
                  id: data.shade,
                },
              }
            : undefined,
        itemType: ItemType.CROWN,
        teeth: crownTeeths,
        product: {
          connect: {
            id: product.id,
          },
        },
        teethshadeType:
          watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
            ? TeethShadeType.MULTI_SHADE
            : TeethShadeType.SINGLE_SHADE,
        multiShadeInfo:
          watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
            ? {
                createMany: {
                  data: [
                    ...(data.gingivalShade
                      ? [
                          {
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.GINGIVAL,
                          },
                        ]
                      : []),
                    ...(data.baseShade
                      ? [
                          {
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.BASE,
                          },
                        ]
                      : []),
                    ...(data.incisalShade
                      ? [
                          {
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.INCISAL,
                          },
                        ]
                      : []),
                  ],
                },
              }
            : undefined,
      });
    }
    await productToUpdate.map(async item => {
      await createAnatomyItem({
        variables: {
          args: item,
        },
      });
    });
    await refetch();
    navigate(`/orders/${order?.id}/edit/`);
  };

  useEffect(() => {
    rhf.setValue(
      "isInlayCoreOnly",
      checkisInlayCoreOnly(product, isBridgeOnInlayCore)
    );
  }, [product]);

  if (getScanPostsDataLoading) {
    return "... Loading ...";
  }

  return (
    <>
      <Text
        style={{ textAlign: "center" }}
        fw={700}
      >
        {savedItem?.id ? "Modification" : "Ajout"} d'un Inlay-Core
      </Text>
      <SelectorButtonRef
        label="Inlay-Core Claveté ?"
        name="isInlayCoreClaveted"
        data={YES_OR_NO_CHOICE}
        onSubmit={selectedValue =>
          rhf.setValue("isInlayCoreClaveted", selectedValue)
        }
        control={rhf.control}
        errors={rhf.formState.errors}
        watch={watchInlayCoreClaveted}
        required={true}
      />
      <SelectorButtonRef
        label="Inlay-Core seulement (sans couronne)?"
        name="isInlayCoreOnly"
        data={YES_OR_NO_CHOICE}
        onSubmit={selectedValue =>
          rhf.setValue("isInlayCoreOnly", selectedValue)
        }
        control={rhf.control}
        errors={rhf.formState.errors}
        watch={watchIsInlayCoreOnly}
        required={true}
      />
      {!watchIsInlayCoreOnly && (
        <SelectorButtonRef
          label="Fabrication d’une couronne Richmond?"
          name="isRichmondCrown"
          data={YES_OR_NO_CHOICE}
          onSubmit={selectedValue =>
            rhf.setValue("isRichmondCrown", selectedValue)
          }
          control={rhf.control}
          errors={rhf.formState.errors}
          watch={watchIsRichmondCrown}
          required={true}
        />
      )}
      <SelectorButtonRef
        label="Choisissez votre type de Scan Post"
        name="inlayCoreScanPostType"
        data={getScanPostsData?.getScanPostsData?.types ?? []}
        onSubmit={selectedValue =>
          rhf.setValue("inlayCoreScanPostType", selectedValue)
        }
        control={rhf.control}
        errors={rhf.formState.errors}
        watch={watchInlayCoreScanPostType}
        required={true}
      />
      {watchInlayCoreScanPostType !== INLAY_CORE_SCAN_POST.ANATOMICAL.value && (
        <SelectInputRef
          label="Choisissez le scan post utilisé"
          name="inlayCoreScanPost"
          data={
            watchInlayCoreScanPostType
              ? (
                  getScanPostsData?.getScanPostsData.scanPosts as {
                    type: string;
                    value: string;
                    label: string;
                  }[]
                ).filter(
                  scanPost => scanPost.type === watchInlayCoreScanPostType
                )
              : getScanPostsData?.getScanPostsData.scanPosts
          }
          control={rhf.control}
          errors={rhf.formState.errors}
          required={
            watchInlayCoreScanPostType !== INLAY_CORE_SCAN_POST.ANATOMICAL.value
          }
        />
      )}
      <SelectInputRef
        label="Matériau d'Inlay-Core"
        name="inlayCoreMaterial"
        data={inlayCoreMaterialFormChoices}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
      />
      {(productType === ItemType.INLAY_CORE ||
        productType === ItemType.CROWN) &&
        getAllPossibleSplintedCrown(product?.teeth ?? []).length > 0 &&
        !watchIsInlayCoreOnly &&
        !watchIsRichmondCrown &&
        isNotBridgeOnInlayCore && (
          <SelectorButtonRef
            label="Faut-il solidariser les couronnes ?"
            name="isSplintedCrown"
            data={YES_OR_NO_CHOICE}
            onSubmit={selectedValue =>
              rhf.setValue("isSplintedCrown", selectedValue)
            }
            control={rhf.control}
            errors={rhf.formState.errors}
            watch={watchIsSplintedCrown}
            required={true}
          />
        )}
      {!watchIsInlayCoreOnly && (
        <>
          <SelectInputRef
            label="Matériau de la couronne"
            name="material"
            data={materialFormChoices.filter(material => material?.crown)}
            control={rhf.control}
            errors={rhf.formState.errors}
            required={true}
          />
          {watchCrownMaterial !== "METAL_NON_PRECIOUS" && (
            <ShadeSelection
              watchShadeType={watchShadeType}
              control={rhf.control}
              errors={rhf.formState.errors}
              anatomyItemChoicesData={anatomyItemChoicesData}
              onSelect={selectedValue =>
                rhf.setValue("shadeType", selectedValue)
              }
              order={order}
              accountProductPreferences={accountProductPreferences}
              rhf={rhf}
              itemType={ItemType.INLAY_CORE}
              user={order?.user}
            />
          )}
        </>
      )}
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={rhf.handleSubmit(onSubmit)}
          style={{ backgroundColor: STYLE.primary }}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default CreateNewInlayCoreComponent;
