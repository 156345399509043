import { useEffect } from "react";
import { Space, Button } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";

import {
  TextInputRef,
  SelectInputRef,
  ItemType,
  WorkflowType,
  STYLE,
  TOGGLE_FEATURE,
  ANATOMY_ITEM_CHOICE_DATA,
  getAnatomyItemChoiceWhere,
  ShadeSelection,
  deduceShadeType,
} from "@jasper/shared";

import {
  GET_ALL_SHADES,
  CREATE_REMOVABLE_ITEM,
  UPDATE_REMOVABLE_ITEM,
} from "../../../gql/";

const CreateRemovableItemComponent = ({
  closeModal,
  productId,
  refetch,
  item,
  redirect,
  accountProductPreferences,
  order,
}) => {
  const rhf = useForm({
    defaultValues: {
      itemType: item?.itemType,
      teeth: item?.teeth?.join(","),
      teethToManufacture: item?.teethToManufacture?.join(","),
      material: item?.itemMaterial?.id,
      shade: item?.teethShade?.id,
      gingivaShade: item?.gingivaShade?.id,
      workflowType: item?.workflowType,
      shadeType: deduceShadeType(
        item?.itemShade?.id ?? item?.teethShade?.id,
        order?.user?.id,
        accountProductPreferences
      ),
    },
  });

  const watchItemType = rhf.watch("itemType");
  const watchMaterial = rhf.watch("material");
  const watchShadeType = rhf.watch("shadeType");

  const { data: allMaterials, loading: loadingMaterials } = useQuery(
    ANATOMY_ITEM_CHOICE_DATA,
    {
      variables: {
        where: getAnatomyItemChoiceWhere(watchShadeType, item?.itemType),
      },
    }
  );
  const { data: allShades } = useQuery(GET_ALL_SHADES);
  const [createRemovableItem] = useMutation(CREATE_REMOVABLE_ITEM);
  const [updateRemovableItem] = useMutation(UPDATE_REMOVABLE_ITEM);

  const isNewWorkflow = (itemType): boolean => {
    if (!TOGGLE_FEATURE.NEW_WORKFLOW) {
      return false;
    }
    if (
      itemType !== ItemType.SPLINT &&
      itemType !== ItemType.NIGHT_GUARD &&
      itemType !== ItemType.RETAINER &&
      itemType !== ItemType.BLEACHING_TRAY
    ) {
      return false;
    }
    return true;
  };

  const getAllGingivaShades = () => {
    return (allShades?.getAllItemShades ?? [])
      .filter(shade => shade.gingiva)
      .map(shade => ({ value: shade.id, label: shade.label }));
  };

  const getAllMaterials = () => {
    return (allMaterials?.getItemMaterialsWhere ?? []).map(material => ({
      value: material.id,
      label: material.label,
    }));
  };

  const submit = async data => {
    try {
      const itemType = item?.itemType ?? data?.itemType ?? watchItemType;
      if (isNewWorkflow(item?.itemType ?? data?.itemType)) {
        redirect(itemType);
        return;
      }
      if (item?.id) {
        await updateRemovableItem({
          variables: {
            data: {
              itemMaterial: data.material
                ? {
                    connect: {
                      id: data.material,
                    },
                  }
                : undefined,
              teethShade: data.shade
                ? {
                    connect: {
                      id: data.shade,
                    },
                  }
                : undefined,
              gingivaShade: data.gingivaShade
                ? {
                    connect: {
                      id: data.gingivaShade,
                    },
                  }
                : undefined,
              itemType: {
                set: data.itemType,
              },
              teeth: data.teeth?.split(",").map(t => parseInt(t)),
              teethToManufacture: data.teethToManufacture
                ? {
                    set: data.teethToManufacture
                      .split(",")
                      .map(t => parseInt(t)),
                  }
                : undefined,
              workflowType: data.workflowType
                ? {
                    set: data.workflowType,
                  }
                : undefined,
            },
            where: {
              id: item.id,
            },
          },
        });
        notifications.show({
          title: "Removable item updated",
          color: "green",
          message: "",
        });
      } else {
        await createRemovableItem({
          variables: {
            args: {
              itemType: data.itemType,
              gingivaShade: data.gingivaShade
                ? {
                    connect: {
                      id: data.gingivaShade,
                    },
                  }
                : undefined,
              teeth: data.teeth?.split(",").map(t => parseInt(t)),
              teethShade: data.shade
                ? {
                    connect: {
                      id: data.shade,
                    },
                  }
                : undefined,
              teethToManufacture: data.teethToManufacture
                ? {
                    set: data.teethToManufacture
                      .split(",")
                      .map(t => parseInt(t)),
                  }
                : undefined,
              workflowType: data.workflowType,
              itemMaterial: data.material
                ? {
                    connect: {
                      id: data.material,
                    },
                  }
                : undefined,
              product: {
                connect: {
                  id: productId,
                },
              },
            },
          },
        });
        notifications.show({
          title: "Removable item created",
          color: "green",
          message: "",
        });
      }
      refetch();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to create or update removable item",
        color: "red",
        message: "",
      });
    }
    closeModal();
  };

  if (loadingMaterials) {
    return null;
  }

  return (
    <>
      <SelectInputRef
        name="itemType"
        label="Item type *"
        data={Object.keys(ItemType)}
        errors={rhf.formState.errors}
        control={rhf.control}
      />
      {!isNewWorkflow(item?.itemType) && (
        <>
          <Space h="md" />
          <TextInputRef
            name="teeth"
            label="Teeth"
            errors={rhf.formState.errors}
            control={rhf.control}
            required={false}
          />
          <Space h="md" />
          <TextInputRef
            name="teethToManufacture"
            label="Teeth To Manufacture"
            errors={rhf.formState.errors}
            control={rhf.control}
            required={false}
          />
          <Space h="md" />
          <SelectInputRef
            name="material"
            label="Material"
            data={getAllMaterials()}
            errors={rhf.formState.errors}
            control={rhf.control}
            required={false}
          />
          <Space h="md" />
          {watchMaterial !== "METAL_NON_PRECIOUS" && (
            <ShadeSelection
              watchShadeType={watchShadeType}
              control={rhf.formState.control}
              errors={rhf.errors}
              anatomyItemChoicesData={allMaterials ?? []}
              onSelect={selectedValue =>
                rhf.setValue("shadeType", selectedValue)
              }
              order={order}
              accountProductPreferences={accountProductPreferences}
              isRemovableItem={true}
              rhf={rhf}
            />
          )}
          <Space h="md" />
          <SelectInputRef
            name="gingivaShade"
            label="Gingiva shade"
            data={getAllGingivaShades()}
            errors={rhf.formState.errors}
            control={rhf.control}
            required={false}
          />
          <Space h="md" />
          <SelectInputRef
            name="workflowType"
            label="Workflow Type"
            data={Object.keys(WorkflowType)}
            errors={rhf.formState.errors}
            control={rhf.control}
            required={false}
          />
        </>
      )}
      <Space h="md" />
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={rhf.handleSubmit(submit)}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default CreateRemovableItemComponent;
