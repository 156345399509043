import { useForm } from "react-hook-form";
import { Grid, Button, Title } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import { GET_ALL_GROUPS } from "../../gql/groups";

import {
  TextInputRef,
  SelectInputRef,
  YesNoSelectorRef,
  STYLE,
  UserType,
  UserRole,
  UPDATE_USER,
  CREATE_USER,
  ItemType,
  PricingType,
} from "@jasper/shared";

const UserEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: allGroups } = useQuery(GET_ALL_GROUPS, {
    variables: {
      skip: 0,
      take: 100,
    },
  });

  const [updateUser] = useMutation(UPDATE_USER);
  const [createUser] = useMutation(CREATE_USER);

  const { control, errors, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      firstname: location.state?.user?.firstName,
      lastname: location.state?.user?.lastName,
      email: location.state?.user?.email,
      role: location.state?.user?.role,
      type: location.state?.user?.type,
      isManager: location.state?.user?.isManager,
      group: location.state?.user?.userGroup?.id,
      pricingType: location.state?.user?.clientBilling?.pricingType,
    },
  });

  const onSubmit = async data => {
    try {
      if (location?.state?.user?.id) {
        await updateUserSubmit(data);
        navigate(`/users/${location.state?.user?.id}`);
      } else {
        const req = await createUserSubmit(data);
        navigate(`/users/${req?.data?.createUser?.id}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateUserSubmit = async data => {
    await updateUser({
      variables: {
        where: {
          id: location.state?.user?.id,
        },
        data: {
          role: {
            set: data.role,
          },
          type: {
            set: data.type,
          },
          email: {
            set: data.email,
          },
          firstName: {
            set: data.firstname,
          },
          lastName: {
            set: data.lastname,
          },
          isManager: {
            set: data.isManager,
          },
          clientBilling: {
            update: {
              where: {
                id: {
                  equals: location?.state?.user?.clientBilling?.id,
                },
              },
              data: {
                pricingType: {
                  set: data.pricingType,
                },
              },
            },
          },
        },
      },
    });
  };

  const createUserSubmit = async data => {
    return await createUser({
      variables: {
        args: {
          firstName: data.firstname,
          email: data.email,
          lastName: data.lastname,
          type: data.type,
          isManager: data.isManager,
          password: data.password,
          role: data.role,
          clientBilling: {
            create: {
              pricingType: data.pricingType,
            },
          },
          userGroup: location.state?.user?.userGroup?.id
            ? {
                connect: {
                  id: location.state?.user?.userGroup?.id,
                },
              }
            : undefined,
          productPreferences: {
            create: [
              {
                fixedProsthesisPreferences: {
                  createMany: {
                    data: [
                      {
                        itemType: ItemType.CROWN,
                      },
                      {
                        itemType: ItemType.LAY,
                      },
                      {
                        itemType: ItemType.BRIDGE,
                      },
                      {
                        itemType: ItemType.INLAY_CORE,
                      },
                      {
                        itemType: ItemType.IMPLANT,
                      },
                      {
                        itemType: ItemType.VENEER,
                      },
                      {
                        itemType: ItemType.BRIDGE_IMPLANT,
                      },
                    ],
                  },
                },
                removableProsthesisPreferences: {
                  create: [
                    {
                      itemType: ItemType.SPLINT,
                    },
                    {
                      itemType: ItemType.FULL_DENTURE,
                    },
                  ],
                },
              },
            ],
          },
        },
      },
    });
  };

  return (
    <>
      <Title
        fw={200}
        style={{ paddingBottom: "1rem" }}
      >
        General
      </Title>
      <Grid>
        <Grid.Col span={6}>
          <TextInputRef
            name="firstname"
            label="Firstname"
            control={control}
            errors={errors}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInputRef
            name="lastname"
            label="Lastname"
            control={control}
            errors={errors}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        {!location.state?.user?.id && (
          <Grid.Col span={6}>
            <TextInputRef
              name="password"
              label="Password"
              control={control}
              errors={errors}
              secure={true}
            />
          </Grid.Col>
        )}
        <Grid.Col span={location.state?.user?.id ? 12 : 6}>
          <TextInputRef
            name="email"
            label="Email"
            control={control}
            errors={errors}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={6}>
          <SelectInputRef
            name="role"
            label="User role"
            data={Object.keys(UserRole)}
            control={control}
            errors={errors}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <SelectInputRef
            name="type"
            label="User type"
            data={Object.keys(UserType)}
            control={control}
            errors={errors}
          />
        </Grid.Col>
      </Grid>
      <Title
        fw={200}
        style={{ paddingBottom: "1rem" }}
      >
        Clinic
      </Title>
      <Grid>
        <Grid.Col span={7}>
          <SelectInputRef
            name="group"
            label="User group (must be updated thought group settings)"
            data={(allGroups?.getAllGroups ?? []).map(group => ({
              label: group.name,
              value: group.id,
            }))}
            control={control}
            errors={errors}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <YesNoSelectorRef
            name="isManager"
            label="Is it a master account ?"
            value={location.state?.user?.isManager ?? false}
            setValue={value => setValue("isManager", value)}
          />
        </Grid.Col>
      </Grid>
      <Title
        fw={200}
        style={{ paddingBottom: "1rem" }}
      >
        Billing
      </Title>
      <Grid>
        <Grid.Col span={6}>
          <SelectInputRef
            name="pricingType"
            label="Pricing type"
            data={Object.keys(PricingType)}
            control={control}
            errors={errors}
          />
        </Grid.Col>
      </Grid>
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default UserEdit;
