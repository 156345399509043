import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Text, Button, Space, Title, Modal, Select } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import {
  GET_ORDER_BY_UNIQUE_ATTRIBUTE,
  DELETE_MANY_PRODUCTS,
  DELETE_MANY_ANATOMY_ITEMS,
  DELETE_MANY_REMOVABLE_ITEMS,
  DELETE_MANY_IMPLANT_ITEM,
  UPDATE_ANATOMY_ITEM,
  UPDATE_REMOVABLE_ITEM,
  UPDATE_IMPLANT_ITEM,
} from "../../../gql/";
import CreateAnatomyItemComponent from "./CreateAnatomyItemComponent";
import AnatomyItemComponent from "./AnatomyItemComponent";
import RemovableItemComponent from "./RemovableItemComponent";
import CreateRemovableItemComponent from "./CreateRemovableItemComponent";
import CreateProductComponent from "./CreateProductComponents";
import ImplantItemComponent from "./ImplantItemComponent";
import CreateImplantItemComponent from "./CreateImplantItemComponent";
import { UseAuth } from "/@/contexts";

import {
  Product,
  ItemType,
  STYLE,
  TOGGLE_FEATURE,
  LabsBreadcrumbs,
  ProductItemType,
  GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES,
} from "@jasper/shared";

const AnatomyItemView = ({
  product,
  onInsert,
  onDelete,
  onUpdate,
  onUpdateTeeth,
}) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: "bold" }}>Anatomy Items:&nbsp;</span>
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={onInsert}
        >
          (add new)
        </span>
      </li>
      {(product?.anatomyItem ?? []).map((item, index) => (
        <div
          style={{ marginLeft: "3rem" }}
          key={item.id}
        >
          <AnatomyItemComponent
            item={item}
            index={index}
            onDelete={() => onDelete(item.id)}
            onUpdate={() => onUpdate(item)}
            onUpdateTeeth={(itemId, teeth) => onUpdateTeeth(itemId, teeth)}
          />
        </div>
      ))}
    </>
  );
};

const RemovableItemView = ({
  product,
  onInsert,
  onDelete,
  onUpdate,
  onUpdateTeeth,
}) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: "bold" }}>Removable Items:&nbsp;</span>
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={onInsert}
        >
          (add new)
        </span>
      </li>
      {(product?.removableItem ?? []).map((item, index) => (
        <div
          style={{ marginLeft: "3rem" }}
          key={item.id}
        >
          <RemovableItemComponent
            item={item}
            index={index}
            onDelete={() => onDelete(item.id)}
            onUpdate={() => onUpdate(item)}
            onUpdateTeeth={(itemId, teeth) => onUpdateTeeth(itemId, teeth)}
          />
        </div>
      ))}
    </>
  );
};

const ImplantItemView = ({
  product,
  onInsert,
  onDelete,
  onUpdate,
  onUpdateTeeth,
}) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: "bold" }}>Implant Items:&nbsp;</span>
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={onInsert}
        >
          (add new)
        </span>
      </li>
      {(product?.implantItem ?? []).map((item, index) => (
        <div
          style={{ marginLeft: "3rem" }}
          key={item.id}
        >
          <ImplantItemComponent
            item={item}
            index={index}
            onDelete={() => onDelete(item.id)}
            onUpdate={() => onUpdate(item)}
            onUpdateTeeth={(itemId, teeth) => onUpdateTeeth(itemId, teeth)}
          />
        </div>
      ))}
    </>
  );
};

const OrderEdit = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();

  const { user } = UseAuth();

  const [isModalNewAnatomyItemOpen, setIsModalNewAnatomyItemOpen] =
    useState<boolean>(false);
  const [isModalNewRemovableItemOpen, setIsModalNewRemovableItemOpen] =
    useState<boolean>(false);
  const [isModalNewImplantItemOpen, setIsModalNewImplantItemOpen] =
    useState<boolean>(false);
  const [isModalNewProductOpen, setIsModalNewProductOpen] =
    useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState(null);

  const [deleteManyProduct] = useMutation(DELETE_MANY_PRODUCTS);
  const [deleteManyAnatomyItems] = useMutation(DELETE_MANY_ANATOMY_ITEMS);
  const [deleteManyRemovableItems] = useMutation(DELETE_MANY_REMOVABLE_ITEMS);
  const [deleteManyImplantItems] = useMutation(DELETE_MANY_IMPLANT_ITEM);
  const [updateAnatomyItem] = useMutation(UPDATE_ANATOMY_ITEM);
  const [updateRemovableItem] = useMutation(UPDATE_REMOVABLE_ITEM);
  const [updateImplantItem] = useMutation(UPDATE_IMPLANT_ITEM);

  const { data: accountProductPreferences } = useQuery(
    GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES
  );

  const { data, refetch } = useQuery(GET_ORDER_BY_UNIQUE_ATTRIBUTE, {
    variables: {
      where: {
        id: order_id,
      },
    },
  });

  const deleteProduct = async (productId: string): void => {
    const checkConfirm = confirm(
      "You are going to delete this product and all related items, this action is irreversible"
    );
    if (!checkConfirm) {
      notifications.show({
        title: "Deletion aborted",
        color: "red",
        message: "",
      });
      return;
    }
    try {
      await deleteManyProduct({
        variables: {
          where: {
            id: {
              equals: productId,
            },
          },
        },
      });
      notifications.show({
        title: "Product deleted",
        color: "green",
        message: "",
      });
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while deleting product",
        color: "red",
        message: "",
      });
    }
  };

  const deleteAnatomyItem = async (itemId: string): void => {
    const checkConfirm = confirm(
      "You are going to delete this item, this action is irreversible"
    );
    if (!checkConfirm) {
      notifications.show({
        title: "Deletion aborted",
        color: "red",
        message: "",
      });
      return;
    }
    try {
      await deleteManyAnatomyItems({
        variables: {
          where: {
            id: {
              equals: itemId,
            },
          },
        },
      });
      notifications.show({
        title: "Item deleted",
        color: "green",
        message: "",
      });
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while deleting item",
        color: "red",
        message: "",
      });
    }
  };

  const deleteRemovableItem = async (itemId: string): void => {
    const checkConfirm = confirm(
      "You are going to delete this item, this action is irreversible"
    );
    if (!checkConfirm) {
      notifications.show({
        title: "Deletion aborted",
        color: "red",
        message: "",
      });
      return;
    }
    try {
      await deleteManyRemovableItems({
        variables: {
          where: {
            id: {
              equals: itemId,
            },
          },
        },
      });
      notifications.show({
        title: "Item deleted",
        color: "green",
        message: "",
      });
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while deleting item",
        color: "red",
        message: "",
      });
    }
  };

  const deleteImplantItem = async (itemId: string): void => {
    const checkConfirm = confirm(
      "You are going to delete this item, this action is irreversible"
    );
    if (!checkConfirm) {
      notifications.show({
        title: "Deletion aborted",
        color: "red",
        message: "",
      });
      return;
    }
    try {
      await deleteManyImplantItems({
        variables: {
          where: {
            id: {
              equals: itemId,
            },
          },
        },
      });
      notifications.show({
        title: "Item deleted",
        color: "green",
        message: "",
      });
      refetch();
    } catch (e) {
      notifications.show({
        title: "Error while deleting item",
        color: "red",
        message: "",
      });
    }
  };

  const isRemovableItemType = (
    selectedProductId: string,
    watchProductType: ItemType
  ) => {
    const product = (data?.getOrderByUniqueAttribute?.products ?? []).find(
      product => product.id === selectedProductId
    );
    const productType = watchProductType ?? product?.productType;
    return (
      productType === ItemType.FULL_DENTURE ||
      productType === ItemType.PARTIAL_DENTURE ||
      productType === ItemType.SPLINT
    );
  };

  return (
    <>
      <Modal
        opened={isModalNewAnatomyItemOpen}
        onClose={() => setIsModalNewAnatomyItemOpen(false)}
        title="New anatomy item"
      >
        <CreateAnatomyItemComponent
          closeModal={() => setIsModalNewAnatomyItemOpen(false)}
          productId={selectedProductId}
          item={selectedItem}
          refetch={refetch}
          redirect={itemType => {
            if (TOGGLE_FEATURE.NEW_WORKFLOW) {
              navigate(
                `/orders/${order_id}/edit/${selectedProductId}/new?itemType=${itemType}`
              );
            }
          }}
        />
      </Modal>
      <Modal
        opened={isModalNewRemovableItemOpen}
        onClose={() => setIsModalNewRemovableItemOpen(false)}
        title="New removable item"
      >
        <CreateRemovableItemComponent
          closeModal={() => setIsModalNewRemovableItemOpen(false)}
          productId={selectedProductId}
          item={selectedItem}
          refetch={refetch}
          redirect={itemType => {
            if (TOGGLE_FEATURE.NEW_WORKFLOW) {
              navigate(
                `/orders/${order_id}/edit/${selectedProductId}/new?itemType=${itemType}`
              );
            }
          }}
          accountProductPreferences={
            accountProductPreferences?.getAccountProductPreferences
          }
          order={data?.getOrderByUniqueAttribute}
        />
      </Modal>
      <Modal
        opened={isModalNewImplantItemOpen}
        onClose={() => setIsModalNewImplantItemOpen(false)}
        title="New implant item"
      >
        <CreateImplantItemComponent
          closeModal={() => setIsModalNewImplantItemOpen(false)}
          productId={selectedProductId}
          item={selectedItem}
          refetch={refetch}
          accountProductPreferences={
            accountProductPreferences?.getAccountProductPreferences
          }
          order={data?.getOrderByUniqueAttribute}
        />
      </Modal>
      <Modal
        opened={isModalNewProductOpen}
        onClose={() => setIsModalNewProductOpen(false)}
        title="New product"
      >
        <CreateProductComponent
          closeModal={() => setIsModalNewProductOpen(false)}
          orderId={order_id}
          product={(data?.getOrderByUniqueAttribute?.products ?? []).find(
            product => product.id === selectedProductId
          )}
          refetch={refetch}
          isRemovableItem={watchProductType =>
            isRemovableItemType(selectedProductId, watchProductType)
          }
        />
      </Modal>
      <LabsBreadcrumbs
        orderReference={data?.getOrderByUniqueAttribute?.orderReference}
        orderId={data?.getOrderByUniqueAttribute?.id}
        navigate={href => navigate(href)}
        user={user}
      />
      {(data?.getOrderByUniqueAttribute.products ?? []).map(
        (product: Product, index: number) => {
          return (
            <div
              key={product.id}
              style={{
                border: "0.02rem solid lightgrey",
                padding: "1rem",
                margin: "1rem",
              }}
            >
              <Title order={2}>Product {index + 1}</Title>
              <Text>
                <span style={{ fontWeight: "bold" }}>Type:</span>{" "}
                {product.productType}
              </Text>
              <Text>
                <span style={{ fontWeight: "bold" }}>Teeth:</span>{" "}
                {product.teeth.join(", ")}
              </Text>
              <Text
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  setSelectedProductId(product.id);
                  setIsModalNewProductOpen(true);
                }}
              >
                Update product teeth or type
              </Text>
              <Text
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  if (
                    TOGGLE_FEATURE.NEW_WORKFLOW &&
                    (product.productType === ItemType.INLAY_CORE ||
                      product.productType === ItemType.CROWN ||
                      product.productType === ItemType.LAY ||
                      product.productType === ItemType.SPLINT ||
                      product.productType === ItemType.PARTIAL_DENTURE ||
                      product.productType === ItemType.FULL_DENTURE ||
                      product.productType === ItemType.IMMEDIATE_DENTURE ||
                      product.productType === ItemType.FULL_DENTURE_WAX_BITE ||
                      product.productType === ItemType.IMPLANT ||
                      product.productType === ItemType.BRIDGE ||
                      product.productType === ItemType.BRIDGE_IMPLANT ||
                      product.productType === ItemType.SCAN_ONLY ||
                      product.productType === ItemType.VENEER)
                  ) {
                    navigate(
                      `/orders/${order_id}/edit/${product.id}/edit?itemType=${product.productType}`,
                      {
                        state: {
                          product: product,
                        },
                      }
                    );
                    return;
                  }
                  setSelectedProductId(product.id);
                  setIsModalNewAnatomyItemOpen(true);
                }}
              >
                Update product detail
              </Text>
              <Text
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => deleteProduct(product.id)}
              >
                Delete product
              </Text>
              <div style={{ marginLeft: "3rem" }}>
                <AnatomyItemView
                  product={product}
                  onInsert={() => {
                    setSelectedItem(null);
                    setSelectedProductId(product.id);
                    setIsModalNewAnatomyItemOpen(true);
                  }}
                  onUpdate={item => {
                    if (
                      TOGGLE_FEATURE.NEW_WORKFLOW &&
                      (item.itemType === ItemType.INLAY_CORE ||
                        item.itemType === ItemType.CROWN)
                    ) {
                      navigate(
                        `/orders/${order_id}/edit/${product.id}/edit?itemType=${item.itemType}&itemId=${item.id}`,
                        {
                          state: {
                            item: item,
                          },
                        }
                      );
                      return;
                    }
                    setSelectedProductId(product.id);
                    setSelectedItem(item);
                    setIsModalNewAnatomyItemOpen(true);
                  }}
                  onDelete={itemId => deleteAnatomyItem(itemId)}
                  onUpdateTeeth={async (itemId, teeth) => {
                    try {
                      await updateAnatomyItem({
                        variables: {
                          where: {
                            id: itemId,
                          },
                          data: {
                            teeth: teeth,
                          },
                        },
                      });
                      notifications.show({
                        title: "Teeth updated",
                        color: "green",
                        message: "",
                      });
                      refetch();
                    } catch (e) {
                      notifications.show({
                        title: "Error while trying to update teeth of item",
                        color: "red",
                        message: "",
                      });
                    }
                    refetch();
                  }}
                />
                <RemovableItemView
                  product={product}
                  onInsert={() => {
                    setSelectedItem(null);
                    setSelectedProductId(product.id);
                    setIsModalNewRemovableItemOpen(true);
                  }}
                  onUpdate={item => {
                    setSelectedItem(item);
                    setIsModalNewRemovableItemOpen(true);
                  }}
                  onDelete={itemId => deleteRemovableItem(itemId)}
                  onUpdateTeeth={async (itemId, teeth) => {
                    try {
                      await updateRemovableItem({
                        variables: {
                          where: {
                            id: itemId,
                          },
                          data: {
                            teeth: teeth,
                          },
                        },
                      });
                      notifications.show({
                        title: "Teeth updated",
                        color: "green",
                        message: "",
                      });
                      refetch();
                    } catch (e) {
                      notifications.show({
                        title: "Error while trying to update teeth of item",
                        color: "red",
                        message: "",
                      });
                    }
                  }}
                />
                <ImplantItemView
                  product={product}
                  onInsert={() => {
                    setSelectedItem(null);
                    setSelectedProductId(product.id);
                    setIsModalNewImplantItemOpen(true);
                  }}
                  onUpdate={itemId => {
                    setSelectedItem(itemId);
                    setIsModalNewImplantItemOpen(true);
                  }}
                  onDelete={itemId => deleteImplantItem(itemId)}
                  onUpdateTeeth={async (itemId, teeth) => {
                    try {
                      await updateImplantItem({
                        variables: {
                          where: {
                            id: itemId,
                          },
                          data: {
                            teeth: teeth,
                          },
                        },
                      });
                      notifications.show({
                        title: "Teeth updated",
                        color: "green",
                        message: "",
                      });
                      refetch();
                    } catch (e) {
                      notifications.show({
                        title: "Error while trying to update teeth of item",
                        color: "red",
                        message: "",
                      });
                    }
                    refetch();
                  }}
                />
              </div>
            </div>
          );
        }
      )}
      <Space h="md" />
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={() => {
            setSelectedProductId("");
            setIsModalNewProductOpen(true);
          }}
        >
          Add a new product
        </Button>
      </div>
    </>
  );
};

export default OrderEdit;
