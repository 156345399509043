import React, { useRef, useEffect, useState } from "react";
import "./messageInput.css";
import { ActionIcon, Textarea } from "@mantine/core";
import { IconSend, IconPaperclip } from "@tabler/icons-react";
import { File as SharedFile } from "@jasper/shared/types/interfaces";
import { FileType } from "@jasper/shared";
import { PreviewPhoto } from "./PreviewPhoto";
import { UploadedFile } from "./UploadedFile";
import { v4 as uuidv4 } from "uuid";

export interface MessageInputProps {
  setMessageToSend: (newValue: string) => void;
  send: () => void;
  uploadFile: () => void;
  files: File[];
  setFiles: (newValue: File[]) => void;
  renderingFiles: SharedFile[];
  setRenderingFiles: (newValue: SharedFile[]) => void;
  setShowModal: (value: boolean) => void;
  temp: boolean;
  setTemp: (value: boolean) => void;
}

export const MessageInput = ({ ...props }: MessageInputProps) => {
  const input = useRef<HTMLTextAreaElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const [tempImages, setTempImages] = useState<SharedFile[]>([]);
  const [tempFiles, setTempFiles] = useState<SharedFile[]>([]);

  const getImageTypeFromFile = async (files: File[]): Promise<SharedFile[]> => {
    const filePromises = files.map((file, index) => {
      return new Promise<SharedFile>((resolve, reject) => {
        if (file.type.match("image.*")) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = e => {
            resolve({
              key: file.name,
              id: index.toString(),
              path: e.target?.result as string,
              fileType: FileType.CHAT_FILE,
            } as SharedFile);
          };
          reader.onerror = error => reject(error);
        } else {
          resolve({
            key: file.name,
            id: index.toString(),
            path: undefined,
            fileType: FileType.CHAT_FILE,
          } as SharedFile);
        }
      });
    });
    return Promise.all(filePromises);
  };

  const send = () => {
    if (input.current && (input.current?.value || props.files.length !== 0)) {
      props.send();
      input.current.value = "";
      props.setMessageToSend("");
      setTempImages([]);
      setTempFiles([]);
      props.setFiles([]);
    }
  };

  const handlePictures = async (e: File[]) => {
    if (e) {
      const fileList: File[] = [...props.files, ...e];
      props.setFiles(fileList);
      const images = await getImageTypeFromFile(fileList);
      setTempImages(images.filter(file => file.path !== null));
      setTempFiles(images.filter(file => file.path === null));
    }
  };

  const dropHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();

    if (ev?.dataTransfer?.items) {
      const newFiles: File[] = [];
      [...ev.dataTransfer.items].forEach(item => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (file !== null) {
            newFiles.push(file);
          }
        }
      });
      handlePictures(newFiles);
    }
  };

  useEffect(() => {
    props.setFiles([]);
  }, [props.renderingFiles.length === 0 && props.renderingFiles.length > 0]);

  return (
    <div
      className="input-box"
      onDrop={event => dropHandler(event)}
    >
      <PreviewPhoto
        setShowModal={props.setShowModal}
        setRenderingFiles={files => props.setRenderingFiles(files)}
        setTempFiles={files => setTempImages(files)}
        setFiles={files => props.setFiles(files)}
        tempFiles={tempImages ?? []}
        input={fileInput}
      />
      <UploadedFile
        setTempFiles={files => setTempFiles(files)}
        tempFiles={tempFiles ?? []}
        input={fileInput}
        isTempFile={true}
        setFiles={files => props.setFiles(files)}
      />
      <div className="input-container">
        <ActionIcon
          variant="filled"
          color="indigo"
          aria-label="Settings"
          className="icon-button"
          onClick={() => {
            fileInput.current?.click();
          }}
        >
          <IconPaperclip
            style={{ width: "100%", height: "100%" }}
            stroke={1.5}
          />
        </ActionIcon>
        <Textarea
          ref={input}
          placeholder="Tape your text here"
          style={{ display: "table-cell", width: "inherit" }}
          onChange={event => props.setMessageToSend(event.target.value)}
          autosize
          onKeyDown={async e => {
            if (e.key === "Enter" && e.shiftKey === true) {
              props.setMessageToSend(input.current?.value + "\n");
            }
            if (e.key === "v" && (e.ctrlKey === true || e.metaKey === true)) {
              const clipboard = await navigator.clipboard.read();
              const images = await Promise.all(
                clipboard.map(async clipboardItem => {
                  const type = ["image/png", "image/jpg", "image/jpeg"].find(
                    t => clipboardItem.types.includes(t)
                  );
                  if (type) {
                    e.preventDefault();
                    return { image: await clipboardItem.getType(type), type };
                  }
                  return null;
                })
              );
              images.forEach(image => {
                if (image) {
                  const file = new File(
                    [image.image],
                    `${uuidv4()}-photo.${image.type.split("/")[1]}`,
                    { type: image.type }
                  );
                  handlePictures([file]);
                }
              });
            }
            if (e.key === "Enter" && e.shiftKey === false) {
              e.preventDefault();
              send();
            }
          }}
        />
        <div style={{ display: "flex", gap: "5px" }}>
          <input
            ref={fileInput}
            onChange={async e => {
              if (e.target.files) {
                handlePictures(Array.from(e.target.files));
              }
            }}
            style={{ display: "none" }}
            multiple
            type="file"
            accept="image/*,application/dicom,application/zip,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.stl,.STL"
          />
          <ActionIcon
            variant="filled"
            color="indigo"
            aria-label="Settings"
            className="icon-button"
            onClick={() => {
              send();
            }}
          >
            <IconSend
              style={{ width: "100%", height: "100%" }}
              stroke={1.5}
            />
          </ActionIcon>
        </div>
      </div>
    </div>
  );
};
