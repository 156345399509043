// @ts-ignore
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Title } from "@mantine/core";
import {
  SelectorButtonRef,
  SelectInputRef,
  ShadeSelection,
  FullDentureTeethShape,
  ItemType,
} from "@jasper/shared";

import {
  ProductPreferences,
  AnatomyItemChoicesData,
  GingivaShade,
} from "../../../../types/interfaces";

interface FullDentureDetailsProps {
  rhf: UseFormReturn;
  user_id: string;
  accountProductPreferences: ProductPreferences;
  anatomyItemChoicesData: AnatomyItemChoicesData;
  gingivaShadeChoices: GingivaShade[];
}

const FullDentureDetails = ({
  rhf,
  user_id,
  accountProductPreferences,
  anatomyItemChoicesData,
  gingivaShadeChoices,
}: FullDentureDetailsProps) => {
  const watchTeethShape = rhf.watch("teethShape");
  const watchShadeType = rhf.watch("shadeType");

  return (
    <>
      <Title>Caractéristiques de votre Prothèse Amovible Complète</Title>
      <SelectInputRef
        label="Teinte de gencives"
        name="gingivaShade"
        data={gingivaShadeChoices}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
      />
      <ShadeSelection
        watchShadeType={watchShadeType}
        control={rhf.control}
        errors={rhf.formState.errors}
        anatomyItemChoicesData={anatomyItemChoicesData}
        onSelect={(selectedValue: string) =>
          rhf.setValue("shadeType", selectedValue)
        }
        user_id={user_id}
        accountProductPreferences={accountProductPreferences}
        itemType={ItemType.FULL_DENTURE}
        rhf={rhf}
      />
      <SelectorButtonRef
        label="Forme des dents"
        name="teethShape"
        data={FullDentureTeethShape}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
        onSubmit={(selectedValue: string) =>
          rhf.setValue("teethShape", selectedValue)
        }
        watch={watchTeethShape}
      />
    </>
  );
};

export default FullDentureDetails;
