import { useForm } from "react-hook-form";
import { Button } from "@mantine/core";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { ItemType, TeethShadeType, TeethShadeSide } from "../../types/enums";
import { STYLE } from "../../types/constants";
import SelectorButtonRef from "../SelectorButtonRef";
import SelectInputRef from "../SelectInputRef";
import ShadeSelection from "../shadeSelection";
import {
  CREATE_ANATOMY_ITEM,
  DELETE_MANY_ANATOMY_ITEMS,
} from "../../gql/items";
import { deduceShadeType } from "../../utils/item.utils";

const CreateNewLay = ({
  productType,
  materialFormChoices,
  anatomyItemChoicesData,
  accountProductPreferences,
  order,
  product_id,
  refetch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const savedItem = (location?.state?.product?.anatomyItem ?? []).find(
    item =>
      item.itemType === ItemType.ONLAY ||
      item.itemType === ItemType.INLAY ||
      item.itemType === ItemType.OVERLAY ||
      item.itemType === ItemType.LAY
  );

  const rhf = useForm({
    defaultValues: {
      layType: savedItem?.itemType,
      material: savedItem?.itemMaterial?.id,
      shade: savedItem?.itemShade?.id,
      shadeType: deduceShadeType(
        savedItem?.itemShade?.id,
        order?.user?.id,
        accountProductPreferences
      ),
    },
  });

  const watchLayType = rhf.watch("layType");
  const watchShadeType = rhf.watch("shadeType");
  const watchMaterial = rhf.watch("material");
  const watchIsMultiShade = rhf.watch("isMultiShade");

  const product = (order?.products ?? []).find(
    product => product.id === product_id
  );

  const [createAnatomyItem] = useMutation(CREATE_ANATOMY_ITEM);
  const [deleteManyAnatomyItem] = useMutation(DELETE_MANY_ANATOMY_ITEMS);

  const onSubmit = async data => {
    if (savedItem?.teeth) {
      await deleteManyAnatomyItem({
        variables: {
          where: {
            productId: {
              equals: product_id,
            },
            teeth: {
              equals: savedItem.teeth,
            },
          },
        },
      });
    }
    await createAnatomyItem({
      variables: {
        args: {
          itemMaterial: data.material
            ? {
                connect: {
                  id: data.material,
                },
              }
            : undefined,
          itemShade:
            data.shade &&
            !watchIsMultiShade &&
            data.material !== "METAL_NON_PRECIOUS"
              ? {
                  connect: {
                    id: data.shade,
                  },
                }
              : undefined,
          itemType: productType === ItemType.LAY ? watchLayType : productType,
          teeth: product.teeth,
          product: {
            connect: {
              id: product.id,
            },
          },
          teethshadeType:
            watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
              ? TeethShadeType.MultiShade
              : TeethShadeType.SingleShade,
          multiShadeInfo:
            watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
              ? {
                  createMany: {
                    data: [
                      ...(data.gingivalShade
                        ? [
                            {
                              itemShadeId: data.gingivalShade,
                              teethShadeSide: TeethShadeSide.GINGIVAL,
                            },
                          ]
                        : []),
                      ...(data.baseShade
                        ? [
                            {
                              itemShadeId: data.baseShade,
                              teethShadeSide: TeethShadeSide.BASE,
                            },
                          ]
                        : []),
                      ...(data.incisalShade
                        ? [
                            {
                              itemShadeId: data.incisalShade,
                              teethShadeSide: TeethShadeSide.INCISAL,
                            },
                          ]
                        : []),
                    ],
                  },
                }
              : undefined,
        },
      },
    });
    await refetch();
    navigate(`/orders/${order?.id}/edit/`);
  };

  return (
    <>
      {productType === ItemType.LAY && (
        <SelectorButtonRef
          label="Veuillez choisir un type de lay"
          data={[
            {
              label: "Inlay",
              value: ItemType.INLAY,
            },
            {
              label: "Onlay",
              value: ItemType.ONLAY,
            },
            {
              label: "Overlay",
              value: ItemType.OVERLAY,
            },
          ]}
          name="layType"
          onSubmit={selectedValue => rhf.setValue("layType", selectedValue)}
          control={rhf.control}
          errors={rhf.formState.errors}
          watch={watchLayType}
          required={true}
        />
      )}
      <SelectInputRef
        label="Matériau"
        name="material"
        data={materialFormChoices}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
      />
      {watchMaterial !== "METAL_NON_PRECIOUS" && (
        <ShadeSelection
          watchShadeType={watchShadeType}
          control={rhf.control}
          errors={rhf.formState.errors}
          anatomyItemChoicesData={anatomyItemChoicesData}
          onSelect={selectedValue => rhf.setValue("shadeType", selectedValue)}
          order={order}
          accountProductPreferences={accountProductPreferences}
          rhf={rhf}
          itemType={ItemType.LAY}
        />
      )}
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={rhf.handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default CreateNewLay;
