import { FileType } from "../types/enums";
import { File as FileInterface } from "../types/interfaces";
import imageCompression from "browser-image-compression";

export const handleImageUpload = async (file: File) => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Image compression error:", error);
  }
};

export const getNotImagesFiles = (allFiles: FileInterface[]): FileInterface[] =>
  allFiles.filter(file => {
    const fileNameSplit = file?.key?.toLocaleLowerCase().split(".");
    return !["jpg", "jpeg", "png", "dcm", "stl"].includes(
      fileNameSplit[fileNameSplit.length - 1]
    );
  });
export const getImagesFromFiles = (
  allFiles: FileInterface[]
): FileInterface[] =>
  allFiles.filter(file => {
    const fileNameSplit = file?.key?.toLocaleLowerCase().split(".");
    return ["jpg", "jpeg", "png", "dcm", "stl"].includes(
      fileNameSplit[fileNameSplit.length - 1]
    );
  });

export const get3OXZFile = (allFiles: FileInterface[]): FileInterface[] => {
  return allFiles.filter(file =>
    file?.key?.toLowerCase().toLowerCase().includes("3oxz")
  );
};

export const getTraceabilitiesFile = (
  allFiles: FileInterface[]
): FileInterface[] =>
  allFiles.filter(
    file =>
      (file?.name ?? file?.fileType)?.toUpperCase() ===
        FileType.BILLING_DENTIST_TRACEABILITY ||
      (file?.name ?? file?.fileType)?.toUpperCase() ===
        FileType.BILLING_PATIENT_TRACEABILITY
  );

export const getImagesFile = (allFiles: FileInterface[]): FileInterface[] =>
  allFiles.filter(file => {
    const fileNameSplit = file?.key?.toLocaleLowerCase().split(".");
    return (
      ["jpg", "jpeg", "png", "dcm", "stl"].includes(
        fileNameSplit[fileNameSplit.length - 1]
      ) ||
      file.fileType === FileType.CLIENT_FILE ||
      file.fileType === FileType.LABS_FILE ||
      file.fileType === FileType.ORDER_ADDON_FILE
    );
  });

export const isUUID = (id: string) => {
  const regex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;
  return id.match(regex);
};

export const getValideFiles = (files: FileInterface[]) => {
  return files
    .filter(file =>
      [
        FileType.LABS_FILE.toLowerCase().replace("_", ""),
        FileType.CLIENT_FILE.toLowerCase().replace("_", ""),
        FileType.ORDER_FILE.toLowerCase().replace("_", ""),
        FileType.BILLING_DENTIST_TRACEABILITY.toLowerCase().replace("_", ""),
        FileType.BILLING_PATIENT_TRACEABILITY.toLowerCase().replace("_", ""),
        FileType.ORDER_ADDON_FILE.toLowerCase().replace("_", ""),
      ].includes(file.fileType.toLowerCase().replace("_", ""))
    )
    .map(({ __typename, ...rest }) => rest);
};
