import { useState } from "react";
import { Textarea, Button, Space, Title } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";

import { STYLE } from "../../types/constants";
import { REDO_ORDER } from "../../gql/orders";

const RedoModal = ({ order, closeModal, refetch }) => {
  const [redoMsg, setRedoMsg] = useState<string>("");
  const [sendRedoOrder] = useMutation(REDO_ORDER);

  const handleSubmit = async () => {
    try {
      await sendRedoOrder({
        variables: {
          orderId: order.id,
          comment: redoMsg,
        },
      });
      notifications.show({
        title: "Redo request send successfully",
        color: "green",
        message: "",
      });
      refetch();
      closeModal();
    } catch (e) {
      notifications.show({
        title: "Error while trying to request redo",
        color: "red",
        message: "",
      });
    }
  };

  return (
    <>
      <div style={{ textAlign: "center", color: STYLE.primary }}>
        <Title order={2}>Redo request for order:</Title>
        <Space h="md" />
        <Title order={2}>{order.orderReference}</Title>
      </div>
      <Space h="md" />
      <Textarea
        color="primary"
        placeholder="Please indicate what you would like for redo"
        onChange={e => setRedoMsg(e.target.value)}
        autosize
        minRows={4}
        maxRows={12}
      />
      <Space h="md" />
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default RedoModal;
