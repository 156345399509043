import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";

import { GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER } from "/@/gql/";
import { Button, Table } from "@mantine/core";
import { SET_LAST_CHAT_OF_ORDER_AS_UNREAD } from "../../gql/chat";
import {
  SearchBar,
  TOGGLE_FEATURE,
  PrecedentComponent,
  UserType,
} from "@jasper/shared";
import {
  getImagesFromFiles,
  getNotImagesFiles,
} from "@jasper/shared/utils/file.utils";
import { IconPaperclip, IconPhoto } from "@tabler/icons-react";
import { socket } from "../../socket";
import { Message, Order } from "@jasper/shared";
import { UseAuth } from "../../contexts";

const __TAKE__ = 10;

const ChatList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(
    parseInt(searchParams.get("page") ?? "0", 10)
  );
  const skip = pageNumber * __TAKE__;
  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const { user } = UseAuth();

  const { data } = useQuery(GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER, {
    variables: {
      args: {
        skip: skip,
        take: __TAKE__,
        searchInput: searchText,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      setAllMessages(data?.getLastUnreadOfEachOrder?.notification ?? []);
    },
  });

  const [markAsUnread] = useMutation(SET_LAST_CHAT_OF_ORDER_AS_UNREAD);

  // useEffect(() => {
  //   socket.on('notification', () => {
  //     refetch();
  //   })
  // }, []);

  const refreshMessageList = (list: Message[], item: Message) => {
    const indexOfMessageToDelete = list.findIndex(
      (message: { order: { id: string } }) =>
        message?.order?.id === item?.order.id
    );
    let newMessagesToDisplay = list.map(order => order);
    if (indexOfMessageToDelete >= 0 && newMessagesToDisplay.length > 0) {
      newMessagesToDisplay.splice(indexOfMessageToDelete, 1);
    } else if (allMessages.length === __TAKE__) {
      newMessagesToDisplay.splice(allMessages.length - 1, 1);
    }
    return [item, ...newMessagesToDisplay];
  };

  useEffect(() => {
    if (user?.type === UserType.SUPPLIER) {
      socket.on(
        `provider-newChatMessage-${user?.userGroupId}`,
        (args: { order: Message }) => {
          setAllMessages(state => refreshMessageList(state, args.order));
        }
      );
    } else {
      socket.on("admin-newChatMessage", (args: { order: Message }) => {
        setAllMessages(state => refreshMessageList(state, args.order));
      });
      return () => {
        socket.off(`provider-newChatMessage-${user?.userGroupId}`);
        socket.off("admin-newChatMessage");
      };
    }
  }, [allMessages, user]);

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearchText(searchParams.get("search") ?? "");
    }
  }, [searchParams.get("search")]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <PrecedentComponent />
        <SearchBar
          setSearchText={value => {
            setSearchText(value);
            setPageNumber(0);
          }}
          searchText={searchText}
          onConfirm={() => navigate(`/chats?search=${searchText ?? ""}&page=0`)}
        />
      </div>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Order reference</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Last message</Table.Th>
            <Table.Th>Sender</Table.Th>
            <Table.Th>Patient</Table.Th>
            <Table.Th>Date</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {allMessages.map((chat: Message, index) => {
            return (
              <Table.Tr
                key={chat.order.id}
                style={chat.read === false ? { fontWeight: "bold" } : {}}
              >
                <Table.Td>{chat.order.orderReference}</Table.Td>
                <Table.Td>{chat.order.status}</Table.Td>
                <Table.Td>
                  {chat.message.length > 50
                    ? chat.message.slice(0, 50) + "..."
                    : chat.message}
                  {chat.message.length > 0 && <br />}
                  {chat.message.length > 0 && <br />}
                  {chat.files !== null &&
                    getImagesFromFiles(chat.files).length > 0 && (
                      <span style={{ display: "flex" }}>
                        <span>{getImagesFromFiles(chat.files).length} x</span>{" "}
                        <IconPhoto />
                      </span>
                    )}
                  {chat.files !== null &&
                    getNotImagesFiles(chat.files).length > 0 && (
                      <span style={{ display: "flex" }}>
                        <span>{getNotImagesFiles(chat.files).length} x</span>{" "}
                        <IconPaperclip />
                      </span>
                    )}
                </Table.Td>
                <Table.Td>{chat.userGroup.name}</Table.Td>
                <Table.Td>
                  {chat.order.patient.firstName} {chat.order.patient.lastName}
                </Table.Td>
                <Table.Td>
                  {new Date(chat.createdAt).toDateString() ===
                  new Date().toDateString()
                    ? new Date(chat.createdAt)
                        .toLocaleTimeString()
                        .split(":")
                        .splice(0, 2)
                        .join(":")
                    : new Date(chat.createdAt).toLocaleDateString()}
                </Table.Td>
                <Table.Td
                  style={{ display: "flex", gap: "3px", width: "max-content" }}
                >
                  <Button
                    onClick={() =>
                      navigate(`/orders/${chat.order.id}`, {
                        state: { from: "CHAT_LIST" },
                      })
                    }
                  >
                    View order
                  </Button>
                  {chat.read === true && TOGGLE_FEATURE.MARK_AS_VIEW && (
                    <Button
                      onClick={async () => {
                        const res = await markAsUnread({
                          variables: {
                            orderId: chat.order.id,
                            supplier: chat.order.provider.id,
                          },
                        });
                        if (res) {
                          const newList = allMessages.map((item, i) =>
                            i === index ? { ...item, read: false } : item
                          );
                          setAllMessages(newList);
                        }
                      }}
                    >
                      Mark as unread
                    </Button>
                  )}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      {allMessages.length > 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {pageNumber > 0 && (
              <Button
                onClick={() => {
                  navigate(`/chats?page=${pageNumber - 1}`);
                  setPageNumber(pageNumber - 1);
                }}
              >
                Previous page
              </Button>
            )}
          </div>
          <div style={{ marginTop: "1rem" }}>Page {pageNumber + 1}</div>
          <div>
            {allMessages.length % __TAKE__ === 0 &&
              data?.getLastUnreadOfEachOrder?.count !== skip + __TAKE__ && (
                <Button
                  onClick={() => {
                    navigate(`/chats?page=${pageNumber + 1}`);
                    setPageNumber(pageNumber + 1);
                  }}
                >
                  Next page
                </Button>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default ChatList;
