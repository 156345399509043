import { gql } from "@apollo/client";

export const UPDATE_ITEM_BILLING = gql`
  mutation UpdateItemBilling(
    $data: ItemBillingUpdateInput!
    $where: ItemBillingWhereUniqueInput!
  ) {
    updateItemBilling(data: $data, where: $where) {
      id
    }
  }
`;
