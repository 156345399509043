import { ItemType } from "@jasper/shared";

import UpdateItemTeethModalContent from "./updateItemTeethModalContent";

const RemovableItemComponent = ({
  item,
  index,
  onDelete,
  onUpdate,
  onUpdateTeeth,
}) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: "bold" }}>Item {index + 1}</span>
        &nbsp;
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => onDelete()}
        >
          (delete item)
        </span>
        {item.itemType !== ItemType.NIGHT_GUARD &&
          item.itemType !== ItemType.RETAINER &&
          item.itemType !== ItemType.BLEACHING_TRAY &&
          item.itemType !== ItemType.PARTIAL_DENTURE &&
          item.itemType !== ItemType.FULL_DENTURE &&
          item.itemType !== ItemType.IMMEDIATE_DENTURE &&
          item.itemType !== ItemType.FULL_DENTURE_WAX_BITE &&
          item.itemType !== ItemType.DENTURE_REPAIR &&
          item.itemType !== ItemType.RETENTION_WIRE &&
          item.itemType !== ItemType.WAX_UP &&
          item.itemType !==
            ItemType.ADDITIONAL_IMPRESSION_FOR_REMOVABLE_PROSTHESIS &&
          item.itemType !== ItemType.ADDING_TEETH_ON_EXISTING_DENTURE &&
          item.itemType !== ItemType.RETAINER && (
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => onUpdate()}
            >
              (update item)
            </span>
          )}
      </li>
      <div style={{ marginLeft: "3rem" }}>
        <li>
          <span style={{ fontWeight: "bold" }}>ItemType:</span>
          &nbsp;{item.itemType}
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>Teeth:</span>
          &nbsp;{item?.teeth?.join(", ")}
        </li>
        <li>
          <span style={{ fontWeight: "bold" }}>TeethToManufacture:</span>
          &nbsp;{item?.teethToManufacture?.join(", ")}
        </li>
        {item?.itemMaterial?.label && (
          <li>
            <span style={{ fontWeight: "bold" }}>ItemMaterial:</span>
            &nbsp;{item?.itemMaterial?.label}
          </li>
        )}
        {item?.teethShade?.label && (
          <li>
            <span style={{ fontWeight: "bold" }}>TeetShade:</span>
            &nbsp;{item?.teethShade?.label}
          </li>
        )}
        {item?.gingivaShade?.label && (
          <li>
            <span style={{ fontWeight: "bold" }}>GingivaShade:</span>
            &nbsp;{item?.gingivaShade?.label}
          </li>
        )}
        {item?.gingivaShade?.label && (
          <li>
            <span style={{ fontWeight: "bold" }}>Workflow Type:</span>
            &nbsp;{item?.workflowType}
          </li>
        )}
      </div>
    </>
  );
};

export default RemovableItemComponent;
