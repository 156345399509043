import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Space, Table, TextInput } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { STYLE, SearchInput } from "@jasper/shared";

import { GET_ALL_GROUPS } from "../../gql/";

const _TAKE_ = 20;

const GroupList = () => {
  const [allGroups, setAllGroups] = useState<{ id: string; name: string }>([]);
  const [skipGroupQuery, setSkipGroupQuery] = useState<number>(0);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchInputDebounce] = useDebounce(searchInput, 500);

  const navigate = useNavigate();

  const { data } = useQuery(GET_ALL_GROUPS, {
    variables: {
      take: _TAKE_,
      skip: skipGroupQuery,
      where: searchInputDebounce
        ? {
            name: {
              contains: searchInputDebounce,
              mode: "insensitive",
            },
          }
        : {},
      orderBy: [
        {
          name: "asc",
        },
      ],
    },
    onCompleted: data => {
      setAllGroups([...allGroups, ...(data?.getAllGroups ?? [])]);
      if (
        (data?.getAllGroups ?? []).length > 0 &&
        data?.getAllGroups.length % _TAKE_ === 0
      ) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);
      }
    },
    fetchPolicy: "network-only",
  });

  return (
    <>
      <SearchInput
        onChange={value => {
          if (searchInput !== value) {
            setAllGroups([]);
            setSkipGroupQuery(0);
          }
          setSearchInput(value);
        }}
        searchInput={searchInput}
        label="Search group by name"
        placeholder="Search group by name"
      />
      <div style={{ textAlign: "right" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={() => navigate("/groups/new")}
        >
          Create a new group
        </Button>
      </div>
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Group name</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {allGroups.map(group => {
            return (
              <Table.Tr
                key={group.id}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/groups/${group.id}`)}
              >
                <Table.Td>{group.name}</Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
      <Space h="md" />
      {showLoadMoreButton && (
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => setSkipGroupQuery(allGroups.length)}
            style={{ backgroundColor: STYLE.primary }}
          >
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

export default GroupList;
