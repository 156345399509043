import React from "react";
import { Title } from "@mantine/core";
import { ItemType, SelectorButtonRef, ShadeSelection } from "@jasper/shared";

const PartialDentureProthesis = ({
  materialChoices,
  gingivaShadeChoices,
  order,
  accountProductPreferences,
  anatomyItemChoicesData,
  rhf,
}) => {
  const watchMaterial = rhf.watch("material");
  const watchGingivaShade = rhf.watch("gingivaShade");
  const watchShadeType = rhf.watch("shadeType");

  return (
    <>
      <Title order={2}>Matériau et teinte - prothèse amovible partielle</Title>
      <SelectorButtonRef
        label="Matériau"
        name="material"
        data={materialChoices}
        onSubmit={selectedValue => rhf.setValue("material", selectedValue)}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
        watch={watchMaterial}
      />
      <ShadeSelection
        watchShadeType={watchShadeType}
        control={rhf.control}
        errors={rhf.formState.errors}
        anatomyItemChoicesData={anatomyItemChoicesData}
        onSelect={selectedValue => rhf.setValue("shadeType", selectedValue)}
        order={order}
        accountProductPreferences={accountProductPreferences}
        itemType={ItemType.PARTIAL_DENTURE}
        rhf={rhf}
      />
      <SelectorButtonRef
        label="Teinte de gencive"
        name="gingivaShade"
        data={gingivaShadeChoices}
        onSubmit={selectedValue => rhf.setValue("gingivaShade", selectedValue)}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
        watch={watchGingivaShade}
      />
    </>
  );
};

export default PartialDentureProthesis;
