import { gql } from "@apollo/client";

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $args: ChatCreateInput!
    $supplier: String!
    $olderChatRead: Boolean!
  ) {
    sendMessage(
      args: $args
      supplier: $supplier
      olderChatRead: $olderChatRead
    ) {
      id
      createdAt
      orderId
      files {
        id
      }
      updatedAt
      userGroupId
      userGroup {
        name
        groupType
        id
      }
      message
    }
  }
`;

export const GET_ALL_MESSAGES_OF_ORDER = gql`
  query GetAllChatsOfOrder($skip: Int, $take: Int, $where: ChatWhereInput) {
    getAllChatsOfOrder(skip: $skip, take: $take, where: $where) {
      notification {
        createdAt
        files {
          fileType
          key
          keyCompressed
          createdAt
          userId
          orderId
        }
        id
        orderId
        userGroup {
          name
          groupType
          id
        }
        userGroupId
        message
        read
      }
      count
    }
  }
`;

export const GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER = gql`
  query GetLastUnreadOfEachOrder($args: ChatInput!) {
    getLastUnreadOfEachOrder(args: $args) {
      notification {
        id
        createdAt
        userGroup {
          name
          groupType
          id
        }
        order {
          orderReference
          id
          status
          patient {
            firstName
            lastName
          }
          provider {
            id
          }
        }
        files {
          key
        }
        message
        read
      }
      count
    }
  }
`;

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead(
    $data: ChatUpdateManyMutationInput!
    $where: ChatWhereInput!
    $supplier: String!
  ) {
    markNotificationsAsRead(data: $data, where: $where, supplier: $supplier)
  }
`;

export const MARK_CHAT_MESSAGES_AS_READ = gql`
  mutation MarkMessagesOfChatAsRead(
    $data: ChatUpdateManyMutationInput!
    $where: ChatWhereInput!
    $supplier: String!
  ) {
    markMessagesOfChatAsRead(data: $data, where: $where, supplier: $supplier)
  }
`;

export const MARK_HELP_MESSAGES_AS_READ = gql`
  mutation MarkHelpMessageAsRead(
    $data: ChatUpdateManyMutationInput!
    $where: ChatWhereInput!
    $supplier: String!
  ) {
    markHelpMessageAsRead(data: $data, where: $where, supplier: $supplier)
  }
`;

export const MARK_CHAT_AS_VIEWED = gql`
  mutation MarkChatAsViewed($where: ChatMarkAsUnreadWhereInput!) {
    markChatAsViewed(where: $where)
  }
`;

export const SET_LAST_CHAT_OF_ORDER_AS_UNREAD = gql`
  mutation MarkLastMessageAsUnread($orderId: String!, $supplier: String!) {
    markLastMessageAsUnread(orderId: $orderId, supplier: $supplier)
  }
`;
