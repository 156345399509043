import { UserType } from "@jasper/shared";
import { File as SharedFile } from "@jasper/shared/types/interfaces";

export const getLetterOfUser = (fristName: string, userType: UserType) => {
  return userType === UserType.JASPER ? "J" : fristName.slice(0, 1);
};

export interface messages {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userGroup: {
    name: string;
    groupType: UserType;
    id: string;
  };
  message: string;
  orderId: string;
  files: SharedFile[] | undefined;
}
