import { gql } from "@apollo/client";

export const GET_ALL_PATIENTS = gql`
  query Patients(
    $orderBy: [PatientOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $where: PatientWhereInput
  ) {
    patients(orderBy: $orderBy, take: $take, skip: $skip, where: $where) {
      id
      firstName
      lastName
      dentistId
      birthdate
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient(
    $data: PatientUpdateInput!
    $where: PatientWhereUniqueInput!
  ) {
    updatePatient(data: $data, where: $where) {
      id
    }
  }
`;
