import { gql } from "@apollo/client";

export const ARCHIVE_MESSAGES_FOR_ORDER = gql`
  mutation ArchiveMessagesForOrder(
    $data: ChatUpdateManyMutationInput!
    $where: ChatWhereInput!
  ) {
    archiveMessagesForOrder(data: $data, where: $where)
  }
`;
