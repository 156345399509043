import React, { useRef } from "react";
import "./preview-photo.css";
import { File as SharedFile } from "@jasper/shared/types/interfaces";
import { IconX } from "@tabler/icons-react";

export interface PreviewPhotoProps {
  setRenderingFiles: (files: SharedFile[]) => void;
  setTempFiles: (files: SharedFile[]) => void;
  setShowModal: (value: boolean) => void;
  tempFiles: SharedFile[];
  setFiles: (files: File[]) => void;
  input: any;
}

export const PreviewPhoto = ({ ...props }: PreviewPhotoProps) => {
  const containerBlock = useRef(null);

  const removeImages = () => {
    props.setFiles([]);
    props.setTempFiles([]);
    props.input.current.value = "";
  };

  if (props.tempFiles.length === 0) {
    return null;
  }

  return (
    <div
      ref={containerBlock}
      className="preview-container"
    >
      <IconX
        style={{ float: "right", cursor: "pointer", width: "15px" }}
        onClick={() => removeImages()}
      />
      <img
        style={{ width: "100%", height: "auto", cursor: "pointer" }}
        src={props.tempFiles[0]?.path}
        onClick={() => {
          props.setShowModal(true);
          props.setRenderingFiles(props.tempFiles);
          props.input.current.value = "";
        }}
      />
      {props.tempFiles.length > 1 && (
        <span style={{ float: "right" }}>x{props.tempFiles.length}</span>
      )}
    </div>
  );
};
