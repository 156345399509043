import { gql } from "@apollo/client";

export const GET_ALL_MATERIALS = gql`
  query GetAllItemMaterials {
    getAllItemMaterials {
      id
      label
      crown
    }
  }
`;
