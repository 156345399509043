import { gql } from "@apollo/client";

export const GET_USER_PRODUCT_PREFERENCES = gql`
  query GetUserProductPreferences($where: ProductPreferencesWhereInput) {
    getUserProductPreferences(where: $where) {
      id
      shadeType
      productPreferenceType
      fixedProsthesisPreferences {
        itemType
        occlusalContactTightness
        proximalContactTightness
        noOcclusalClearance
        occlusalStaining
        interproximalContactDesign
        anteriorPosteriorPonticDesign
      }
      removableProsthesisPreferences {
        itemType
        splintHardness
        teethShape
      }
    }
  }
`;

export const GET_ALL_PROVIDERS = gql`
  query GetAllProviders {
    getAllProviders {
      id
      name
      displayPriority
    }
  }
`;

export const GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES = gql`
  query GetAccountProductPreferences {
    getAccountProductPreferences {
      id
      shadeType
      productPreferenceType
      dentist {
        id
        firstName
        lastName
      }
      fixedProsthesisPreferences {
        id
        itemType
        occlusalContactTightness
        proximalContactTightness
        noOcclusalClearance
        occlusalStaining
        interproximalContactDesign
        anteriorPosteriorPonticDesign
      }
      removableProsthesisPreferences {
        id
        itemType
        splintHardness
        teethShape
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllGroups(
    $skip: Int
    $take: Int
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
  ) {
    users(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      lastName
      firstName
      email
      isManager
    }
  }
`;

export const GET_USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      isManager
      clientBilling {
        id
        pricingType
      }
      email
      firstName
      lastName
      role
      type
      userGroup {
        id
        name
        clinicNumber
        addresses {
          id
          city
          country
          name
          postcode
          streetName
          streetNumber
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation Mutation($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($args: UserCreateInput!) {
    createUser(args: $args) {
      id
    }
  }
`;
