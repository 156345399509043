import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";

import SelectorButtonRef from "../SelectorButtonRef";
import SelectInputRef from "../SelectInputRef";
import ShadeSelection from "../shadeSelection";
import { STYLE } from "../../types/constants";
import { deduceShadeType } from "../../utils/item.utils";
import { ItemType, TeethShadeType, TeethShadeSide } from "../../types/enums";

const CreateNewBridge = ({
  materialFormChoices,
  accountProductPreferences,
  order,
  anatomyItemChoicesData,
  onSubmit,
}) => {
  const location = useLocation();

  const savedItem = (location?.state?.product?.anatomyItem ?? []).find(
    item => item.itemType === ItemType.BRIDGE
  );

  const rhf = useForm({
    defaultValues: {
      material: savedItem?.itemMaterial?.id,
      shade: savedItem?.itemShade?.id,
      shadeType: deduceShadeType(
        savedItem?.itemShade?.id,
        order?.user?.id,
        accountProductPreferences
      ),
      isMultiShade: savedItem?.teethshadeType === TeethShadeType.MULTI_SHADE,
      gingivalShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.GINGIVAL
        )?.itemShade?.id ?? undefined,
      baseShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.BASE
        )?.itemShade?.id ?? undefined,
      incisalShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.INCISAL
        )?.itemShade?.id ?? undefined,
    },
  });

  const watchShadeType = rhf.watch("shadeType");
  const watchMaterial = rhf.watch("material");

  const submit = data => {
    onSubmit(data, data.isMultiShade, savedItem);
  };

  return (
    <>
      <SelectInputRef
        label="Matériau de la couronne"
        name="material"
        data={materialFormChoices}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
      />
      {watchMaterial !== "METAL_NON_PRECIOUS" && (
        <ShadeSelection
          watchShadeType={watchShadeType}
          user={order?.user}
          control={rhf.control}
          errors={rhf.formState.errors}
          anatomyItemChoicesData={anatomyItemChoicesData}
          onSelect={selectedValue => rhf.setValue("shadeType", selectedValue)}
          order={order}
          accountProductPreferences={accountProductPreferences}
          rhf={rhf}
          itemType={ItemType.BRIDGE}
        />
      )}
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={rhf.handleSubmit(submit)}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default CreateNewBridge;
