import { Breadcrumbs } from "@mantine/core";

import { useLocation, useNavigate } from "react-router-dom";
import {
  isUserStaffNotAdmin,
  isUserProvider,
  OrderStatus,
} from "@jasper/shared";

interface LabsBreadcrumbsProps {
  orderReference?: string;
  orderId?: string;
  navigate: (href: string | number) => void;

  user: any; // TODO: Add proper type
  searchText?: string;
}

interface BreadcrumbItem {
  title: string;
  href: string | number;
}

const LabsBreadcrumbs: React.FC<LabsBreadcrumbsProps> = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const isOrderEditPage = (): boolean => {
    const url = window.location.href;
    return url.includes("orders") && url.includes("edit");
  };

  const goToPreviousPage = (): boolean => {
    return (
      location?.state?.from === "HELP_LIST" ||
      location?.state?.from === "CHAT_LIST"
    );
  };

  const getUrlForDefaultPage = () => {
    if (isUserStaffNotAdmin(props.user)) {
      return "/groups";
    }

    const baseURL = isUserProvider(props.user)
      ? `/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0`
      : `/home?status=ALL&page=0`;

    // Add search only if it's not empty
    return props.searchText ? `${baseURL}&search=${props.searchText}` : baseURL;
  };

  const getNameForDefaultPage = (): string => {
    return location?.state?.from === "HELP_LIST" ||
      location?.state?.from === "CHAT_LIST"
      ? "Notifications"
      : "Orders";
  };

  const items: BreadcrumbItem[] = [
    { title: getNameForDefaultPage(), href: getUrlForDefaultPage() },
    { title: props.orderReference || "", href: `/orders/${props.orderId}` },
    ...(isOrderEditPage()
      ? [{ title: "Edit", href: `/orders/${props.orderId}/edit/` }]
      : []),
  ];

  return (
    <>
      <Breadcrumbs>
        {items.map((item, index) => (
          <div key={index}>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                props.navigate(goToPreviousPage() ? -1 : item.href)
              }
            >
              {item.title}
            </span>
          </div>
        ))}
      </Breadcrumbs>
    </>
  );
};

export default LabsBreadcrumbs;
