import { Space, Button } from "@mantine/core";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";

import {
  TextInputRef,
  SelectInputRef,
  ItemType,
  STYLE,
  AbutmentType,
  RetentionType,
  ANATOMY_ITEM_CHOICE_DATA,
  getAnatomyItemChoiceWhere,
  ShadeSelection,
  deduceShadeType,
} from "@jasper/shared";

import {
  GET_ALL_IMPLANT_REFERENCES,
  CREATE_IMPLANT_ITEM,
  UPDATE_IMPLANT_ITEM,
} from "../../../gql/";

import { ItemMaterial } from "@jasper/shared/types/interfaces";

const CreateImplantItemComponent = ({
  closeModal,
  productId,
  refetch,
  item,
  accountProductPreferences,
  order,
}: {
  closeModal: () => void;
  productId: string;
  refetch: () => void;
  item?: any; // TODO: Add proper type
  accountProductPreferences?: any; // TODO: Add proper type
  order?: any; // TODO: Add proper type
}) => {
  const rhf = useForm({
    defaultValues: {
      abutmentMaterial: item?.abutmentMaterial,
      abutmentType: item?.abutmentType,
      implantPlatform: item?.implantPlatform,
      implantReference: item?.implantReference,
      implantReferenceSuggestion: item?.implantReferenceSuggestion,
      material: item?.itemMaterial?.id,
      shade: item?.itemShade?.id,
      itemType: item?.itemType,
      retentionType: item?.retentionType,
      scanBodySuggestion: item?.scanBodySuggestion,
      standardAbutmentSuggestion: item?.standardAbutmentSuggestion,
      teeth: item?.teeth?.join(","),
      shadeType: deduceShadeType(
        item?.itemShade?.id,
        order?.user?.id,
        accountProductPreferences
      ),
    },
  });

  const watchMaterial = rhf.watch("material");
  const watchShadeType = rhf.watch("shadeType");

  const { data: allMaterials, loading: loadingMaterials } = useQuery(
    ANATOMY_ITEM_CHOICE_DATA,
    {
      variables: {
        where: getAnatomyItemChoiceWhere(item?.itemType),
      },
    }
  );
  const { data: allImplantReferences } = useQuery(GET_ALL_IMPLANT_REFERENCES);

  const [createImplantItem] = useMutation(CREATE_IMPLANT_ITEM);
  const [updateImplantItem] = useMutation(UPDATE_IMPLANT_ITEM);

  const getAllMaterials = () => {
    return (allMaterials?.getItemMaterialsWhere ?? []).map(
      (material: ItemMaterial) => ({
        value: material.id,
        label: material.label,
      })
    );
  };

  const getAllImplantReferences = () => {
    return (allImplantReferences?.getAllImplantReferences ?? []).map(ref => ({
      value: ref.id,
      label: ref.label,
    }));
  };

  const submit = async (data: typeof allImplantReferences) => {
    try {
      if (item?.id) {
        await updateImplantItem({
          variables: {
            data: {
              abutmentMaterial: data.abutmentMaterial
                ? {
                    connect: {
                      id: data.abutmentMaterial,
                    },
                  }
                : undefined,
              abutmentType: data?.abutmentType
                ? {
                    set: data.abutmentType,
                  }
                : undefined,
              implantPlatform: data.implantPlatform
                ? {
                    set: data.implantPlatform,
                  }
                : undefined,
              implantReference: data.implantReference?.id
                ? {
                    connect: {
                      id: data.implantReference?.id,
                    },
                  }
                : undefined,
              implantReferenceSuggestion: data.implantReferenceSuggestion
                ? {
                    set: data.implantReferenceSuggestion,
                  }
                : undefined,
              itemMaterial: data.material
                ? {
                    connect: {
                      id: data.material,
                    },
                  }
                : undefined,
              itemShade: data.shade
                ? {
                    connect: {
                      id: data.shade,
                    },
                  }
                : undefined,
              itemType: {
                set: data.itemType,
              },
              retentionType: data.retentionType
                ? {
                    set: data.retentionType,
                  }
                : undefined,
              scanBodySuggestion: data.scanBodySuggestion
                ? {
                    set: data.scanBodySuggestion,
                  }
                : undefined,
              standardAbutmentSuggestion: data.standardAbutmentSuggestion
                ? {
                    set: data.standardAbutmentSuggestion,
                  }
                : undefined,
              teeth: data?.teeth.split(",").map(t => parseInt(t)) || undefined,
            },
            where: {
              id: item.id,
            },
          },
        });
        notifications.show({
          title: "Implant item updated",
          color: "green",
          message: "",
        });
      } else {
        await createImplantItem({
          variables: {
            args: {
              abutmentMaterial: data.abutmentMaterial
                ? {
                    connect: {
                      id: data.abutmentMaterial,
                    },
                  }
                : undefined,
              abutmentType: data.abutmentType,
              implantPlatform: data.implantPlatform,
              implantReference: data.implantReference
                ? {
                    connect: {
                      id: data.implantReference,
                    },
                  }
                : undefined,
              implantReferenceSuggestion: data.implantReferenceSuggestion,
              itemMaterial: data.material
                ? {
                    connect: {
                      id: data.material,
                    },
                  }
                : undefined,
              itemShade: data.shade
                ? {
                    connect: {
                      id: data.shade,
                    },
                  }
                : undefined,
              itemType: data.itemType,
              product: {
                connect: {
                  id: productId,
                },
              },
              retentionType: data.retentionType,
              scanBodySuggestion: data.scanBodySuggestion,
              standardAbutmentSuggestion: data.standardAbutmentSuggestion,
              teeth: data?.teeth?.split(",").map(t => parseInt(t)) || undefined,
            },
          },
        });
        notifications.show({
          title: "Implant item created",
          color: "green",
          message: "",
        });
      }
      refetch();
      closeModal();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to create or update implant item",
        color: "red",
        message: "",
      });
    }
  };

  if (loadingMaterials) {
    return null;
  }

  return (
    <>
      <SelectInputRef
        name="itemType"
        label="Item type *"
        data={Object.keys(ItemType)}
        errors={rhf.formState.errors}
        control={rhf.control}
      />
      <Space h="md" />
      <TextInputRef
        name="teeth"
        label="Teeth"
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <SelectInputRef
        name="material"
        label="Material"
        data={getAllMaterials()}
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      {watchMaterial !== "METAL_NON_PRECIOUS" && (
        <ShadeSelection
          watchShadeType={watchShadeType}
          control={rhf.control}
          errors={rhf.formState.errors}
          anatomyItemChoicesData={allMaterials ?? []}
          onSelect={selectedValue => rhf.setValue("shadeType", selectedValue)}
          order={order}
          accountProductPreferences={accountProductPreferences}
          rhf={rhf}
          itemType={ItemType.IMPLANT}
        />
      )}
      <Space h="md" />
      <SelectInputRef
        name="retentionType"
        label="Retention Type"
        data={Object.keys(RetentionType)}
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <SelectInputRef
        name="abutmentType"
        label="Abutment Type"
        data={Object.keys(AbutmentType)}
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <SelectInputRef
        name="abutmentMaterial"
        label="Abutment Material"
        data={getAllMaterials()}
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <TextInputRef
        name="standardAbutmentSuggestion"
        label="Standard abutment suggestion"
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <SelectInputRef
        name="implantReference"
        label="Implant reference"
        data={getAllImplantReferences()}
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <TextInputRef
        name="implantReferenceSuggestion"
        label="Implant reference suggestion"
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <TextInputRef
        name="implantPlatform"
        label="Implant platform"
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <TextInputRef
        name="scanBodySuggestion"
        label="Scanbody suggestion"
        errors={rhf.formState.errors}
        control={rhf.control}
        required={false}
      />
      <Space h="md" />
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={rhf.handleSubmit(submit)}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default CreateImplantItemComponent;
