import React, { FC } from "react";
import { Box, Text } from "@mantine/core";

import {
  getImplantRetentionTypeLabel,
  getItemAbutmentMaterialLabel,
  getItemAbutmentTypeLabel,
  getItemInlayCoreHasClavetteLabel,
  getItemInlayCoreMaterialLabel,
  getItemMaterialLabel,
  getItemShadeLabel,
  getItemTypeWithCountLabel,
  sortItems,
  getItemMultiShadeLabel,
  getItemInlayCoreScanPostTranslated,
} from "../utils/item.utils";
import {
  getArchFromTeeth,
  getTeethLabel,
  isAnatomyItemType,
  isImplantItemType,
  isRemovableItemType,
} from "../utils/product.utils";
import {
  Product,
  Order,
  Item,
  AnatomyItem,
  ImplantItem,
  RemovableItem,
} from "../types/interfaces";
import {
  ItemType,
  TeethShadeType,
  AbutmentType,
  WorkflowType,
  WaxUpType,
  WaxUpTypeEn,
  WaxUpTypeFr,
} from "../types/enums";
import { translateItemMaterial } from "../utils/translation.utils";

type ItemsDescriptionProps = {
  product: Product;
  order?: Order;
  shouldTranslate: boolean;
};

const ItemsDescription: FC<ItemsDescriptionProps> = ({
  product,
  shouldTranslate,
}) => {
  if (!product) return null;

  const getItems = (product: Product) => {
    const productType = product?.productType;

    if (isAnatomyItemType(productType)) {
      return product?.anatomyItem;
    }
    if (isImplantItemType(productType)) {
      return product?.implantItem;
    }
    if (isRemovableItemType(productType)) {
      return product?.removableItem;
    }
    if (productType === ItemType.SCAN_ONLY) {
      return product.removableItem;
    }
    return null;
  };

  let items = getItems(product);
  if (!items || (Array.isArray(items) && items.length < 1)) return null;
  if (!Array.isArray(items)) {
    items = [items];
  }

  const {
    occlusalStaining,
    occlusalContactTightness,
    proximalContactTightness,
    interproximalContactDesign,
    anteriorPosteriorPonticDesign,
  } = (product.itemPreferences?.set ?? product.itemPreferences) || {};

  const showItemOrProductTeeths = (productType: ItemType): boolean => {
    if (isAnatomyItemType(productType) || isImplantItemType(productType)) {
      return true;
    }
    return false;
  };

  const getItemName = (
    item: AnatomyItem | ImplantItem | RemovableItem,
    shouldTranslate: boolean
  ) => {
    const itemTypeWithCount = getItemTypeWithCountLabel(item, shouldTranslate);
    const teethLabel = getTeethLabel(
      item.itemType,
      item.teethLabel,
      item.teeth,
      shouldTranslate
    );
    return `${itemTypeWithCount} ${teethLabel}`;
  };

  const getRemovableWorkflowType = (item: Item) => {
    switch (item?.workflowType) {
      case WorkflowType.WAX_RIM:
        return "+ Wax rim";
      case WorkflowType.ONE_APPOINTMENT_DIRECT:
        return "+ Direct finish";
      case WorkflowType.WITH_FITTING:
        return "+ Set up try in";
      default:
        return "";
    }
  };

  const displayItemTeeth = (teeth: number[]) => {
    if (!teeth) {
      return "";
    }
    return `(${teeth?.join(", ")})`;
  };

  const deduceWaxUpType = (
    type: WaxUpType,
    shouldTranslate: boolean = false
  ) => {
    if (shouldTranslate) {
      return WaxUpTypeEn[type];
    }
    return WaxUpTypeFr[type];
  };

  return (
    <Box>
      {sortItems(items, product.productType).map((item: any) => {
        if (item.itemType === ItemType.BRIDGE_PONTIC) {
          return null;
        }
        return (
          <Box
            key={item.id}
            style={{ textWrap: "wrap" }}
          >
            <Text
              size="sm"
              style={{ textAlign: "left", whiteSpace: "wrap" }}
            >
              {getItemName(item, shouldTranslate)}{" "}
              {showItemOrProductTeeths(product.productType) &&
                displayItemTeeth(
                  item.itemType === ItemType.BRIDGE_IMPLANT
                    ? product?.teeth
                    : item?.teeth
                )}
              {getRemovableWorkflowType(item)}
            </Text>
            {item.itemType !== ItemType.INLAY_CORE && (
              <div style={{ marginLeft: "1rem", textAlign: "left" }}>
                {([
                  ItemType.IMMEDIATE_DENTURE,
                  ItemType.FULL_DENTURE,
                  ItemType.PARTIAL_DENTURE,
                  ItemType.SPLINT,
                  ItemType.BLEACHING_TRAY,
                  ItemType.RETAINER,
                  ItemType.VENEER,
                ].includes(product.productType) ||
                  item?.itemType === ItemType.RETENTION_WIRE) && (
                  <Text size="sm">
                    {`- ${getArchFromTeeth(item.teeth, shouldTranslate)}`}
                  </Text>
                )}
                {item.itemType === ItemType.WAX_UP && (
                  <Text size="sm">
                    {`- ${deduceWaxUpType(item?.waxUpType, shouldTranslate)}`}
                  </Text>
                )}
                {item.itemType === ItemType.PARTIAL_DENTURE && (
                  <Text size="sm">
                    {`- To include : ${item.teeth.join(", ")}`}
                  </Text>
                )}
                {product?.itemPreferences?.set?.teethShape && (
                  <Text size="sm">
                    {`- Shape : ${product?.itemPreferences?.set?.teethShape}`}
                  </Text>
                )}
                {item?.gingivaShade && (
                  <Text size="sm">
                    {`- Gingiva shade : ${item?.gingivaShade.id}`}
                  </Text>
                )}
                {item?.isReplacement && item?.isReplacement === true && (
                  <Text size="sm">{`- Replacement`}</Text>
                )}
                {item?.itemMaterial && (
                  <Text size="sm">
                    {`- ${shouldTranslate ? translateItemMaterial(item?.itemMaterial?.id) : getItemMaterialLabel(item)}`}
                  </Text>
                )}
                {item?.itemShade && (
                  <Text size="sm">{`- ${getItemShadeLabel(item)}`}</Text>
                )}
                {item?.teethShade && (
                  <Text size="sm">{`- ${getItemShadeLabel(item)}`}</Text>
                )}
                {item?.teethshadeType === TeethShadeType.MULTI_SHADE && (
                  <>
                    <Text size="sm">
                      - {shouldTranslate ? "Shade" : "Teinte"}:
                    </Text>
                    <Text
                      size="sm"
                      style={{ whiteSpace: "pre-line", marginLeft: "1rem" }}
                    >{`${getItemMultiShadeLabel(item, shouldTranslate)}`}</Text>
                  </>
                )}
              </div>
            )}
            <div style={{ marginLeft: "1rem", textAlign: "left" }}>
              {((occlusalStaining && occlusalStaining !== "MEDIUM") ||
                (occlusalContactTightness &&
                  occlusalContactTightness !== "MEDIUM") ||
                (proximalContactTightness &&
                  proximalContactTightness !== "MEDIUM") ||
                (interproximalContactDesign &&
                  interproximalContactDesign !== "NORMAL_CONTACT_POINT") ||
                anteriorPosteriorPonticDesign) &&
                item?.itemType !== ItemType.INLAY_CORE && (
                  <>
                    <Text size="sm">{`- Preferences`}</Text>
                    <div style={{ marginLeft: "1rem" }}>
                      {occlusalStaining && occlusalStaining !== "MEDIUM" && (
                        <Text size="sm">{`- Occlusal Staining = ${occlusalStaining}`}</Text>
                      )}
                      {occlusalContactTightness &&
                        occlusalContactTightness !== "MEDIUM" && (
                          <Text size="sm">{`- Occlusal Contact Tightness = ${occlusalContactTightness}`}</Text>
                        )}
                      {proximalContactTightness &&
                        proximalContactTightness !== "MEDIUM" && (
                          <Text size="sm">{`- Proximal Contact Tightness = ${proximalContactTightness}`}</Text>
                        )}
                      {interproximalContactDesign &&
                        interproximalContactDesign !==
                          "NORMAL_CONTACT_POINT" && (
                          <Text size="sm">{`- Interporximal Contact Design = ${interproximalContactDesign || "NORMAL_CONTACT_POINT"}`}</Text>
                        )}
                      {anteriorPosteriorPonticDesign && (
                        <Text size="sm">{`- Anterior Posterior Pontic Design = ${anteriorPosteriorPonticDesign}`}</Text>
                      )}
                    </div>
                  </>
                )}
            </div>
            <div style={{ marginLeft: "1rem", textAlign: "left" }}>
              {getItemInlayCoreMaterialLabel(item, shouldTranslate) && (
                <Text size="sm">{`- ${getItemInlayCoreMaterialLabel(item, shouldTranslate)}`}</Text>
              )}
              {getItemInlayCoreHasClavetteLabel(item) && (
                <Text size="sm">{`- ${getItemInlayCoreHasClavetteLabel(item, shouldTranslate)}`}</Text>
              )}
              {getItemInlayCoreScanPostTranslated(item) && (
                <Text size="sm">{`- ${getItemInlayCoreScanPostTranslated(item)}`}</Text>
              )}
              {getImplantRetentionTypeLabel(item) && (
                <Text size="sm">{`- ${getImplantRetentionTypeLabel(item, shouldTranslate)}`}</Text>
              )}
              {getItemAbutmentMaterialLabel(item) && (
                <Text size="sm">{`- ${getItemAbutmentMaterialLabel(item, shouldTranslate)}`}</Text>
              )}
              {item?.itemType === ItemType.IMPLANT &&
                item.implantReferenceSuggestion && (
                  <Text size="sm">{`- Implant reference suggestion: ${item.implantReferenceSuggestion}`}</Text>
                )}
              {item?.itemType === ItemType.IMPLANT &&
                item.scanBodySuggestion && (
                  <Text size="sm">{`- Scan body: ${item.scanBodySuggestion}`}</Text>
                )}
              {item?.itemType === ItemType.IMPLANT && item.implantPlatform && (
                <Text size="sm">{`- Implant platform: ${item.implantPlatform}`}</Text>
              )}
              {item?.itemType === ItemType.IMPLANT && item.implantReference && (
                <>
                  <Text size="sm">{`- Implant brand: ${item.implantReference?.brand}`}</Text>
                  <Text size="sm">{`- Implant reference: ${item.implantReference?.label}`}</Text>
                </>
              )}
              {item?.itemType === ItemType.IMPLANT &&
                item.standardAbutmentSuggestion && (
                  <Text size="sm">{`- Standard abutment suggestion: ${item.standardAbutmentSuggestion}`}</Text>
                )}
              {getItemAbutmentTypeLabel(item) && !shouldTranslate && (
                <Text size="sm">{`- Abutment type: ${getItemAbutmentTypeLabel(item, shouldTranslate)}`}</Text>
              )}
              {item.abutmentType &&
                shouldTranslate &&
                item.abutmentType === AbutmentType.STANDARD && (
                  <Text size="sm">
                    - Standard abutment, we will ship you the parts, please
                    confirm abutment height and diameter
                  </Text>
                )}
              {item.abutmentType &&
                shouldTranslate &&
                item.abutmentType === AbutmentType.STANDARD_GENERIC && (
                  <Text size="sm">- Standard 3rd party abutment</Text>
                )}
              {item.abutmentType &&
                shouldTranslate &&
                item.abutmentType === AbutmentType.CUSTOM && (
                  <Text size="sm">- Abutment type: {item.abutmentType}</Text>
                )}
            </div>
          </Box>
        );
      })}
    </Box>
  );
};

export default ItemsDescription;
