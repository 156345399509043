import { useState } from "react";
import { TextInput, Space, Button, Switch } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";

import { STYLE, UPDATE_USER } from "@jasper/shared";

const AddNewMemberToGroupModal = ({ group_id, closeModal, refetch }) => {
  const [inputEmail, setInputEmail] = useState<string>("");
  const [isUserManager, setIsUserManager] = useState<boolean>(false);

  const [updateUser] = useMutation(UPDATE_USER);

  const onSubmit = async () => {
    try {
      await updateUser({
        variables: {
          where: {
            email: inputEmail,
          },
          data: {
            userGroup: {
              connect: {
                id: group_id,
              },
            },
            isManager: {
              set: isUserManager,
            },
          },
        },
      });
      notifications.show({
        title: "User correctly added",
        color: "green",
        message: "",
      });
      refetch();
      closeModal();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to add user to group",
        color: "red",
        message: "",
      });
    }
  };

  return (
    <>
      <TextInput
        label="Email of user to add"
        value={inputEmail}
        onChange={e => setInputEmail(e.target.value)}
      />
      <Space h="md" />
      <Switch
        label="Will this user be a master account ?"
        description="This action will be effective in all group where this user is a member."
        checked={isUserManager}
        onChange={() => setIsUserManager(!isUserManager)}
      />
      <Space h="md" />
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default AddNewMemberToGroupModal;
