import { ItemType, ItemMaterials } from "../types/enums";

export const translateItemType = (
  itemType: ItemType | typeof MULTIPLE | ""
): string => {
  switch (itemType) {
    case ItemType.INLAY:
      return "Inlay";

    case ItemType.ONLAY:
      return "Onlay";

    case ItemType.OVERLAY:
      return "Overlay";

    case ItemType.CROWN:
      return "Crown";

    case ItemType.BRIDGE:
      return "Bridge";

    case ItemType.INLAY_CORE:
      return "Post & Core";

    case ItemType.RICHMOND_CROWN:
      return "Post & Core IN ONE PIECE (Richmond Crown)";

    case ItemType.SPLINTED_CROWN:
      return "Splinted crowns";

    case ItemType.IMPLANT:
      return "Abutment";

    case ItemType.IMPLANT_CROWN:
      return "Implant Crown";

    case ItemType.BRIDGE_IMPLANT:
      return "Bridge";

    case ItemType.BRIDGE_PONTIC:
      return "Pontic";

    case ItemType.SPLINTED_CROWN_IMPLANT:
      return "Splinted implant crowns";

    case ItemType.PARTIAL_DENTURE:
      return "Partial denture";

    case ItemType.NIGHT_GUARD:
      return "Night Guard";

    case ItemType.BLEACHING_TRAY:
      return "Bleaching Tray";

    case ItemType.RETAINER:
      return "Retainer";

    case ItemType.FULL_DENTURE:
      return "Full Denture";

    case ItemType.IMMEDIATE_DENTURE:
      return "Immediate Denture";

    case ItemType.FULL_DENTURE_WAX_BITE:
      return "Full Denture Wax Bite Rim";

    case ItemType.RETENTION_WIRE:
      return "Retention Wire";

    case ItemType.SPACE_MAINTAINER:
      return "Space Maintainer";

    case ItemType.WAX_UP:
      return "Wax Up";

    case ItemType.ADDITIONAL_IMPRESSION_FOR_REMOVABLE_PROSTHESIS:
      return "Additional impression for removable prosthesis";

    case ItemType.ADDING_TEETH_ON_EXISTING_DENTURE:
      return "Adding teeth on existing denture";

    case ItemType.DENTURE_REPAIR:
      return "Denture repair";

    default:
      return itemType;
  }
};

export const translateItemMaterial = (itemMaterial: ItemMaterials): string => {
  switch (itemMaterial) {
    case ItemMaterials.METAL_NON_PRECIOUS:
      return "Metal";
    case ItemMaterials.PFM_NON_PRECIOUS:
      return "Porcelain Fused To Metal";
    case ItemMaterials.PFM_NON_PRECIOUS_POST_AND_CORE:
      return "Opaque";
    case ItemMaterials.PFM_PRECIOUS:
      return "Porcelain Fused to Precious Metal";
    case ItemMaterials.PORCELAIN_FUSED_ZIRCONIA:
      return "Porcelain Fused to Zirconia";
    case ItemMaterials.ZIRCONIA_FULL:
      return "Full Zirconia";
    case ItemMaterials.ZIRCONIA_MULTILAYER:
      return "3D Multilayer Zirconia";
    case ItemMaterials.EMAX_FULL:
      return "Emax";
    case ItemMaterials.EMAX_MULTILAYER:
      return "3D Emax Multilayer ";
    case ItemMaterials.COMPOSITE_NO_METAL:
      return "Composite";
    case ItemMaterials.TITANIUM:
      return "Titanium ";
    case ItemMaterials.TI_BASE:
      return "Ti-base";
    case ItemMaterials.COBALT_CHROME_METALLIC_FRAME:
      return "Co-Cr Framework ";
    case ItemMaterials.TITANIUM_FRAME:
      return "Titanium Framework";
    case ItemMaterials.VALPLAST:
      return "Valplast";
    case ItemMaterials.ACRYLIC:
      return "Acrylic";
    case ItemMaterials.ZIRCONIA:
      return "Zirconia";
    case ItemMaterials.NIGHT_GUARD_HARD_MATERIAL:
      return "Hard Material";
    case ItemMaterials.NIGHT_GUARD_SOFT_MATERIAL:
      return "Soft Material ";
    case ItemMaterials.NIGHT_GUARD_MEDIUM_MATERIAL:
      return "Medium Material ";

    default:
      return itemMaterial;
  }
};

export const translateInlayCoreMaterial = (inlayCoreMaterial?: string) => {
  switch (inlayCoreMaterial) {
    case "METAL_NON_PRECIOUS":
      return "Metal Post & Core";
    case "PFM_NON_PRECIOUS_POST_AND_CORE":
      return "Opaque Post & Core";
    default:
      return inlayCoreMaterial;
  }
};
