import React, { useState } from "react";
import { Title, Textarea } from "@mantine/core";

const PartialDentureHooks = ({ rhf, defaultComment }) => {
  const [comment, setComment] = useState<string>(defaultComment ?? "");

  return (
    <>
      <Title order={2}>Détails concernant les crochets</Title>
      <Textarea
        label="Notes du praticien (positionnement des crochets, etc.)"
        placeholder="Un commentaire ?"
        minRows={4}
        autosize
        value={comment}
        onChange={e => {
          setComment(e.target.value);
          rhf.setValue("hookComment", e.target.value);
        }}
      />
    </>
  );
};

export default PartialDentureHooks;
