import { Button as MantineButton } from "@mantine/core";

import { STYLE } from "@jasper/shared";
import { ReactNode } from "react";

export const Button = (props: {
  icon?: ReactNode;
  value: string;
  onClick: () => any;
  testid: string;
}) => {
  return (
    <div style={{ marginTop: "1rem", textAlign: "center" }}>
      <MantineButton
        onClick={props.onClick}
        data-testid={props.testid}
        color={STYLE.primary}
      >
        {props.icon && props.icon} {props.value}
      </MantineButton>
    </div>
  );
};
