import React from "react";
import ReactDOM from "react-dom/client";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  HttpLink,
  from,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import Cookies from "js-cookie";
import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";

import App from "/@/App.tsx";
import { getApiUrl } from "/@/config";

Sentry.init({
  dsn: "https://c6218904544949611d0a3e9348089c24@o4507708191145984.ingest.de.sentry.io/4507893518630992",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: import.meta.env.MODE === "production" ? 0.1 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: import.meta.env.MODE === "production" ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE,
});

const httpLink = new HttpLink({
  uri: `${getApiUrl()}/graphql`,
  credentials: "include",
});

import axios from "axios";
const refreshApi = axios.create({
  baseURL: `${getApiUrl()}/`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

const refreshTokenLink = onError(({ graphQLErrors, forward, operation }) => {
  let hasToRefresh = false;
  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i++) {
      if ("response" in graphQLErrors[i].extensions) {
        const statusCode = (
          graphQLErrors[i].extensions.response as {
            statusCode: number;
            message: string;
          }
        ).statusCode;
        if (statusCode === 401) {
          hasToRefresh = true;
        }
      }
    }
  }
  if (hasToRefresh) {
    Cookies.remove("Authentication");
    return new Observable(observer => {
      refreshApi
        .get("auth/refresh-token")
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };
          forward(operation).subscribe(subscriber);
        })
        .catch(err => {
          observer.error(err);
        });
    });
  }
});

const client = new ApolloClient({
  link: from([refreshTokenLink, httpLink]),
  cache: new InMemoryCache(),
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <MantineProvider>
        <Notifications position="bottom-center" />
        <App />
      </MantineProvider>
    </ApolloProvider>
  </React.StrictMode>
);
