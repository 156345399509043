import React from "react";
import { Text, Select, Space } from "@mantine/core";
import { Controller, Control, FieldErrors } from "react-hook-form";
import { MantineProvider } from "@mantine/core";

interface SelectInputRefProps {
  name: string;
  data: any[]; // TODO: Define a stricter type if possible
  label: string;
  errors: FieldErrors<any>;
  control: Control<any>;
  required?: boolean;
  disabled?: boolean;
  subText?: string;
}

const SelectInputRef: React.FC<SelectInputRefProps> = ({
  name,
  data,
  label,
  errors,
  control,
  required = true,
  disabled = false,
  subText = "",
  searchable = false,
  clearable = false,
}) => {
  return (
    <MantineProvider>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <Select
            label={label}
            data={data}
            disabled={disabled}
            searchable={searchable}
            clearable={clearable}
            {...field}
          />
        )}
      />
      {errors?.[name]?.type === "required" && (
        <Text
          size="sm"
          style={{ color: "red" }}
        >
          This field is required
        </Text>
      )}
      {subText && (
        <Text
          size="xs"
          fs="italic"
        >
          {subText}
        </Text>
      )}
      <Space h="md" />
    </MantineProvider>
  );
};

export default SelectInputRef;
