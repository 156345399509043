import { useState } from "react";
import { Select, Button } from "@mantine/core";

import { STYLE } from "@jasper/shared";

const ChangeDentistModal = (props: {
  dentistId: string;
  allDentists: { id: string; value: string }[];
  searchDentistInput: string;
  setSearchDentistInput: (value: string) => void;
  onSubmit: (dentistId: string) => void;
}) => {
  const [dentistSelected, setDentistSelected] = useState<string>("");

  return (
    <>
      <Select
        name="dentist"
        label="Select a new dentist"
        data={props.allDentists ?? []}
        searchable
        clearable
        searchValue={props.searchDentistInput}
        onSearchChange={value => props.setSearchDentistInput(value)}
        onChange={dentistId => setDentistSelected(dentistId)}
      />
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={() => props.onSubmit(dentistSelected)}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default ChangeDentistModal;
