import { useState } from "react";
import { Button, Modal, Tooltip, Menu, Flex } from "@mantine/core";
import { useMutation, useQuery } from "@apollo/client";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { Notifications } from "@mantine/notifications";
import { useDebounce } from "use-debounce";

import { STYLE } from "../types/constants";
import { OrderStatus, UserType } from "../types/enums";
import { REFRESH_PASTILLE, UPDATE_ORDER } from "../gql/orders";
import { isUserStaff, isUserProvider } from "../utils/user.utils";
import { GET_ALL_PROVIDERS, GET_ALL_USERS } from "../gql/users";
import RedoModal from "./modals/RedoModal";
import UpdateDeliveryDateModal from "./modals/updateDeliveryDateModal";
import UpdateMaterialAndLotModal from "./modals/updateMaterialAndLotModal";
import SendOrderToSupplierModal from "./modals/sendOrderToSupplierModal";
import ChangeDentistModal from "./modals/ChangeDentistModal";
import {
  IconArchive,
  IconArrowLeftFromArc,
  IconCalendarCog,
  IconEdit,
  IconHandStop,
  IconListDetails,
  IconLogs,
  IconMailbox,
  IconPlaneDeparture,
  IconProgress,
  IconRepeat,
  IconSortAscendingShapes,
  IconStatusChange,
  IconTransfer,
  IconArrowIteration,
  IconArchiveOff,
} from "@tabler/icons-react";
import { ARCHIVE_MESSAGES_FOR_ORDER } from "../gql/chat";
import {
  sendMailToProvider,
  GENERATE_TRACEABILITY_DOCUMENT,
  getValideFiles,
} from "@jasper/shared";

const __TAKE__ = 50;

const HeaderOrderView = ({ order, user, refetch, refetchFiles }) => {
  const [sendMail] = useMutation(sendMailToProvider);
  const [generateTracebilitySheet] = useMutation(
    GENERATE_TRACEABILITY_DOCUMENT
  );
  const [openMaterialAndLotModal, setOpenMaterialAndLotModal] = useState(false);
  const [openDeliveryDateModal, setOpenDeliveryDateModal] = useState(false);
  const [openRedoModal, setOpenRedoModal] = useState(false);
  const [openSendToSupplierModal, setOpenSendToSupplierModal] = useState(false);
  const [openModalOrderHistory, setOpenModalOrderHistory] = useState(false);
  const [changeDentistModalOpened, setChangeDentistModalOpened] =
    useState(false);
  const [searchDentistInput, setSearchDentistInput] = useState<string>("");
  const [searchDentistInputDebounce] = useDebounce(searchDentistInput, 500);

  const navigate = useNavigate();

  const [updateOrder] = useMutation(UPDATE_ORDER);
  const [updateChat] = useMutation(ARCHIVE_MESSAGES_FOR_ORDER);
  const { data: allProviders } = useQuery(GET_ALL_PROVIDERS);
  const [refreshPastille] = useMutation(REFRESH_PASTILLE);
  const { data: allDentists } = useQuery(GET_ALL_USERS, {
    variables: {
      where: {
        type: {
          equals: UserType.DENTIST,
        },
        AND: [
          {
            OR: [
              ...searchDentistInputDebounce
                .trim()
                .split(" ")
                .reduce((acc, word: string) => {
                  acc.push(
                    ...[
                      {
                        firstName: {
                          contains: word,
                          mode: "insensitive",
                        },
                      },
                      {
                        lastName: {
                          contains: word,
                          mode: "insensitive",
                        },
                      },
                    ]
                  );
                  return acc;
                }, []),
            ],
          },
        ],
      },
      take: __TAKE__,
    },
  });

  const getAllUntranslatedComment = () => {
    return (
      (order?.orderComment ?? []).filter(
        comment => !comment.translatedComment && comment.comment
      ) ?? []
    );
  };

  const archiveAndSetAsDelivered = async () => {
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.DELIVERED,
            },
            isArchived: {
              set: true,
            },
          },
        },
      });
      refetch();
      notifications.show({
        title: "Order is correctly archived and set as delivered",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to archived order and set it as delivered",
        color: "red",
        message: "",
      });
    }
  };
  const archiveAndSetAsNotDelievered = async () => {
    try {
      const sortedStatusList = [...order?.history]
        .filter(
          orderStatus => !orderStatus.status.includes(OrderStatus.DELIVERED)
        )
        .sort((a, b) => a?.createdAt - b?.createdAt);
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status:
              sortedStatusList.length > 0
                ? {
                    set: sortedStatusList[sortedStatusList.length - 1].status,
                  }
                : undefined,
            isArchived: {
              set: false,
            },
          },
        },
      });
      refetch();
      notifications.show({
        title: `Order is correctly unarchived`,
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to unarchived order",
        color: "red",
        message: "",
      });
    }
  };

  const startProduction = async (
    providerGroupId?: string,
    sendDeleteCaseEmail: boolean = false,
    previousProviderGroupId?: string
  ) => {
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: providerGroupId
                ? OrderStatus.WAITING_FOR_PRODUCTION
                : OrderStatus.PRODUCING,
            },
            isArchived: {
              set: false,
            },
            provider: providerGroupId
              ? {
                  connect: {
                    id: providerGroupId,
                  },
                }
              : undefined,
          },
        },
      });
      refreshPastille({
        variables: {
          args: [
            {
              userGroupId: providerGroupId,
              previousUserGroupId: previousProviderGroupId,
              orderId: order.id,
              previousStatus: order.status,
              currentStatus: providerGroupId
                ? OrderStatus.WAITING_FOR_PRODUCTION
                : OrderStatus.PRODUCING,
            },
          ],
        },
      });
      refetch();
      if (providerGroupId) {
        await sendMail({
          variables: {
            args: {
              orderId: order.id,
              sendDeleteCaseEmail: sendDeleteCaseEmail,
              previousProviderGroupId: previousProviderGroupId,
            },
          },
        });
        await updateChat({
          variables: {
            where: {
              orderId: {
                equals: order.id,
              },
            },
            data: {
              archivedForProvider: {
                set: true,
              },
            },
          },
        });
      }
      notifications.show({
        title: "Order is correctly set as producing",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order as producing",
        color: "red",
        message: "",
      });
    }
  };

  const markAsProducingInTransit = async () => {
    if (order.status === OrderStatus.PRODUCING_IN_TRANSIT) {
      notifications.show({
        title: "Order is already in PRODUCING_IN_TRANSIT status",
        color: "red",
        message: "",
      });
      return;
    }
    if (order.status !== OrderStatus.PRODUCING) {
      notifications.show({
        title:
          "Cannot set order as PRODUCING_IN_TRANSIT cause status is not in PRODUCING state",
        color: "red",
        message: "",
      });
      return;
    }
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.PRODUCING_IN_TRANSIT,
            },
          },
        },
      });
      refetch();
      notifications.show({
        title: "Order is correctly set as producting in transit",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order as producting in transit",
        color: "red",
        message: "",
      });
    }
  };

  const markOrderAsShipped = async () => {
    if (order.status === OrderStatus.SHIPPED) {
      notifications.show({
        title: "Order is already in SHIPPED status",
        color: "red",
        message: "",
      });
      return;
    }
    if (order.status !== OrderStatus.PRODUCING_IN_TRANSIT) {
      notifications.show({
        title:
          "Cannot set order as SHIPPED cause status is not in PRODUCING_IN_TRANSIT state",
        color: "red",
        message: "",
      });
      return;
    }
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.SHIPPED,
            },
          },
        },
      });
      refetch();
      notifications.show({
        title: "Order is correctly set as SHIPPED",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order as SHIPPED",
        color: "red",
        message: "",
      });
    }
  };

  const setOrderOnHold = async () => {
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.ON_HOLD,
            },
          },
        },
      });
      refetch();
      notifications.show({
        title: "Order is correctly set as ON_HOLD",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order ON_HOLD",
        color: "red",
        message: "",
      });
    }
  };

  const markAsDelivered = async () => {
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: {
            status: {
              set: OrderStatus.DELIVERED,
            },
          },
        },
      });
      refetch();
      notifications.show({
        title: "Order is correctly set as delivered",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set order as delivered",
        color: "red",
        message: "",
      });
    }
  };

  const getTextForArchivedButton = order => {
    if (order.status !== OrderStatus.DELIVERED && order.isArchived === true) {
      return "Set order as delivered";
    }
    if (order.status === OrderStatus.DELIVERED && order.isArchived === false) {
      return "Archive order";
    }
    return "Archive & set as delivered";
  };

  const regenerateTraceabilitySheet = async () => {
    try {
      (order?.orderBilling ?? []).map(async (orderBilling: { id: string }) => {
        await generateTracebilitySheet({
          variables: {
            updateOrderBillingWhere: {
              id: orderBilling.id,
            },
            providerReference: "SSF1",
            orderBillingWhere: {
              id: orderBilling.id,
            },
          },
        });
      });
      const validFiles = getValideFiles(order?.files);
      notifications.show({
        title: "Loading",
        color: "grey",
        message: "",
        autoClose: 7000,
      });
      // We use setTimeout to let the server enouth time to create and upload the file on s3
      setTimeout(async () => {
        await refetch();
      }, 7000);
      setTimeout(() => {
        notifications.show({
          title: "Traceability sheet regenerated successfully",
          color: "green",
          message: "",
        });
      }, 10000);
    } catch (e) {
      console.error(e);
      notifications.show({
        title:
          "There was an error while trying to regenerate traceability sheet",
        color: "red",
        message: "",
      });
    }
  };

  return (
    <div style={{ paddingBottom: "1rem", float: "right" }}>
      <Notifications position="bottom-center" />
      <Modal
        opened={openModalOrderHistory}
        onClose={() => setOpenModalOrderHistory(false)}
      >
        <>
          {[...order?.history]
            .sort((a, b) => a?.createdAt - b?.createdAt)
            .map(history => (
              <div>
                {new Date(history?.createdAt).toLocaleString()} -{" "}
                {history?.status}{" "}
                {history?.user?.firstName &&
                  ` - Updated by ${history?.user?.firstName} ${history?.user?.lastName ?? ""}`}
              </div>
            ))}
        </>
      </Modal>
      <Modal
        opened={openMaterialAndLotModal}
        onClose={() => setOpenMaterialAndLotModal(false)}
      >
        <UpdateMaterialAndLotModal
          order={order}
          closeModal={() => setOpenMaterialAndLotModal(false)}
          refetch={refetch}
          user={user}
        />
      </Modal>
      <Modal
        opened={openDeliveryDateModal}
        onClose={() => setOpenDeliveryDateModal(false)}
      >
        <UpdateDeliveryDateModal
          order={order}
          closeModal={() => setOpenDeliveryDateModal(false)}
          refetch={refetch}
        />
      </Modal>
      <Modal
        opened={openRedoModal}
        onClose={() => setOpenRedoModal(false)}
      >
        <RedoModal
          order={order}
          closeModal={() => setOpenRedoModal(false)}
          refetch={refetch}
        />
      </Modal>
      <Modal
        opened={changeDentistModalOpened}
        onClose={() => setChangeDentistModalOpened(false)}
      >
        <ChangeDentistModal
          allDentists={(allDentists?.users ?? []).map(user => ({
            value: user?.id,
            label: `${user?.firstName} ${user?.lastName} - ${user?.isManager ? "MASTER ACCOUNT -" : ""} (${user?.email})`,
          }))}
          setSearchDentistInput={value => setSearchDentistInput(value)}
          searchDentistInput={searchDentistInput}
          onSubmit={async dentistId => {
            const orderBillingId =
              (order?.orderBilling ?? []).length > 0
                ? order?.orderBilling[0]?.id
                : undefined;
            try {
              await updateOrder({
                variables: {
                  where: {
                    id: order?.id,
                  },
                  data: {
                    user: {
                      connect: {
                        id: dentistId,
                      },
                    },
                    orderBilling: {
                      update: [
                        {
                          data: {
                            user: {
                              connect: {
                                id: dentistId,
                              },
                            },
                          },
                          where: {
                            id: orderBillingId,
                          },
                        },
                      ],
                    },
                  },
                },
              });
              notifications.show({
                title: "Dentist updated",
                color: "green",
                message: "",
              });
              setChangeDentistModalOpened(false);
              refetch();
            } catch (e) {
              notifications.show({
                title: "Error while trying to update linked to this order",
                color: "red",
                message: "",
              });
              console.error(e);
            }
          }}
        />
      </Modal>
      <Modal
        opened={openSendToSupplierModal}
        onClose={() => setOpenSendToSupplierModal(false)}
      >
        <SendOrderToSupplierModal
          suppliers={(allProviders?.getAllProviders ?? [])
            .filter(
              (provider: { id: string }) => provider?.id !== order?.provider?.id
            )
            .map(
              (provider: {
                id: string;
                name: string;
                displayPriority: number | null;
              }) => {
                return {
                  id: provider.id,
                  name: provider.name,
                  displayPriority: provider.displayPriority,
                };
              }
            )}
          onSubmit={(selectedSupplierId: string) => {
            if (!selectedSupplierId) {
              notifications.show({
                title: "You must select a provider",
                color: "red",
                message: "",
              });
              return;
            }
            startProduction(
              selectedSupplierId,
              order.status === OrderStatus.PRODUCING ||
                order.status === OrderStatus.WAITING_FOR_PRODUCTION,
              order?.provider?.id ?? undefined
            );
            setOpenSendToSupplierModal(false);
          }}
        />
      </Modal>
      <Flex
        wrap="wrap"
        gap="xs"
      >
        {isUserStaff(user) &&
          (order.status === OrderStatus.PRODUCING ||
            order.status === OrderStatus.WAITING_FOR_PRODUCTION) && (
            <Button
              onClick={() => setOpenSendToSupplierModal(true)}
              style={{ backgroundColor: STYLE.primary }}
            >
              <IconTransfer style={{ marginRight: "5px" }} /> Send order to
              another suppliers
            </Button>
          )}
        {isUserStaff(user) &&
          (order.status === OrderStatus.CONFIRMED ||
            order.status === OrderStatus.ON_HOLD ||
            order.status === OrderStatus.DELIVERED) && (
            <>
              <Tooltip label="You need to translated all comments before start production">
                <Button
                  onClick={() => setOpenSendToSupplierModal(true)}
                  disabled={getAllUntranslatedComment().length > 0}
                  style={() =>
                    getAllUntranslatedComment().length > 0
                      ? { backgroundColor: "lightgrey" }
                      : { backgroundColor: STYLE.primary }
                  }
                >
                  <IconArrowLeftFromArc style={{ marginRight: "5px" }} /> Send
                  order to suppliers
                </Button>
              </Tooltip>
            </>
          )}
        {isUserStaff(user) && (
          <Button
            onClick={() => setOpenModalOrderHistory(true)}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconLogs style={{ marginRight: "5px" }} /> Show order history
          </Button>
        )}
        {isUserStaff(user) && (
          <Button
            onClick={() => setChangeDentistModalOpened(true)}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconSortAscendingShapes style={{ marginRight: "5px" }} /> Change
            dentist
          </Button>
        )}
        {isUserStaff(user) && (
          <Menu>
            <Menu.Target>
              <Button style={{ backgroundColor: STYLE.primary }}>
                <IconStatusChange style={{ marginRight: "5px" }} /> Update order
                status
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              {Object.keys(OrderStatus).map(status => (
                <Menu.Item
                  key={status}
                  onClick={async () => {
                    try {
                      await updateOrder({
                        variables: {
                          where: {
                            id: order.id,
                          },
                          data: {
                            status: {
                              set: status,
                            },
                          },
                        },
                      });
                      refetch();
                      notifications.show({
                        title: "Order status correctly updated",
                        color: "green",
                        message: "",
                      });
                    } catch (e) {
                      console.error(e);
                      notifications.show({
                        title: "Error while trying to update order status",
                        color: "red",
                        message: "",
                      });
                    }
                  }}
                >
                  {status}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        )}
        {isUserProvider(user) && order.status === OrderStatus.ON_HOLD && (
          <Button
            onClick={() => startProduction()}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconProgress style={{ marginRight: "5px" }} /> order back to
            producing
          </Button>
        )}
        {isUserProvider(user) &&
          (order.status === OrderStatus.WAITING_FOR_PRODUCTION ||
            order.status === OrderStatus.PRODUCING) && (
            <Button
              onClick={() => setOrderOnHold()}
              style={{ backgroundColor: STYLE.primary }}
            >
              <IconHandStop style={{ marginRight: "5px" }} /> Set order on hold
            </Button>
          )}
        {isUserProvider(user) && (
          <Button
            onClick={() => setOpenMaterialAndLotModal(true)}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconListDetails style={{ marginRight: "5px" }} /> Add material list
            and lot number
          </Button>
        )}
        {isUserStaff(user) &&
          order.status === OrderStatus.PRODUCING_IN_TRANSIT && (
            <Button
              onClick={markOrderAsShipped}
              style={{ backgroundColor: STYLE.primary }}
            >
              <IconPlaneDeparture style={{ marginRight: "5px" }} /> Mark as
              shipped from France to France
            </Button>
          )}
        {isUserStaff(user) && (
          <Button
            onClick={() => navigate(`/orders/${order.id}/edit/`)}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconEdit style={{ marginRight: "5px" }} /> Edit order
          </Button>
        )}
        {isUserStaff(user) &&
          order.status !== OrderStatus.DELIVERED &&
          order.status !== OrderStatus.DRAFT && (
            <Button
              onClick={() => setOpenDeliveryDateModal(true)}
              style={{ backgroundColor: STYLE.primary }}
            >
              <IconCalendarCog style={{ marginRight: "5px" }} /> Update delivery
              date
            </Button>
          )}
        {isUserStaff(user) && order.status === OrderStatus.DELIVERED && (
          <Button
            onClick={() => setOpenRedoModal(true)}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconRepeat style={{ marginRight: "5px" }} /> Mark as redo
          </Button>
        )}
        {isUserProvider(user) &&
          order.status === OrderStatus.WAITING_FOR_PRODUCTION && (
            <Button
              onClick={() => startProduction()}
              style={{ backgroundColor: STYLE.primary }}
            >
              <IconProgress style={{ marginRight: "5px" }} /> Start production
            </Button>
          )}
        {isUserProvider(user) && order.status === OrderStatus.PRODUCING && (
          <Button
            onClick={markAsProducingInTransit}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconPlaneDeparture style={{ marginRight: "5px" }} /> Mark as
            shipped to France
          </Button>
        )}
        {isUserStaff(user) && order.status === OrderStatus.SHIPPED && (
          <Button
            onClick={markAsDelivered}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconMailbox style={{ marginRight: "5px" }} /> Mark as delivered
          </Button>
        )}
        {isUserStaff(user) &&
          (order.status !== OrderStatus.DELIVERED ||
            order.isArchived === false) && (
            <Button
              onClick={archiveAndSetAsDelivered}
              style={{ backgroundColor: STYLE.primary }}
            >
              <IconArchive style={{ marginRight: "5px" }} />{" "}
              {getTextForArchivedButton(order)}
            </Button>
          )}
        {isUserStaff(user) && order.isArchived === true && (
          <Button
            onClick={archiveAndSetAsNotDelievered}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconArchiveOff style={{ marginRight: "5px" }} /> Unarchive order
          </Button>
        )}
        {isUserStaff(user) && (
          <Button
            onClick={() => regenerateTraceabilitySheet()}
            style={{ backgroundColor: STYLE.primary }}
          >
            <IconArrowIteration style={{ marginRight: "5px" }} /> Regenerate
            traceability sheet
          </Button>
        )}
      </Flex>
    </div>
  );
};

export default HeaderOrderView;
