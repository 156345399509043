import React from "react";
import "./uploaded-file.css";
import { File as SharedFile } from "@jasper/shared/types/interfaces";
import { IconX, IconPaperclip } from "@tabler/icons-react";

export interface UploadedFileProps {
  setTempFiles?: (files: SharedFile[]) => void;
  tempFiles: SharedFile[];
  setFiles?: (files: File[]) => void;
  input?: any;
  isTempFile: boolean;
}

export const UploadedFile = ({ ...props }: UploadedFileProps) => {
  const removeImages = () => {
    if (props.setFiles) {
      props.setFiles([]);
    }
    if (props.setTempFiles) {
      props.setTempFiles([]);
    }
    if (props.input?.current) {
      props.input.current.value = "";
    }
  };

  if (props.tempFiles.length === 0) {
    return null;
  }

  return props.tempFiles
    .filter(file => {
      const fileNameWithExtention = file.key.split(".");
      if (fileNameWithExtention.length > 0) {
        return !["jpg", "jpeg", "gif", "png", "piff", "bmp"].includes(
          fileNameWithExtention[
            fileNameWithExtention.length - 1
          ].toLocaleLowerCase()
        );
      }
    })
    .map(file => (
      <div
        className={`uploaded-file-container ${!props.isTempFile ? "saved-file-hover" : ""}`}
      >
        {!props.isTempFile && (
          <a
            style={{
              cursor: "pointer",
              textTransform: "uppercase",
              display: "table",
              textDecoration: "none",
              color: "white",
              borderSpacing: "10px",
              verticalAlign: "center",
            }}
            href={file.path}
            target="_blank"
          >
            <span
              style={{
                display: "table-cell",
                verticalAlign: "middle",
                marginRight: "5px",
              }}
            >
              <IconPaperclip
                style={{
                  width: "20px",
                }}
              />
            </span>
            <span>{file.key.substring(37)}</span>
          </a>
        )}
        {props.isTempFile && (
          <>
            <div
              style={{
                display: "table-cell",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  textTransform: "uppercase",
                  display: "table",
                  textDecoration: "none",
                  color: "white",
                  borderSpacing: "10px",
                  verticalAlign: "center",
                }}
              >
                <span
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                >
                  <IconPaperclip
                    style={{
                      width: "20px",
                    }}
                  />
                </span>
                <span
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                >
                  {file.key}
                </span>
              </span>
            </div>
            <span
              style={{
                display: "table-cell",
                verticalAlign: "middle",
              }}
            >
              <IconX
                style={{ float: "right", cursor: "pointer", width: "20px" }}
                onClick={() => removeImages()}
              />
            </span>
          </>
        )}
      </div>
    ));
};
