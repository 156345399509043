import { createBrowserRouter } from "react-router-dom";
import Layout from "/@/layout";
import Login from "/@/pages/login";
import Home from "/@/pages/home";
import OrderView from "/@/pages/orders/orderView";
import OrderEdit from "/@/pages/orders/edit/orderEdit";

import GroupList from "/@/pages/groups/groupList";
import ChatList from "/@/pages/notifications/chatList";
import HelpList from "/@/pages/notifications/helpList";
import GroupView from "/@/pages/groups/groupView";
import GroupNew from "/@/pages/groups/groupNew";
import UserList from "/@/pages/users/userList";
import UserView from "/@/pages/users/userView";
import UserEdit from "/@/pages/users/userEdit";

import { NewItemComponent } from "@jasper/shared";

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/orders/:order_id",
        element: <OrderView />,
      },
      {
        path: "/orders/:order_id/edit",
        element: <OrderEdit />,
      },
      {
        path: "/orders/:order_id/edit/:product_id/new",
        element: <NewItemComponent />,
      },
      {
        path: "/orders/:order_id/edit/:product_id/edit",
        element: <NewItemComponent />,
      },
      {
        path: "/chats",
        element: <ChatList />,
      },
      {
        path: "/help-demands",
        element: <HelpList />,
      },
      {
        path: "/groups",
        element: <GroupList />,
      },
      {
        path: "/groups/:group_id",
        element: <GroupView />,
      },
      {
        path: "/groups/:group_id/edit",
        element: <GroupNew />,
      },
      {
        path: "/groups/new",
        element: <GroupNew />,
      },
      {
        path: "/users",
        element: <UserList />,
      },
      {
        path: "/users/new",
        element: <UserEdit />,
      },
      {
        path: "/users/:user_id",
        element: <UserView />,
      },
      {
        path: "/users/:user_id/edit",
        element: <UserEdit />,
      },
    ],
  },
]);
