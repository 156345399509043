import React from "react";
import { Grid, Button } from "@mantine/core";

const SelectorButton = (props: {
  data: { value: string; label: string }[];
  selected: string;
  onSubmit: (value: any) => void;
}) => {
  return (
    <Grid>
      {(props.data ?? []).map((item: { value: string; label: string }) => {
        return (
          <Grid.Col span={6}>
            <Button
              variant="light"
              color={item.value === props.selected ? "blue" : "gray"}
              style={() => ({
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                padding: "15px",
                border:
                  item.value === props.selected ? "2px solid #228be6" : "0",
              })}
              key={item.value}
              onClick={() => props.onSubmit(item.value)}
            >
              {item.label}
            </Button>
          </Grid.Col>
        );
      })}
    </Grid>
  );
};

export default SelectorButton;
