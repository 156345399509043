import { useState } from "react";
import { Title, Text, Space, Modal } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";

import {
  getPatientLabelFirstLine,
  translatedPatientAgeAndSex,
} from "../utils/patient.utils";
import { getDentistCode, isUserProvider } from "../utils/user.utils";
import { STYLE } from "../types/constants";
import BoxOrderView from "./BoxOrderView";
import { UserType } from "../types/enums";
import UpdatePatientModal from "./modals/updatePatientModal";

import {
  isImplant,
  isOrderWithNoAttachement,
  isPAC,
  UPDATE_PATIENT,
} from "@jasper/shared";
import { Order, Patient, User } from "../types/interfaces";

interface OrderViewPatientComponentProps {
  order: Order;
  user: User;
  refetch: () => void;
}

const OrderViewPatientComponent = ({
  order,
  user,
  refetch,
}: OrderViewPatientComponentProps) => {
  const [isModalUpdatePatientOpened, setIsModalUpdatePatientOpened] =
    useState<boolean>(false);
  const [updatePatient] = useMutation(UPDATE_PATIENT);

  return (
    <BoxOrderView
      span={5}
      minHeight={28}
    >
      <Modal
        opened={isModalUpdatePatientOpened}
        onClose={() => setIsModalUpdatePatientOpened(false)}
      >
        <UpdatePatientModal
          patient={order?.patient}
          onSubmit={async (values: Patient) => {
            try {
              await updatePatient({
                variables: {
                  where: {
                    id: order?.patient?.id,
                  },
                  data: {
                    firstName: {
                      set: values.firstName,
                    },
                    lastName: {
                      set: values.lastName,
                    },
                  },
                },
              });
              notifications.show({
                title: "Patient updated",
                color: "green",
                message: "",
              });
              refetch();
              setIsModalUpdatePatientOpened(false);
            } catch (e) {
              console.error(e);
              notifications.show({
                title: "Error while trying to update patient",
                color: "red",
                message: "",
              });
            }
          }}
        />
      </Modal>
      <Title
        order={5}
        style={{
          color: STYLE.primary,
        }}
      >
        Order {order.orderReference}
      </Title>
      <Space h="md" />
      <Title order={5}>Patient</Title>
      <Text>
        {getPatientLabelFirstLine(order)}
        {!isUserProvider(user) && (
          <span
            style={{
              marginLeft: "2px",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => setIsModalUpdatePatientOpened(true)}
          >
            (modifier)
          </span>
        )}
      </Text>
      <Text>{translatedPatientAgeAndSex(order?.patient)}</Text>
      <Space h="md" />
      <Title order={5}>Doctor</Title>
      <Text>
        {user.type !== UserType.SUPPLIER ? (
          `${order?.user?.firstName ?? ""} ${order?.user?.lastName ?? ""} - ${order?.user?.userGroup?.clinicNumber}`
        ) : (
          <>
            Doctor code : {getDentistCode(order?.user)} <br />
            Clinic code : {order.user.userGroup.clinicNumber}
          </>
        )}
      </Text>
      <Space h="md" />
      {!isUserProvider(user) && (
        <div style={{ color: "#ff5d00", fontWeight: "bold" }}>
          {(order?.isRush ?? false) && "RUSH "}
          {(order?.isPhysicalPrint ?? false) && "PHYSICAL-PRINT "}
          {(order?.orderComment ?? []).length > 0 && " COMMENT "}
          {isImplant(order) && "IMPLANT "}
          {isOrderWithNoAttachement(order) && "NO-ATTACHEMENT "}
          {isPAC(order) && "PAC "}
          {(order?.redo ?? []).length > 0 && "REDO"}
        </div>
      )}
    </BoxOrderView>
  );
};

export default OrderViewPatientComponent;
