import { AppShell } from "@mantine/core";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { UseAuth } from "/@/contexts";
import Navbar from "/@/composants/navbar";
import { STYLE } from "@jasper/shared";

const Layout = () => {
  const { user } = UseAuth();
  const location = useLocation();

  const isLoginPage = () => {
    return location.pathname === "/";
  };

  if (
    !user?.id &&
    !isLoginPage() // prevent infinit loop
  ) {
    return (
      <Navigate
        to="/"
        replace={true}
      />
    );
  }

  return (
    <>
      {(import.meta.env.MODE === "development" ||
        import.meta.env.MODE === "staging") && (
        <div
          style={{
            color: "white",
            textAlign: "center",
            backgroundColor: STYLE.primary,
            paddingBottom: "1rem",
            paddingTop: "1rem",
          }}
        >
          {import.meta.env.MODE}
        </div>
      )}
      <AppShell
        navbar={{
          width: isLoginPage() ? 0 : "18rem",
          breakpoint: "sm",
        }}
        padding="md"
      >
        {!isLoginPage() && (
          <AppShell.Navbar
            p="md"
            style={{ backgroundColor: "#F2F3F9" }}
          >
            <Navbar />
          </AppShell.Navbar>
        )}
        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </>
  );
};

export default Layout;
