import { gql } from "@apollo/client";

export const CREATE_ANATOMY_ITEM = gql`
  mutation CreateAnatomyItem($args: AnatomyItemCreateInput!) {
    createAnatomyItem(args: $args) {
      id
    }
  }
`;

export const DELETE_MANY_ANATOMY_ITEMS = gql`
  mutation DeleteManyAnatomyItem($where: AnatomyItemWhereInput) {
    deleteManyAnatomyItem(where: $where)
  }
`;

export const UPDATE_ANATOMY_ITEM = gql`
  mutation UpdateAnatomyItem(
    $data: AnatomyItemUpdateInput!
    $where: AnatomyItemWhereUniqueInput!
  ) {
    updateAnatomyItem(data: $data, where: $where) {
      id
    }
  }
`;

export const CREATE_REMOVABLE_ITEM = gql`
  mutation CreateRemovableItem($args: RemovableItemCreateInput!) {
    createRemovableItem(args: $args) {
      id
    }
  }
`;

export const DELETE_MANY_REMOVABLE_ITEMS = gql`
  mutation DeleteManyAnatomyItem($where: RemovableItemWhereInput) {
    deleteManyRemovableItems(where: $where)
  }
`;

export const UPDATE_REMOVABLE_ITEM = gql`
  mutation UpdateRemovableItem(
    $data: RemovableItemUpdateInput!
    $where: RemovableItemWhereUniqueInput!
  ) {
    updateRemovableItem(data: $data, where: $where) {
      id
    }
  }
`;

export const GET_ALL_IMPLANT_REFERENCES = gql`
  query GetAllImplantReferences {
    getAllImplantReferences {
      id
      label
    }
  }
`;

export const CREATE_IMPLANT_ITEM = gql`
  mutation CreateImplantItem($args: ImplantItemCreateInput!) {
    createImplantItem(args: $args) {
      id
    }
  }
`;

export const DELETE_MANY_IMPLANT_ITEM = gql`
  mutation DeleteManyImplantItem($where: ImplantItemWhereInput) {
    deleteManyImplantItem(where: $where)
  }
`;

export const UPDATE_IMPLANT_ITEM = gql`
  mutation UpdateImplantItem(
    $data: ImplantItemUpdateInput!
    $where: ImplantItemWhereUniqueInput!
  ) {
    updateImplantItem(data: $data, where: $where) {
      id
    }
  }
`;
