import { UseAuth, UserContext } from "/@/contexts";
import { RouterProvider } from "react-router-dom";
import { router } from "/@/router";
import { socket } from "./socket";
import { useEffect } from "react";

const App = () => {
  const { user } = UseAuth();

  useEffect(() => {
    socket.on("connect", () => {});
    socket.emit("joinRoom", { room: "notification" });
    socket.on("disconnect", () => {});
    return () => {
      socket.emit("leaveRoom", { room: "notification" });
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  return (
    <UserContext.Provider value={{ user }}>
      <RouterProvider router={router} />
    </UserContext.Provider>
  );
};

export default App;
