import React, { useState } from "react";
import { Title, Text, Space, Switch, Group, Button } from "@mantine/core";
import { useForm } from "react-hook-form";

import { STYLE } from "../../../types/constants";
import TextInputRef from "../../TextInputRef";
import {
  getImplantReferenceBrands,
  getImplantReferenceChoices,
  getImplantItemPlatformChoices,
} from "../../../utils/item.utils";
import SelectInputRef from "../../SelectInputRef";

const CreateNewImplantImplantInfo = (props: {
  implantItemChoicesData;
  onSubmit;
  savedItem;
}) => {
  const [isImplantReferenceNotFound, setIsImplantReferenceNotFound] = useState(
    props?.savedItem?.implantReferenceSuggestion ?? false
  );
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      implantReferenceBrand:
        props.savedItem?.implantReference?.brand ?? undefined,
      implantReferenceId: props.savedItem?.implantReference?.id ?? undefined,
      platform: props.savedItem?.implantPlatform ?? undefined,
      implantScanBodySuggestion: props.savedItem?.scanBodySuggestion ?? "",
      implantBrandSuggestion:
        props.savedItem?.implantReferenceSuggestion?.split("-").length > 0
          ? props.savedItem?.implantReferenceSuggestion.split("-")[0]
          : "",
      implantReferenceSuggestion:
        props.savedItem?.implantReferenceSuggestion?.split("-").length > 1
          ? props.savedItem?.implantReferenceSuggestion.split("-")[1]
          : "",
      implantPlatformSuggestion:
        props.savedItem?.implantReferenceSuggestion?.split("-").length > 2
          ? props.savedItem?.implantReferenceSuggestion.split("-")[2]
          : "",
    },
  });

  const watchImplantReferenceBrand = watch("implantReferenceBrand");
  const watchImplantReferenceId = watch("implantReferenceId");

  return (
    <>
      <>
        <Title order={2}>Système d'implant et Scan Body</Title>
        <Text size="md">
          Les informations précises nous permettent de vous assurer de meilleurs
          délais de livraison
        </Text>
        <Space h="md" />
      </>
      <>
        <Title
          order={4}
          style={{ color: STYLE.primary }}
        >
          Implant
        </Title>
        <Space h="sm" />
        {isImplantReferenceNotFound ? (
          <>
            <TextInputRef
              name="implantBrandSuggestion"
              placeholder="Fabriquant (ex: Zimmer, Straumann, Biotech Dental, etc.)"
              label="Fabricant"
              errors={errors}
              control={control}
              required={true}
            />
            <Space h="sm" />
            <TextInputRef
              name="implantReferenceSuggestion"
              placeholder="Système (ex: NobelActive pour NobelBiocare, Kontact Standard pour Biotech Dental, etc.)"
              label="Système"
              errors={errors}
              control={control}
              required={true}
            />
            <Space h="sm" />
            <TextInputRef
              name="implantPlatformSuggestion"
              placeholder="ex: Narrow Platform, Regular Platform pour Nobel Biocare; 3.0, 3.6, 4.9 pour Biotech Dental, etc."
              label="Plateforme implantaire / diamètre"
              errors={errors}
              control={control}
              required={true}
            />
          </>
        ) : (
          <>
            <SelectInputRef
              name="implantReferenceBrand"
              label="Marque d'implant"
              errors={errors}
              control={control}
              data={getImplantReferenceBrands(props.implantItemChoicesData).map(
                (referenceBrands: string) => ({
                  label: referenceBrands,
                  value: referenceBrands,
                })
              )}
              required={true}
            />
            <SelectInputRef
              name="implantReferenceId"
              label="Référence"
              errors={errors}
              control={control}
              data={getImplantReferenceChoices(
                props.implantItemChoicesData
              ).filter(
                implantReference =>
                  implantReference.brand === watchImplantReferenceBrand
              )}
              required={true}
            />
            <SelectInputRef
              name="platform"
              label="Plateforme implantaire"
              errors={errors}
              control={control}
              data={getImplantItemPlatformChoices(
                (
                  props.implantItemChoicesData?.getAllImplantReferences ?? []
                ).find(
                  implantReference =>
                    implantReference.id === watchImplantReferenceId
                )?.platforms ?? []
              )}
              required={true}
            />
          </>
        )}
        <Space h="sm" />
        <Switch
          checked={isImplantReferenceNotFound}
          onChange={event =>
            setIsImplantReferenceNotFound(event.currentTarget.checked)
          }
          label="Je ne trouve pas mon système d'implant"
        />
        <Space h="sm" />
      </>
      <>
        <Title
          order={4}
          style={{ color: STYLE.primary }}
        >
          Scan Body
        </Title>
        <Space h="sm" />
        <TextInputRef
          name="implantScanBodySuggestion"
          placeholder="Par exemple: scanbody Biotech Dental Kontact (KPSCANP), scanbody DESS compatible Biotech Dental Kontact"
          label="Référence de votre Scan Body"
          errors={errors}
          control={control}
        />
        <Text
          style={{ fontStyle: "italic" }}
          size="xs"
        >
          Le scan body nous permet de trouver les librairies de conception 3D de
          votre fabricant d\'implant. Si vous ne connaissez pas la référence
          exacte, indiquez les informations que vous avez ou bien "NA".
        </Text>
        <Space h="sm" />
      </>
      <Group
        justify="space-between"
        mt="xl"
      >
        <div></div>
        <Button
          onClick={() =>
            handleSubmit(values =>
              props.onSubmit(values, isImplantReferenceNotFound)
            )()
          }
        >
          Next step
        </Button>
      </Group>
    </>
  );
};

export default CreateNewImplantImplantInfo;
