import { useForm } from "react-hook-form";
import { Space, Button } from "@mantine/core";

import { STYLE } from "../../types/constants";
import TextInputRef from "../TextInputRef";

const UpdatePatientModal = ({ onSubmit, patient }) => {
  const { errors, control, handleSubmit } = useForm({
    defaultValues: {
      firstName: patient?.firstName,
      lastName: patient?.lastName,
    },
  });
  return (
    <>
      <TextInputRef
        name="firstName"
        label="Firstname"
        errors={errors}
        control={control}
        required={true}
      />
      <Space h="md" />
      <TextInputRef
        name="lastName"
        label="Lastname"
        errors={errors}
        control={control}
        required={true}
      />
      <Space h="md" />
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default UpdatePatientModal;
