export enum ItemType {
  IMPLANT = "IMPLANT",
  CROWN = "CROWN",
  SPLINT = "SPLINT",
  BLEACHING_TRAY = "BLEACHING_TRAY",
  LAY = "LAY",
  INLAY = "INLAY",
  ONLAY = "ONLAY",
  OVERLAY = "OVERLAY",
  BRIDGE = "BRIDGE",
  INLAY_CORE = "INLAY_CORE",
  POST_AND_CORE_RETAINED_CROWN = "POST_AND_CORE_RETAINED_CROWN",
  VENEER = "VENEER",
  IMPLANT_CROWN = "IMPLANT_CROWN",
  ABUTMENT = "ABUTMENT",
  CUSTOM_ABUTMENT = "CUSTOM_ABUTMENT",
  NIGHT_GUARD = "NIGHT_GUARD",
  RETAINER = "RETAINER",
  PARTIAL_DENTURE = "PARTIAL_DENTURE",
  FULL_DENTURE = "FULL_DENTURE",
  FULL_DENTURE_WAX_BITE = "FULL_DENTURE_WAX_BITE",
  IMMEDIATE_DENTURE = "IMMEDIATE_DENTURE",
  MODEL = "MODEL",
  RICHMOND_CROWN = "RICHMOND_CROWN",
  SPLINTED_CROWN = "SPLINTED_CROWN",
  SPLINTED_CROWN_IMPLANT = "SPLINTED_CROWN_IMPLANT",
  BRIDGE_IMPLANT = "BRIDGE_IMPLANT",
  BRIDGE_PONTIC = "BRIDGE_PONTIC",
  SCAN_ONLY = "SCAN_ONLY",
  SPACE_MAINTAINER = "SPACE_MAINTAINER",
  RETENTION_WIRE = "RETENTION_WIRE",
  WAX_UP = "WAX_UP",
  ADDITIONAL_IMPRESSION_FOR_REMOVABLE_PROSTHESIS = "ADDITIONAL_IMPRESSION_FOR_REMOVABLE_PROSTHESIS",
  ADDING_TEETH_ON_EXISTING_DENTURE = "ADDING_TEETH_ON_EXISTING_DENTURE",
  DENTURE_REPAIR = "DENTURE_REPAIR",
}

export enum OrderStatus {
  DRAFT = "DRAFT",
  CONFIRMED = "CONFIRMED",
  WAITING_FOR_PRODUCTION = "WAITING_FOR_PRODUCTION",
  PRODUCING = "PRODUCING",
  PRODUCING_IN_TRANSIT = "PRODUCING_IN_TRANSIT",
  SHIPPED = "SHIPPED",
  DELIVERED = "DELIVERED",
  ON_HOLD = "ON_HOLD",
}

export enum ModelTypenames {
  Product = "Product",
  AnatomyItem = "AnatomyItem",
  ImplantItem = "ImplantItem",
  RemovableItem = "RemovableItem",
}

export enum Sex {
  NOT_INDICATED = "NOT_INDICATED",
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum ArchType {
  MAXILLAIRE = "maxillaire",
  MANDIBULAIRE = "mandibulaire",
  BIMAXILAIRE = "bimaxillaire",
}

export enum ArchTypeTranslated {
  MAXILLAIRE = "Upper Arch",
  MANDIBULAIRE = "Lower Arch",
  BIMAXILAIRE = "Upper & Lower Arch",
}

export enum ArchTypeEn {
  LOWER_ARCH = "LOWER_ARCH",
  UPPER_ARCH = "UPPER_ARCH",
  BOTH_ARCH = "BOTH_ARCH",
}

export enum UserType {
  DENTIST = "DENTIST",
  SUPPLIER = "SUPPLIER",
  JASPER = "JASPER",
}

export enum UserRole {
  USER = "USER",
  STAFF = "STAFF",
  ADMIN = "ADMIN",
}

export enum RetentionType {
  SCREW_RETAINED = "SCREW_RETAINED",
  CEMENT_RETAINED = "CEMENT_RETAINED",
}

export enum AbutmentType {
  STANDARD = "STANDARD",
  STANDARD_GENERIC = "STANDARD_GENERIC",
  CUSTOM = "CUSTOM",
}

export enum FileType {
  ORDER_FILE = "ORDER_FILE",
  BILLING_DENTIST_TRACEABILITY = "BILLING_DENTIST_TRACEABILITY",
  BILLING_PATIENT_TRACEABILITY = "BILLING_PATIENT_TRACEABILITY",
  CHAT_FILE = "Chat_File",
  CLIENT_FILE = "ClientFile",
  LABS_FILE = "LabsFile",
  HELP_FILE = "Help_File",
  ORDER_ADDON_FILE = "Order_Addon_File",
}

export enum WorkflowType {
  ONE_APPOINTMENT_DIRECT = "ONE_APPOINTMENT_DIRECT",
  WAX_RIM = "WAX_RIM",
  WITH_FITTING = "WITH_FITTING",
}

export enum TeethShadeType {
  MULTI_SHADE = "MULTI_SHADE",
  SINGLE_SHADE = "SINGLE_SHADE",
}

export enum TeethShadeSide {
  GINGIVAL = "GINGIVAL",
  BASE = "BASE",
  INCISAL = "INCISAL",
}

export enum FixedProsthesisShadeType {
  VITA_3D_MASTER = "VITA_3D_MASTER",
  VITA_CLASSIC = "VITA_CLASSIC",
}

export enum PartialAndFullDentureProsthesisShadeType {
  VITA_CLASSIC = "VITA_CLASSIC",
  VITA_3D_MASTER = "VITA_3D_MASTER",
  SR_VIVODENT_SPE = "SR_VIVODENT_SPE",
}

export enum ProductPreferenceType {
  FIXED_PROSTHESIS_PREFERENCES = "FIXED_PROSTHESIS_PREFERENCES",
  REMOVABLE_PROSTHESIS_PREFERENCES = "REMOVABLE_PROSTHESIS_PREFERENCES",
}

export enum ItemShadeType {
  VITA_3D_CLASSIC = "VITA_3D_CLASSIC",
  VITA_3D_MASTER = "VITA_3D_MASTER",
  GINGIVA = "GINGIVA",
  VITA_CLASSIC = "VITA_CLASSIC",
  SR_VIVODENT_SPE = "SR_VIVODENT_SPE",
}

export enum CommentType {
  ORDER = "ORDER",
  HELP = "HELP",
  REDO = "REDO",
  PARTIAL_DENTURE = "PARTIAL_DENTURE",
}

export enum ItemMaterials {
  METAL_NON_PRECIOUS = "METAL_NON_PRECIOUS",
  PFM_NON_PRECIOUS = "PFM_NON_PRECIOUS",
  PFM_NON_PRECIOUS_POST_AND_CORE = "PFM_NON_PRECIOUS_POST_AND_CORE",
  PFM_PRECIOUS = "PFM_NON_PRECIOUS",
  PORCELAIN_FUSED_ZIRCONIA = "PORCELAIN_FUSED_ZIRCONIA",
  ZIRCONIA_FULL = "ZIRCONIA_FULL",
  ZIRCONIA_MULTILAYER = "ZIRCONIA_MULTILAYER",
  EMAX_FULL = "EMAX_FULL",
  EMAX_MULTILAYER = "EMAX_MULTILAYER",
  COMPOSITE_NO_METAL = "COMPOSITE_NO_METAL",
  TITANIUM = "TITANIUM",
  TI_BASE = "TI_BASE",
  COBALT_CHROME_METALLIC_FRAME = "COBALT_CHROME_METALLIC_FRAME",
  TITANIUM_FRAME = "TITANIUM_FRAME",
  VALPLAST = "VALPLAST",
  ACRYLIC = "ACRYLIC",
  ZIRCONIA = "ZIRCONIA",
  NIGHT_GUARD_HARD_MATERIAL = "NIGHT_GUARD_HARD_MATERIAL",
  NIGHT_GUARD_SOFT_MATERIAL = "NIGHT_GUARD_SOFT_MATERIAL",
  NIGHT_GUARD_MEDIUM_MATERIAL = "NIGHT_GUARD_MEDIUM_MATERIAL",
}

export enum GroupType {
  DENTIST = "DENTIST",
  SUPPLIER = "SUPPLIER",
}

export enum PricingType {
  STANDARD = "STANDARD",
  DISCOVERY = "DISCOVERY",
}

export enum OcclusalContactTightness {
  HEAVY = "HEAVY",
  MEDIUM = "MEDIUM",
  LIGHT = "LIGHT",
  NONE = "NONE",
}

export enum ProximalContactTightness {
  HEAVY = "HEAVY",
  MEDIUM = "MEDIUM",
  LIGHT = "LIGHT",
  NONE = "NONE",
}

export enum OcclusalStaining {
  DARK = "DARK",
  MEDIUM = "MEDIUM",
  LIGHT = "LIGHT",
  NONE = "NONE",
}

export enum NoOcclusalClearance {
  KEEP_MANUFACTURING = "KEEP_MANUFACTURING",
  ASK_DENTIST = "ASK_DENTIST",
}

export enum InterproximalContactDesign {
  NORMAL_CONTACT_POINT = "NORMAL_CONTACT_POINT",
  WIDE_CONTACT_SURFACE = "WIDE_CONTACT_SURFACE",
}

export enum AnteriorPosteriorPonticDesign {
  MODIFIED_RIDGE_LAP = "MODIFIED_RIDGE_LAP",
  FULL_RIDGE_LAP = "FULL_RIDGE_LAP",
}

export enum ProductItemType {
  ANATOMY_ITEM = "ANATOMY_ITEM",
  REMOVABLE_ITEM = "REMOVABLE_ITEM",
  IMPLANT_ITEM = "IMPLANT_ITEM",
}

export enum WaxUpType {
  NUMERICAL = "NUMERICAL",
  PHYSICAL = "PHYSICAL",
  NUMERICAL_AND_PHYSICAL = "NUMERICAL_AND_PHYSICAL",
}

export enum WaxUpTypeFr {
  NUMERICAL = "Numérique",
  PHYSICAL = "Physique",
  NUMERICAL_AND_PHYSICAL = "Numérique et physique",
}

export enum WaxUpTypeEn {
  NUMERICAL = "Digital",
  PHYSICAL = "Physical",
  NUMERICAL_AND_PHYSICAL = "Digital and physical",
}

export enum OrderCommentType {
  ORDER = "ORDER",
  HELP = "HELP",
  REDO = "REDO",
  PARTIAL_DENTURE = "PARTIAL_DENTURE",
}
