import React, { useState } from "react";
import { Title, Space, Group, Button, Text, Box } from "@mantine/core";
import { useForm } from "react-hook-form";

import SelectorButtonRef from "../../SelectorButtonRef";
import SelectInputRef from "../../SelectInputRef";
import ShadeSelection from "../../shadeSelection";
import {
  RetentionTypeChoices,
  YES_OR_NO_CHOICE,
} from "../../../types/constants";
import {
  FixedProsthesisShadeType,
  ItemType,
  TeethShadeSide,
  TeethShadeType,
} from "../../../types/enums";
import {
  findUserPreference,
  getAllPossibleSplintedCrown,
} from "../../../utils/product.utils";
import DentalArchesSvg from "../../DentalArchesSvg";
import { ImplantItem } from "@prisma/client";
import { useLocation } from "react-router-dom";

const CreateNewImplantCrownInfo = ({
  materialFormChoices,
  anatomyItemChoicesData,
  order,
  accountProductPreferences,
  previousStep,
  onSubmit,
  savedItem,
  savedPonticItem,
  itemType,
  product,
}) => {
  const [selectedPonticTeeth, setSelectedPonticTeeth] = useState<number[]>(
    savedPonticItem?.teeth ?? []
  );
  const location = useLocation();
  const rhf = useForm({
    defaultValues: {
      retentionType: savedItem?.retentionType ?? undefined,
      material: savedItem?.itemMaterial?.id ?? undefined,
      shade: savedItem?.itemShade?.id ?? undefined,
      isSplintedCrown:
        (location?.state?.product?.implantItem ?? []).filter(
          (item: ImplantItem) =>
            item.itemType === ItemType.SPLINTED_CROWN_IMPLANT
        ).length > 0,
      isMultiShade: savedItem?.teethshadeType === TeethShadeType.MULTI_SHADE,
      gingivalShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.GINGIVAL
        )?.itemShade?.id ?? undefined,
      baseShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.BASE
        )?.itemShade?.id ?? undefined,
      incisalShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.INCISAL
        )?.itemShade?.id ?? undefined,
      shadeType:
        findUserPreference(
          order?.user?.id,
          accountProductPreferences?.getAccountProductPreferences
        )?.shadeType ?? FixedProsthesisShadeType.VITA_CLASSIC,
    },
  });

  const watchRetentionType = rhf.watch("retentionType");
  const watchShadeType = rhf.watch("shadeType");
  const watchIsSplintedCrown = rhf.watch("isSplintedCrown");

  return (
    <>
      <Title order={2}>Matériau, teinte et rétention</Title>
      <Space h="md" />
      <SelectorButtonRef
        label="Système de rétention"
        name="retentionType"
        data={RetentionTypeChoices}
        onSubmit={selectedValue => rhf.setValue("retentionType", selectedValue)}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
        watch={watchRetentionType}
      />
      <SelectInputRef
        label="Matériau de la couronne"
        name="material"
        data={materialFormChoices}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
      />
      {product?.productType === ItemType.IMPLANT &&
        getAllPossibleSplintedCrown(product?.teeth ?? []).length > 0 && (
          <SelectorButtonRef
            label="Faut-il solidariser les couronnes ?"
            name="isSplintedCrown"
            data={YES_OR_NO_CHOICE}
            onSubmit={selectedValue =>
              rhf.setValue("isSplintedCrown", selectedValue)
            }
            control={rhf.control}
            errors={rhf.formState.errors}
            watch={watchIsSplintedCrown}
            required={true}
          />
        )}
      <ShadeSelection
        watchShadeType={watchShadeType}
        control={rhf.control}
        errors={rhf.formState.errors}
        anatomyItemChoicesData={anatomyItemChoicesData}
        onSelect={selectedValue => rhf.setValue("shadeType", selectedValue)}
        order={order}
        accountProductPreferences={accountProductPreferences}
        rhf={rhf}
        itemType={ItemType.IMPLANT}
        user={order?.user}
      />
      {itemType === ItemType.BRIDGE_IMPLANT && (
        <>
          <Text
            fw={490}
            size="sm"
          >
            Sélectionnez les dents du pontic
          </Text>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DentalArchesSvg
              teeth={selectedPonticTeeth}
              width={275}
              height={366}
              onClick={tooth => {
                const index = selectedPonticTeeth.indexOf(tooth);
                if (index >= 0) {
                  setSelectedPonticTeeth(
                    selectedPonticTeeth.filter((t: number) => t !== tooth)
                  );
                  return;
                }
                setSelectedPonticTeeth([...selectedPonticTeeth, tooth]);
              }}
            />
          </Box>
        </>
      )}
      <Group
        justify="space-between"
        mt="xl"
      >
        <Button
          variant="default"
          onClick={previousStep}
        >
          Previous step
        </Button>
        <Button
          onClick={rhf.handleSubmit(values =>
            onSubmit(values, selectedPonticTeeth)
          )}
        >
          Next step
        </Button>
      </Group>
    </>
  );
};

export default CreateNewImplantCrownInfo;
