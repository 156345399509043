import React from "react";
import { Text, TextInput, PasswordInput } from "@mantine/core";
import { Controller, Control, FieldErrors } from "react-hook-form";
import { MantineProvider } from "@mantine/core";

interface TextInputRefProps {
  name: string;
  label: string;
  errors: FieldErrors<any>;
  control: Control<any>;
  required?: boolean;
  secure?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const TextInputRef: React.FC<TextInputRefProps> = ({
  name,
  label,
  errors,
  control,
  required = true,
  secure = false,
  disabled = false,
  placeholder = "",
}) => {
  return (
    <MantineProvider>
      {secure ? (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <PasswordInput
              label={label}
              disabled={disabled}
              placeholder={placeholder}
              {...field}
            />
          )}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <TextInput
              label={label}
              disabled={disabled}
              placeholder={placeholder}
              {...field}
            />
          )}
        />
      )}
      {errors?.[name]?.type === "required" && (
        <Text
          size="sm"
          style={{ color: "red" }}
        >
          This field is required
        </Text>
      )}
    </MantineProvider>
  );
};

export default TextInputRef;
