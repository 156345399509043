import {
  ItemType,
  RetentionType,
  AbutmentType,
  TeethShadeSide,
  FixedProsthesisShadeType,
  PartialAndFullDentureProsthesisShadeType,
  ItemShadeType,
  ProductPreferenceType,
  Product,
} from "../types/enums";
import {
  RemovableItem,
  ImplantItem,
  AnatomyItem,
  Item,
} from "../types/interfaces";
import {
  isRemovableItemType,
  getItemTypeLabel,
  findUserPreference,
} from "./product.utils";
import {
  translateItemType,
  translateInlayCoreMaterial,
  translateItemMaterial,
} from "./translation.utils";

const clavetedInlayCoreMaterials = ["METAL_NON_PRECIOUS", "TITANIUM"];

export const getItemsOfBridgeImplant = (
  implantItems: ImplantItem[] = []
): {
  abutment: ImplantItem | null;
  bridgeImplant: ImplantItem | null;
  bridgePontic: ImplantItem | null;
} => {
  const bridgePonticItem = (implantItems ?? []).find(
    (item: ImplantItem) => item.itemType === ItemType.BRIDGE_PONTIC
  );
  const bridgeImplantItem = (implantItems ?? []).find(
    (item: ImplantItem) => item.itemType === ItemType.BRIDGE_IMPLANT
  );
  const abutment = (implantItems ?? []).find(
    (item: ImplantItem) => item.itemType === ItemType.IMPLANT
  );
  return {
    abutment: bridgeImplantItem ? abutment : null,
    bridgeImplant: bridgeImplantItem ?? null,
    bridgePontic: bridgeImplantItem ? bridgePonticItem : null,
  };
};

export const excludePonticFromBridgeImplantTeeth = (
  bridgeImplantTeeth: number[],
  bridePonticTeeth: number[]
) => {
  if (!bridePonticTeeth) {
    return bridgeImplantTeeth;
  }
  return (bridgeImplantTeeth ?? []).filter(
    (teeth: number) => !bridePonticTeeth.includes(teeth)
  );
};

export const getItemCrownOrSplintedCrownFromProduct = (
  anatomyItems: AnatomyItem[]
): AnatomyItem => {
  const crown = (anatomyItems ?? []).find(
    (item: AnatomyItem) => item.itemType === ItemType.CROWN
  );
  if (crown) {
    return crown;
  }
  return (anatomyItems ?? []).find(
    (item: AnatomyItem) => item.itemType === ItemType.SPLINTED_CROWN
  );
};

export const getFixedProsthesisPreferences = (
  productType: ItemType,
  productPreferences
) => {
  const fixedProsthesisPreferences =
    productPreferences?.fixedProsthesisPreferences;
  let preferenceItemType = productType;
  if (
    productType === ItemType.LAY ||
    productType === ItemType.INLAY ||
    productType === ItemType.OVERLAY ||
    productType === ItemType.ONLAY
  ) {
    preferenceItemType = ItemType.LAY;
  }
  if (productType === ItemType.POST_AND_CORE_RETAINED_CROWN) {
    preferenceItemType = ItemType.INLAY_CORE;
  }
  if (fixedProsthesisPreferences) {
    return (
      fixedProsthesisPreferences.find(
        itemPreference => itemPreference.itemType === preferenceItemType
      ) ?? null
    );
  } else {
    return null;
  }
};

export const findFullDentureSavedItemInProducts = (products: Product[]) => {
  if ((products ?? []).length === 0) return undefined;
  for (const i in products) {
    if ((products[i]?.removableItem ?? []).length === 0) continue;
    const fullDentureItem = products[i].removableItem.find(
      (item: { itemType: ItemType }) => isFullDentureType(item.itemType)
    );
    if (fullDentureItem) {
      return fullDentureItem;
    }
  }
  return undefined;
};

export const getShadeTypeAvailableByItemType = (
  isRemovableItem: boolean,
  itemType: ItemType
): string[] => {
  if (
    itemType &&
    (itemType === ItemType.PARTIAL_DENTURE ||
      itemType === ItemType.FULL_DENTURE ||
      itemType === ItemType.FULL_DENTURE_WAX_BITE ||
      itemType === ItemType.IMMEDIATE_DENTURE)
  ) {
    return Object.keys(PartialAndFullDentureProsthesisShadeType);
  }
  if (isRemovableItem) {
    return Object.keys(PartialAndFullDentureProsthesisShadeType);
  }
  return Object.keys(FixedProsthesisShadeType);
};

export const getGingivaShadeFromAllShades = (
  allShades: { shadeType: ItemShadeType; id: string; label: string }[]
): { value: string; label: string }[] => {
  return allShades
    .filter(
      (shade: { shadeType: ItemShadeType }) =>
        shade.shadeType === ItemShadeType.GINGIVA
    )
    .map((shade: { id: string; label: string }) => ({
      value: shade.id,
      label: shade.label,
    }));
};

export const isFullDentureType = (type: ItemType): boolean =>
  [
    ItemType.FULL_DENTURE,
    ItemType.FULL_DENTURE_WAX_BITE,
    ItemType.IMMEDIATE_DENTURE,
  ].includes(type);

export const deduceFinalItemTypeOfFullDenture = (
  productSpecificType: ItemType,
  newFullDentureStep: number,
  isReplacement: boolean
) => {
  if (productSpecificType === ItemType.IMMEDIATE_DENTURE) {
    return ItemType.IMMEDIATE_DENTURE;
  } else if (
    productSpecificType === ItemType.FULL_DENTURE &&
    newFullDentureStep === 1 &&
    !isReplacement
  ) {
    return ItemType.FULL_DENTURE_WAX_BITE;
  }
  return ItemType.FULL_DENTURE;
};

export const getTeethsFromUpperArch = (teeths: number[]): number[] => {
  if (!teeths || teeths.length === 0) {
    return [];
  }
  return teeths.reduce((acc: number[], teeth: number) => {
    if (teeth <= 28) {
      acc.push(teeth);
    }
    return acc;
  }, []);
};

export const getTeethsFromLowerArch = (teeths: number[]): number[] => {
  if (!teeths || teeths.length === 0) {
    return [];
  }
  return teeths.reduce((acc: number[], teeth: number) => {
    if (teeth >= 31) {
      acc.push(teeth);
    }
    return acc;
  }, []);
};

export const checkHasGenericImplant = order => {
  const products = order?.products ?? [];
  const implantItems = products.reduce((acc, product) => {
    if ((product?.implantItem ?? []).length > 0) {
      acc.push(...product.implantItem);
    }
    return acc;
  }, []);
  if (implantItems.length === 0) {
    return false;
  }
  const hasGenericImplant = (implantItems ?? []).find(
    item =>
      item.abutmentType === AbutmentType.STANDARD ||
      item.abutmentType === AbutmentType.STANDARD_GENERIC
  );
  return hasGenericImplant ?? false;
};

export const formatChoicesData = (
  rawData: any,
  claveted?: string
): { value: string; label: string; shadeType: ItemShadeType }[] => {
  let data = rawData;
  if (claveted) {
    data = data.filter((item: any) =>
      clavetedInlayCoreMaterials.includes(item.id)
    );
  }
  return data
    .map((item: any) => ({
      label: item.label,
      value: item.id,
      shadeType: item.shadeType,
      ...item,
    }))
    .sort(
      (
        a: { value: string; label: string; shadeType: ItemShadeType },
        b: { value: string; label: string; shadeType: ItemShadeType }
      ) => a.value.localeCompare(b.value)
    );
};

export const checkisInlayCoreOnly = (
  product: Product,
  isNotBridgeOnInlayCore: boolean
) => {
  if (!product) {
    return false;
  }
  if (product.productType !== ItemType.INLAY_CORE) {
    return false;
  }
  if (
    (product?.anatomyItem ?? []).find(
      (item: AnatomyItem) =>
        item.itemType === ItemType.CROWN ||
        item.itemType === ItemType.SPLINTED_CROWN
    )
  ) {
    return false;
  }
  if (isNotBridgeOnInlayCore) {
    return false;
  }
  return true;
};

export const deduceShadeType = (
  shadeId: FixedProsthesisShadeType | PartialAndFullDentureProsthesisShadeType,
  user_id: string,
  accountProductPreferences: {
    dentist: { id: string };
    productPreferenceType: ProductPreferenceType;
  }[]
) => {
  if (!shadeId) {
    return (
      findUserPreference(user_id, accountProductPreferences)?.shadeType ??
      FixedProsthesisShadeType.VITA_CLASSIC
    );
  }
  if (
    shadeId
      .toLowerCase()
      .search(FixedProsthesisShadeType.VITA_CLASSIC.toLowerCase()) >= 0
  ) {
    return FixedProsthesisShadeType.VITA_CLASSIC;
  }
  if (
    shadeId
      .toLowerCase()
      .search(FixedProsthesisShadeType.VITA_3D_MASTER.toLowerCase()) >= 0
  ) {
    return FixedProsthesisShadeType.VITA_3D_MASTER;
  }
  if (
    shadeId
      .toLowerCase()
      .search(
        PartialAndFullDentureProsthesisShadeType.SR_VIVODENT_SPE.toLowerCase()
      ) >= 0
  ) {
    return PartialAndFullDentureProsthesisShadeType.SR_VIVODENT_SPE;
  }
  return FixedProsthesisShadeType.VITA_3D_MASTER;
};

export const getTeethSideLabel = (teethSide: string, translated?: boolean) => {
  if (!translated) {
    switch (teethSide) {
      case TeethShadeSide.GINGIVAL:
        return "Tiers cervical";
      case TeethShadeSide.BASE:
        return "Tiers médian";
      case TeethShadeSide.INCISAL:
        return "Tiers incisal";
    }
  }
  return teethSide;
};

export const getItemMultiShadeLabel = (item, translated?) => {
  return (item?.multiShadeInfo ?? []).reduce(
    (acc, shade) =>
      (acc += `- ${getTeethSideLabel(shade?.teethShadeSide, translated)}: ${shade?.itemShade?.label}\n`),
    ""
  );
};

const AbutmentTypeChoices = [
  { value: AbutmentType.STANDARD, label: "Standard" },
  { value: AbutmentType.CUSTOM, label: "Personnalisé" },
];

export const StandardAbutmentTypeChoices = [
  { value: AbutmentType.STANDARD, label: "Pilier original - fabriquant" },
  { value: AbutmentType.STANDARD_GENERIC, label: "Pilier standard générique" },
];

export const INLAY_CORE_SCAN_POST = {
  ANATOMICAL: { value: "ANATOMICAL", label: "Anatomique" },
};

export const scanPosts = [
  {
    type: "PIVOMATIC",
    diameter: "1.2",
    length: "SHORT",
    value: "PIVOMATIC_1.2_SHORT",
    label: "PS 1.2 (Court)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.3",
    length: "SHORT",
    value: "PIVOMATIC_1.3_SHORT",
    label: "PS 1.3 (Court)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.4",
    length: "SHORT",
    value: "PIVOMATIC_1.4_SHORT",
    label: "PS 1.4 (Court)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.5",
    length: "SHORT",
    value: "PIVOMATIC_1.5_SHORT",
    label: "PS 1.5 (Court)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.6",
    length: "SHORT",
    value: "PIVOMATIC_1.6_SHORT",
    label: "PS 1.6 (Court)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.7",
    length: "SHORT",
    value: "PIVOMATIC_1.7_SHORT",
    label: "PS 1.7 (Court)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.2",
    length: "LONG",
    value: "PIVOMATIC_1.2_LONG",
    label: "PL 1.2 (Long)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.3",
    length: "LONG",
    value: "PIVOMATIC_1.3_LONG",
    label: "PL 1.3 (Long)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.4",
    length: "LONG",
    value: "PIVOMATIC_1.4_LONG",
    label: "PL 1.4 (Long)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.5",
    length: "LONG",
    value: "PIVOMATIC_1.5_LONG",
    label: "PL 1.5 (Long)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.6",
    length: "LONG",
    value: "PIVOMATIC_1.6_LONG",
    label: "PL 1.6 (Long)",
  },
  {
    type: "PIVOMATIC",
    diameter: "1.7",
    length: "LONG",
    value: "PIVOMATIC_1.7_LONG",
    label: "PL 1.7 (Long)",
  },
  {
    type: "APOL",
    diameter: "1.4",
    length: "SHORT",
    value: "APOL_1.4_SHORT",
    label: "AS 1.4 (Court)",
  },
  {
    type: "APOL",
    diameter: "1.6",
    length: "SHORT",
    value: "APOL_1.6_SHORT",
    label: "AS 1.6 (Court)",
  },
  {
    type: "APOL",
    diameter: "1.7",
    length: "SHORT",
    value: "APOL_1.7_SHORT",
    label: "AS 1.7 (Court)",
  },
  {
    type: "APOL",
    diameter: "1.2",
    length: "LONG",
    value: "APOL_1.2_LONG",
    label: "AL 1.2 (Long)",
  },
  {
    type: "APOL",
    diameter: "1.4",
    length: "LONG",
    value: "APOL_1.4_LONG",
    label: "AL 1.4 (Long)",
  },
  {
    type: "APOL",
    diameter: "1.6",
    length: "LONG",
    value: "APOL_1.6_LONG",
    label: "AL 1.6 (Long)",
  },
  {
    type: "APOL",
    diameter: "1.7",
    length: "LONG",
    value: "APOL_1.7_LONG",
    label: "AL 1.7 (Long)",
  },
  {
    type: "EXOTEC",
    diameter: "1.2",
    length: "SHORT",
    value: "EXOTEC_1.2_YELLOW_SHORT",
    label: "Exotec 1.2 Jaune (Court)",
  },
  {
    type: "EXOTEC",
    diameter: "1.2",
    length: "LONG",
    value: "EXOTEC_1.2_YELLOW_LONG",
    label: "Exotec 1.2 Jaune (Long)",
  },
  {
    type: "EXOTEC",
    diameter: "1.4",
    length: "SHORT",
    value: "EXOTEC_1.4_PURPLE_SHORT",
    label: "Exotec 1.4 Violet (Court)",
  },
  {
    type: "EXOTEC",
    diameter: "1.4",
    length: "LONG",
    value: "EXOTEC_1.4_YELLOW_LONG",
    label: "Exotec 1.4 Violet (Long)",
  },
  {
    type: "EXOTEC",
    diameter: "1.6",
    length: "SHORT",
    value: "EXOTEC_1.6_BLUE_SHORT",
    label: "Exotec 1.6 Bleu (Court)",
  },
  {
    type: "EXOTEC",
    diameter: "1.6",
    length: "LONG",
    value: "EXOTEC_1.6_BLUE_LONG",
    label: "Exotec 1.6 Bleu (Long)",
  },
  {
    type: "EXOTEC",
    diameter: "1.7",
    length: "SHORT",
    value: "EXOTEC_1.7_GREY_SHORT",
    label: "Exotec 1.7 Gris (Court)",
  },
  {
    type: "EXOTEC",
    diameter: "1.7",
    length: "LONG",
    value: "EXOTEC_1.7_GREY_LONG",
    label: "Exotec 1.7 Gris (Long)",
  },
];

export const getItemMaterialLabel = (
  item: AnatomyItem | ImplantItem | RemovableItem
) => {
  let ret = "";
  if (!item.itemMaterial?.label) {
    return "";
  }
  if (
    item.itemType === ItemType.IMPLANT ||
    item.itemType === ItemType.SPLINTED_CROWN_IMPLANT
  ) {
    ret = ret + "Couronne ";
  }
  if (item.itemMaterial?.label) {
    return ret + item.itemMaterial.label;
  }
};

export const getItemInlayCoreMaterialLabel = (
  item: AnatomyItem,
  shouldTranslate: boolean = false
) => {
  if (
    item.itemType === ItemType.INLAY_CORE ||
    item.itemType === ItemType.POST_AND_CORE_RETAINED_CROWN ||
    item.itemType === ItemType.RICHMOND_CROWN
  ) {
    if (shouldTranslate) {
      return `Post and core material: ${translateInlayCoreMaterial(item?.inlayCoreMaterial?.id)}`;
    }
    return item.inlayCoreMaterial?.label
      ? `${item.itemType === ItemType.INLAY_CORE ? "Inlay-core" : "Tenon"} en ${item.inlayCoreMaterial.label}`
      : "";
  }
  return "";
};

export const getItemInlayCoreHasClavetteLabel = (
  item: AnatomyItem,
  shouldTranslate: boolean = false
) => {
  if (shouldTranslate) {
    return item.inlayCoreHasClavette ? "with multiple posts" : "";
  }
  return item.inlayCoreHasClavette ? "avec clavette" : "";
};

export const getItemShadeLabel = (item: Item) => {
  if ("itemShade" in item) {
    return item.itemShade?.label ?? "";
  }
  return item.teethShade?.label ?? "";
};

export const getItemInlayCoreScanPostLabel = (item: AnatomyItem) => {
  if (
    item.itemType === ItemType.INLAY_CORE ||
    item.itemType === ItemType.RICHMOND_CROWN ||
    item.itemType === ItemType.POST_AND_CORE_RETAINED_CROWN
  ) {
    const scanPostLabel =
      scanPosts.find(scanPost => scanPost.value === item.inlayCoreScanPost)
        ?.label ?? INLAY_CORE_SCAN_POST.ANATOMICAL.label;
    return scanPostLabel ? `Scan post ${scanPostLabel.toLowerCase()}` : "";
  }
  return "";
};

export const getItemInlayCoreScanPostTranslated = (item: AnatomyItem) => {
  if (
    item.itemType === ItemType.INLAY_CORE ||
    item.itemType === ItemType.RICHMOND_CROWN ||
    item.itemType === ItemType.POST_AND_CORE_RETAINED_CROWN
  ) {
    const scanPostLabel =
      scanPosts.find(scanPost => scanPost.value === item.inlayCoreScanPost)
        ?.value ?? item.inlayCoreScanPost;
    return scanPostLabel
      ? `Scan post: ${scanPostLabel.replaceAll("_", " ")}`
      : "";
  }
  return "";
};

export const getImplantRetentionTypeLabel = (
  item: ImplantItem,
  shouldTranslate: boolean = false
) => {
  if (item.retentionType === RetentionType.CEMENT_RETAINED) {
    return shouldTranslate
      ? `Retention type: ${RetentionType.CEMENT_RETAINED}`
      : "Système de rétention : scellé";
  } else if (item.retentionType === RetentionType.SCREW_RETAINED) {
    return shouldTranslate
      ? `Retention type: ${RetentionType.SCREW_RETAINED}`
      : "Système de rétention : transvissé";
  }
  return "";
};

export const getItemAbutmentMaterialLabel = (
  item: ImplantItem,
  shouldTranslate: boolean = false
) => {
  if (shouldTranslate) {
    return translateItemMaterial(item?.abutmentMaterial?.id);
  }
  return item.abutmentMaterial?.label ?? "";
};

export const sortItems = (items: any, productType: ItemType) => {
  if (!items || items.length < 2) {
    return items;
  }
  if (productType === ItemType.BRIDGE_IMPLANT) {
    items = [...items].sort(a => {
      if (a.itemType === ItemType.BRIDGE_IMPLANT) return -1;
      if (a.itemType === ItemType.IMPLANT) return 1;
      return 0;
    });
  }
  return items;
};

export const getItemAbutmentTypeLabel = (
  item: ImplantItem,
  shouldTranslate: boolean = false
) => {
  if (
    (item.itemType === ItemType.IMPLANT ||
      item.itemType === ItemType.SPLINTED_CROWN_IMPLANT) &&
    item.abutmentType
  ) {
    let abutmentLabel =
      AbutmentTypeChoices.find((a: any) => a.value === item.abutmentType) ??
      StandardAbutmentTypeChoices.find(
        (a: any) => a.value === item.abutmentType
      );
    abutmentLabel = shouldTranslate
      ? abutmentLabel?.value
      : abutmentLabel?.label;
    if (abutmentLabel) {
      return abutmentLabel.charAt(0).toUpperCase() + abutmentLabel.slice(1);
    }
  }
  return "";
};

export const getItemTypePluralLabel = (
  item: AnatomyItem | ImplantItem | RemovableItem
) => {
  if (!item || !item.itemType) return "";
  const plural =
    Array.isArray(item.teeth) && item.teeth.length > 1 ? true : false;
  if (
    !plural ||
    isRemovableItemType(item.itemType) ||
    item.itemType === ItemType.BRIDGE ||
    item.itemType === ItemType.BRIDGE_IMPLANT
  ) {
    return getItemTypeLabel(item.itemType);
  }
  if (item.itemType === ItemType.CROWN) {
    return "Couronnes";
  } else if (item.itemType === ItemType.INLAY) {
    return "Inlays";
  } else if (item.itemType === ItemType.IMPLANT) {
    return "Piliers";
  } else if (item.itemType === ItemType.SPLINTED_CROWN_IMPLANT) {
    return "Couronnes solidarisées sur implant";
  } else if (item.itemType === ItemType.ONLAY) {
    return "Onlays";
  } else if (item.itemType === ItemType.LAY) {
    return "Inlays/Onlays";
  } else if (item.itemType === ItemType.OVERLAY) {
    return "Overlay";
  } else if (item.itemType === ItemType.VENEER) {
    return "Facettes";
  } else if (item.itemType === ItemType.INLAY_CORE) {
    return "Inlay-cores";
  } else if (
    item.itemType === ItemType.POST_AND_CORE_RETAINED_CROWN ||
    item.itemType === ItemType.RICHMOND_CROWN
  ) {
    return "Couronnes Richmond";
  } else if (item.itemType === ItemType.SPLINTED_CROWN) {
    return "Couronnes solidarisées";
  } else if (item.itemType === ItemType.SPACE_MAINTAINER) {
    return "Mainteneurs d'espace";
  } else if (item.itemType === ItemType.RETENTION_WIRE) {
    return "Fils de contention";
  } else if (item.itemType === ItemType.WAX_UP) {
    return "Wax up";
  } else if (
    item.itemType === ItemType.ADDITIONAL_IMPRESSION_FOR_REMOVABLE_PROSTHESIS
  ) {
    return "Empreintes supplémentaires pour prothèse amovible";
  } else if (item.itemType === ItemType.ADDING_TEETH_ON_EXISTING_DENTURE) {
    return "Adjonction de dents sur prothèse existante";
  } else if (item.itemType === ItemType.DENTURE_REPAIR) {
    return "Réparation prothèse amovible";
  } else {
    return "";
  }
};

export const getItemTypeWithCountLabel = (
  item: AnatomyItem | ImplantItem | RemovableItem,
  shouldTranslate: boolean = false
) => {
  if (!item || !item.itemType) {
    return "";
  }
  const itemName = shouldTranslate
    ? translateItemType(item.itemType)
    : getItemTypePluralLabel(item);
  if (item.itemType === ItemType.BRIDGE_IMPLANT) {
    return `- 1 x ${itemName}`;
  } else if (
    isRemovableItemType(item.itemType) ||
    item.itemType === ItemType.BRIDGE
  ) {
    return `- ${itemName}`;
  } else if (item.itemType === ItemType.SPLINTED_CROWN) {
    return `- 1 x ${
      Array.isArray(item.teeth) ? item.teeth.length : 0
    } ${itemName}`;
  } else {
    return `- ${
      Array.isArray(item.teeth) ? item.teeth.length : 0
    } x ${itemName}`;
  }
};

export const filterShadeByShadeType = (
  allShades: { id: string; label: string; shadeType: string }[],
  selectedShadeType: FixedProsthesisShadeType
) => {
  const shades =
    allShades !== null && allShades !== undefined ? [...allShades] : [];
  if (!selectedShadeType) {
    return shadeSort(shades).map(shade => ({
      value: shade.id,
      label: shade.label,
    }));
  }
  return shadeSort(shades)
    .filter(shade => shade.shadeType === selectedShadeType)
    .map(shade => ({ value: shade.id, label: shade.label }));
};

export const shadeSort = (
  allShades: { id: string; label: string; shadeType: string }[]
) => {
  const parseCodeName = (str: string) => {
    const match = str.match(
      /^([a-zA-Z]+)?(\d+(\.\d+)?)([a-zA-Z]+)?(\d+(\.\d+)?)?$/
    ); // works for letter? number letter? number?
    return match
      ? [
          match[1] || "",
          parseFloat(match[2]),
          match[4] || "",
          parseFloat(match[5]) || NaN,
        ]
      : ["", 0, "", NaN];
  };
  const notPaseableShades = [
    "Rose rouge",
    "Rose clair",
    "Classique",
    "Rose foncé",
  ];
  return allShades.sort((a, b) => {
    if (
      !notPaseableShades.includes(a.label) &&
      !notPaseableShades.includes(b.label)
    ) {
      const [aFirst, aNum, aLast, aNumLast] = parseCodeName(
        a.label.split("(")[0].trim()
      );
      const [bFirst, bNum, bLast, bNumLast] = parseCodeName(
        b.label.split("(")[0].trim()
      );

      if (aFirst < bFirst) return -1;
      if (aFirst > bFirst) return 1;

      if (aNum < bNum) return -1;
      if (aNum > bNum) return 1;

      if (aLast < bLast) return -1;
      if (aLast > bLast) return 1;

      if (!isNaN(aNumLast as number) && isNaN(bNumLast as number)) return -1;
      if (isNaN(aNumLast as number) && !isNaN(bNumLast as number)) return 1;

      if (aNumLast < bNumLast) return -1;
      if (aNumLast > bNumLast) return 1;

      return 0;
    }
    return a.label.localeCompare(b.label);
  });
};

export const getImplantReferenceBrands = (data: {
  getAllImplantReferences: { brand: string }[];
}) => {
  return (data?.getAllImplantReferences ?? [])
    .map(implantReference => implantReference.brand.trim())
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort((a, b) => a.localeCompare(b));
};

export const getImplantReferenceChoices = (
  data: any
): {
  value: string;
  label: string;
  brand: string;
  platforms: string[];
}[] => {
  return (data?.getAllImplantReferences ?? []).map((implantReference: any) => ({
    value: implantReference.id,
    ...implantReference,
  }));
};

export const getImplantItemPlatformChoices = (data: string[]) => {
  if (data) {
    return data.map((item: string) => {
      return { label: item, value: item };
    });
  }
  return [];
};

export const getImplantItemConnectionChoices = (
  data: { value: string; label: string }[]
) => {
  if (data && data.length > 0) {
    return data.map((item: { value: string; label: string }) => {
      return { label: item.label, value: item.value };
    });
  }
  return [];
};
