import { useState } from "react";
import { Text, Space, Button } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";

import { UPDATE_ORDER } from "../../gql/orders";
import { STYLE } from "../../types/constants";
import { getLastShippingDateFromDeliveryDate } from "../../utils/order.utils";

const UpdateDeliveryDateModal = ({ order, closeModal, refetch }) => {
  const currentDelieveryDate =
    new Date(order?.deliveryDate).toISOString().split("T")[0] || null;
  const [newDeliveryDate, setNewDeliveryDate] = useState(
    new Date(order?.deliveryDate).toISOString().split("T")[0] || null
  );

  const [updateOrder] = useMutation(UPDATE_ORDER);

  const submitNewDeliveryDate = async () => {
    if (!newDeliveryDate) {
      notifications.show({
        title: "Delivery date cannot be empty",
        color: "red",
        message: "",
      });
      return;
    }
    const data: { deliveryDate: { set: string }; isRush?: { set: boolean } } = {
      deliveryDate: {
        set: newDeliveryDate,
      },
      lastShippingDate: {
        set: getLastShippingDateFromDeliveryDate(newDeliveryDate),
      },
    };
    if (currentDelieveryDate && currentDelieveryDate > newDeliveryDate) {
      data.isRush = {
        set: true,
      };
    }
    try {
      await updateOrder({
        variables: {
          where: {
            id: order.id,
          },
          data: data,
        },
      });
      notifications.show({
        title: "Delivery date updated",
        color: "green",
        message: "",
      });
      refetch();
      closeModal();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to update delivery date",
        color: "red",
        message: "",
      });
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Text>
        Actual delivery date: {new Date(order.deliveryDate).toDateString()}
      </Text>
      <Space h="md" />
      <input
        type="date"
        value={newDeliveryDate}
        onChange={e => setNewDeliveryDate(e.target.value)}
      />
      <br />
      <Space h="md" />
      <Button
        style={{ backgroundColor: STYLE.primary }}
        onClick={submitNewDeliveryDate}
      >
        Submit new delivery date
      </Button>
    </div>
  );
};

export default UpdateDeliveryDateModal;
