import { useState } from "react";
import { Text, Space, Textarea, Button } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";

import { TRANSLATE_COMMENT } from "../../gql/orders";
import { STYLE } from "../../types/constants";
import { Order, OrderComment } from "@jasper/shared/types/interfaces";

interface TranslateCommentModalProps {
  order: Order;
  closeModal: () => void;
  refetch: () => void;
  comment: OrderComment;
}

const TranslateCommentModal = ({
  order,
  closeModal,
  refetch,
  comment,
}: TranslateCommentModalProps) => {
  const [translatedComment, setTranslatedComment] = useState<string>(
    (order?.orderComment ?? []).find(c => c.id === comment.id)
      ?.translatedComment ?? ""
  );
  const [translateCommentMutation] = useMutation(TRANSLATE_COMMENT);

  const submitTranslateComment = async () => {
    if (!translatedComment) {
      notifications.show({
        title: "You cannot set an empty comment",
        color: "red",
        message: "",
      });
      return;
    }
    try {
      await translateCommentMutation({
        variables: {
          where: {
            id: comment?.id,
          },
          data: {
            translatedComment: {
              set: translatedComment,
            },
          },
        },
      });
      notifications.show({
        title: "Comment is correctly translated",
        color: "green",
        message: "",
      });
      refetch();
      closeModal();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to update the comment",
        color: "red",
        message: "",
      });
    }
  };

  return (
    <>
      <Text style={{ fontWeight: "bold" }}>Comment:</Text>
      <blockquote
        style={{
          whiteSpace: "pre-wrap",
          backgroundColor: "#ededed",
          borderRadius: "5px",
          padding: "5px",
        }}
      >
        {comment?.comment}
      </blockquote>
      <Space h="md" />
      <Textarea
        label="Translate comment:"
        rows={4}
        value={translatedComment}
        onChange={e => setTranslatedComment(e.target.value)}
      />
      <Space h="md" />
      <Button
        style={{ backgroundColor: STYLE.primary }}
        onClick={submitTranslateComment}
      >
        Submit translation
      </Button>
    </>
  );
};

export default TranslateCommentModal;
