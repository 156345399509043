import { Space, Text } from "@mantine/core";

import TextInputRef from "../TextInputRef";
import SelectInputRef from "../SelectInputRef";
import { ItemType } from "../../types/enums";

const UpdateItemMaterialBrandAndLotNumberComponent = ({
  item,
  product,
  errors,
  control,
  allMaterialTraceability,
  required,
}) => {
  return (
    <>
      <Text style={{ textAlign: "center", fontWeight: "bold" }}>
        Item {(item?.itemType ?? product?.productType).toLowerCase()} with
        material {item?.itemMaterial?.label}
      </Text>
      <Space h="md" />
      <TextInputRef
        name={`${item.id}.lotNumber`}
        label="Lot number"
        errors={errors}
        control={control}
      />
      <Space h="md" />
      <SelectInputRef
        name={`${item.id}.materialBrand`}
        data={allMaterialTraceability
          .filter(material => material.id && material.firstMaterialReference)
          .filter(material => {
            if (item.itemType === ItemType.INLAY_CORE) {
              return material.itemMaterialId === item?.inlayCoreMaterial?.id;
            }
            return material.itemMaterialId === item?.itemMaterial?.id;
          })
          .map(material => ({
            value: material.id,
            label:
              material.translatedName ??
              `${material.firstMaterialReference} - ${material.firstMaterialApprovalReference} - ${material.firstMaterialBrand} - ${material.firstMaterialCountry}`,
          }))}
        label="Material brand"
        errors={errors}
        control={control}
      />
      <Space h="md" />
    </>
  );
};

export default UpdateItemMaterialBrandAndLotNumberComponent;
