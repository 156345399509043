import { useMutation, useQuery } from "@apollo/client";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  Menu,
  Button as MantimeButton,
  Table,
  Modal,
  MantineStyleProp,
} from "@mantine/core";

import { Button } from "/@/composants/";
import {
  GET_ALL_ORDERS_FOR_STAFF,
  UPDATE_STATUS_AND_ADD_PRODIVER_GROUP_ID_FOR_MANY_ORDER,
} from "/@/gql/";
import { getTodayTimestamp } from "/@/utils/date.utils";
import { Order, User, UserGroup } from "@jasper/shared/types/interfaces";
import { UseAuth } from "/@/contexts";
import { socket } from "../socket";

import {
  OrderStatus,
  UPDATE_MANY_ORDER,
  isUserProvider,
  isUserStaff,
  isImplant,
  isOrderWithNoAttachement,
  isPAC,
  SearchBar,
  PrecedentComponent,
  UserType,
} from "@jasper/shared";
import { notifications } from "@mantine/notifications";
import SendOrderToSupplierModal from "@jasper/shared/components/modals/sendOrderToSupplierModal";
import { GET_ALL_PROVIDERS } from "@jasper/shared/gql/users";
import { IconArrowLeft, IconArrowRight, IconClick } from "@tabler/icons-react";
import "./home.css";
import { REFRESH_PASTILLE } from "@jasper/shared/gql/orders";
import { getDentistCode } from "@jasper/shared/utils/user.utils";

const LIMIT_PAGINATION_QUANTITY = 20;

interface OrderListTableProps {
  data: Order[];
  navigate: (url: string) => void;
  status: string | null;
  setListOrderToUpdate: (orders: Order[]) => void;
  toggleList: (order: Order) => void;
  user: User;
  selectedValues: string[];
  selectAll: (orderIds: string[]) => void;
  searchText: string;
}
const OrderListTable = ({
  data,
  navigate,
  status,
  setListOrderToUpdate,
  toggleList,
  user,
  selectedValues,
  selectAll,
  searchText,
}: OrderListTableProps) => {
  const checkAllCheckbox = useRef<HTMLInputElement>(null);
  const getBackgroundColor = (order: Order) => {
    if (isPAC(order)) {
      return { backgroundColor: "blue", color: "white" };
    }
    if ((order?.redo ?? []).length > 0) {
      return { backgroundColor: "purple", color: "white" };
    }
    if (isImplant(order) && order?.comment) {
      return { backgroundColor: "red", color: "white" };
    }
    if (isImplant(order)) {
      return { backgroundColor: "darkred", color: "white" };
    }
    if ((order?.orderComment ?? []).length > 0) {
      return { backgroundColor: "lightcoral", color: "white" };
    }
    return "";
  };
  const displayLastUpdate = (dt: Date) => {
    const diffMs = dt.getTime() - new Date().getTime();
    const lastUpdateInDays = Math.round(
      Math.abs(diffMs / (24 * 60 * 60 * 1000))
    );
    if (lastUpdateInDays >= 1) {
      return dt.toDateString();
    }
    return dt.toLocaleTimeString();
  };

  useEffect(() => {
    setListOrderToUpdate([]);
    if (checkAllCheckbox.current) {
      checkAllCheckbox.current.checked = false;
    }
  }, [status]);

  const checkAll = () => {
    if (checkAllCheckbox.current && checkAllCheckbox.current.checked === true) {
      const checkboxes = document.getElementsByName(
        "selectOrder"
      ) as NodeListOf<HTMLInputElement>;
      const orderIds = [...checkboxes].map(box => box.value);
      selectAll(orderIds);
    } else {
      selectAll([]);
    }
  };

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <label
              className="label-checkbox"
              htmlFor="checkAll"
            >
              <input
                id="checkAll"
                ref={checkAllCheckbox}
                type="checkbox"
                onChange={() => checkAll()}
              />
            </label>
          </Table.Th>
          <Table.Th>Note</Table.Th>
          <Table.Th>Reference</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Doctor</Table.Th>
          <Table.Th>Patient</Table.Th>
          <Table.Th>Product</Table.Th>
          {isUserStaff(user) && (
            <>
              <Table.Th>Delivery Date</Table.Th>
              <Table.Th>Last update</Table.Th>
            </>
          )}
          {isUserProvider(user) && <Table.Th>Last shipping date</Table.Th>}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {(data ?? []).map((order: Order) => (
          <Table.Tr
            style={{ cursor: "pointer" }}
            key={order.id}
          >
            <Table.Td>
              {((
                order?.orderComment.filter(
                  comment => comment.translatedComment === null
                ) ?? []
              ).length === 0 ||
                order?.status !== OrderStatus.CONFIRMED) && (
                <label
                  className="label-checkbox"
                  htmlFor={`check-${order.id}`}
                >
                  <input
                    id={`check-${order.id}`}
                    type="checkbox"
                    value={order.id}
                    name="selectOrder"
                    checked={selectedValues.includes(order.id)}
                    onChange={() => toggleList(order)}
                  />
                </label>
              )}
            </Table.Td>
            <Table.Td
              onClick={() =>
                navigate(`/orders/${order.id}?search=${searchText}`)
              }
              style={getBackgroundColor(order) as MantineStyleProp}
            >
              {(order?.isRush ?? false) && "RUSH "}
              {(order?.isPhysicalPrint ?? false) && "PHYSICAL PRINT "}
              {(order?.orderComment ?? []).length > 0 && " COMMENT "}
              {isImplant(order) && "IMPLANT "}
              {isOrderWithNoAttachement(order) && "NO ATTACHEMENT "}
              {isPAC(order) && "PAC "}
              {(order?.redo ?? []).length > 0 && "REDO"}
            </Table.Td>
            <Table.Td
              onClick={() =>
                navigate(`/orders/${order.id}?search=${searchText}`)
              }
            >
              {order.orderReference}
            </Table.Td>
            <Table.Td
              onClick={() =>
                navigate(`/orders/${order.id}?search=${searchText}`)
              }
            >
              {order.status}
            </Table.Td>
            <Table.Td
              onClick={() =>
                navigate(`/orders/${order.id}?search=${searchText}`)
              }
            >
              {isUserProvider(user) ? (
                <>{getDentistCode(order?.user)}</>
              ) : (
                <>
                  Dr {order?.user?.firstName} {order?.user?.lastName}
                </>
              )}
            </Table.Td>
            <Table.Td
              onClick={() =>
                navigate(`/orders/${order.id}?search=${searchText}`)
              }
            >
              {order?.patient?.firstName} {order?.patient?.lastName}
            </Table.Td>
            <Table.Td
              onClick={() =>
                navigate(`/orders/${order.id}?search=${searchText}`)
              }
            >
              {(order.products ?? []).length > 1
                ? "Multiple"
                : order.products[0]?.productType}
            </Table.Td>
            {isUserStaff(user) && (
              <>
                <Table.Td
                  onClick={() =>
                    navigate(`/orders/${order.id}?search=${searchText}`)
                  }
                >
                  {order.deliveryDate
                    ? new Date(order.deliveryDate).toDateString()
                    : "-"}
                </Table.Td>
                <Table.Td
                  onClick={() =>
                    navigate(`/orders/${order.id}?search=${searchText}`)
                  }
                >
                  {displayLastUpdate(
                    new Date(order.updatedAt ?? order.createdAt)
                  )}
                </Table.Td>
              </>
            )}
            {isUserProvider(user) && (
              <Table.Td
                onClick={() =>
                  navigate(`/orders/${order.id}?search=${searchText}`)
                }
              >
                {order?.lastShippingDate
                  ? new Date(order?.lastShippingDate).toDateString()
                  : "-"}
              </Table.Td>
            )}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

const todayTimestamp = getTodayTimestamp();

const Home = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [listOrderToUpdate, setListOrderToUpdate] = useState<Order[]>([]);
  const [ordersToDisplay, setOrdersToDisplay] = useState<Order[]>([]);
  const [updateOrderStatusAndAddProvider] = useMutation(
    UPDATE_STATUS_AND_ADD_PRODIVER_GROUP_ID_FOR_MANY_ORDER
  );
  const [updateOrderStatus] = useMutation(UPDATE_MANY_ORDER);
  const [openSendToSupplierModal, setOpenSendToSupplierModal] = useState(false);
  const [refreshPastille] = useMutation(REFRESH_PASTILLE);
  const { data: allProviders } = useQuery(GET_ALL_PROVIDERS);
  const [page, setPage] = useState<number>(
    parseInt(searchParams.get("page") ?? "0")
  );
  const [totalCountOfItem, setTotalCountOfItem] = useState(0);

  const toggleList = (order: Order) => {
    const foundOrder = listOrderToUpdate.find(val => val.id === order.id);
    if (foundOrder !== undefined) {
      setListOrderToUpdate(
        listOrderToUpdate.filter(val => val.id === order.id)
      );
    } else {
      setListOrderToUpdate([...listOrderToUpdate, order]);
    }
  };

  const [searchText, setSearchText] = useState<string>("");

  const navigate = useNavigate();

  const { user } = UseAuth();

  const getStatus = () => {
    if (searchParams.get("status")) {
      return searchParams.get("status");
    }
    if (isUserProvider(user)) {
      return OrderStatus.PRODUCING;
    }
    if (isUserStaff(user)) {
      return OrderStatus.CONFIRMED;
    }
  };

  const getOrderListFilters = () => {
    const filters: any = {};
    if (searchParams.get("status") === "ARCHIVED") {
      filters["isArchived"] = {
        equals: true,
      };
    }
    if (searchParams.get("status") === "TO_SHIP") {
      filters["lastShippingDate"] = {
        lte: todayTimestamp,
      };
      filters["status"] = {
        in: [
          OrderStatus.WAITING_FOR_PRODUCTION,
          OrderStatus.PRODUCING,
          OrderStatus.ON_HOLD,
        ],
      };
    }
    if (
      searchParams.get("status") &&
      searchParams.get("status") !== "ALL" &&
      searchParams.get("status") !== "TO_SHIP" &&
      searchParams.get("status") !== "ARCHIVED"
    ) {
      filters["status"] = {
        equals: searchParams.get("status"),
      };
      filters["isArchived"] = {
        equals: false,
      };
    }
    if (searchText) {
      filters["OR"] = [
        {
          orderReference: {
            contains: searchText,
            mode: "insensitive",
          },
        },
        {
          patient: {
            is: {
              firstName: {
                contains: searchText,
                mode: "insensitive",
              },
            },
          },
        },
        {
          patient: {
            is: {
              lastName: {
                contains: searchText,
                mode: "insensitive",
              },
            },
          },
        },
        {
          user: {
            is: {
              firstName: {
                contains: searchText,
                mode: "insensitive",
              },
            },
          },
        },
        {
          user: {
            is: {
              lastName: {
                contains: searchText,
                mode: "insensitive",
              },
            },
          },
        },
      ];
    }
    return filters;
  };

  const { data, refetch } = useQuery(GET_ALL_ORDERS_FOR_STAFF, {
    variables: {
      skip: LIMIT_PAGINATION_QUANTITY * page,
      take: LIMIT_PAGINATION_QUANTITY,
      where: getOrderListFilters(),
    },
    fetchPolicy: "network-only",
  });

  const startProduction = async (
    listOrderToUpdate: Order[],
    providerGroupId?: string
  ) => {
    try {
      await updateOrderStatusAndAddProvider({
        variables: {
          orderIds: listOrderToUpdate.map(order => order.id),
          providerId: providerGroupId,
        },
      });
      refetch();
      setListOrderToUpdate([]);
      refreshPastille({
        variables: {
          args: listOrderToUpdate.map(order => ({
            userGroupId: providerGroupId,
            previousUserGroupId: order.providerGroupId,
            orderId: order.id,
            previousStatus: order.status,
            currentStatus: OrderStatus.PRODUCING,
          })),
        },
      });
      notifications.show({
        title: "Orders are correctly set as producing",
        color: "green",
        message: "",
      });
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to set orders as producing",
        color: "red",
        message: "",
      });
    }
  };

  const handleListAddEvent = (orderToAdd: Order) => {
    ordersToDisplay.length === LIMIT_PAGINATION_QUANTITY
      ? setOrdersToDisplay(state => {
          if (state.find((order: Order) => order?.id === orderToAdd?.id)) {
            return state;
          } else {
            const lastState = [...state];
            lastState.pop();
            return [orderToAdd, ...lastState];
          }
        })
      : setOrdersToDisplay(state => {
          return state.find((order: Order) => order?.id === orderToAdd?.id)
            ? state
            : [orderToAdd, ...state];
        });
    setTotalCountOfItem(total => total + 1);
  };

  useEffect(() => {
    if (searchParams.get("search") && !searchText) {
      setSearchText(searchParams.get("search") ?? "");
    }
  }, [searchParams.get("search")]);

  useEffect(() => {
    if (user.type === UserType.JASPER) {
      socket.on("newOrderConfirmed", (args: { order: Order }) => {
        if (
          parseInt(searchParams.get("page") ?? "0") === 0 &&
          ["ALL", OrderStatus.CONFIRMED].includes(
            searchParams.get("status") ?? ""
          )
        ) {
          handleListAddEvent(args.order);
        }
      });
      return () => {
        socket.off("newOrderConfirmed");
      };
    }
    if (user.type === UserType.SUPPLIER) {
      socket.on("assignNewProvider", (args: { order: Order }) => {
        if (user?.userGroupId === args?.order?.providerGroupId) {
          const isFirstPage = page === 0;
          const isSameStatus =
            searchParams.get("status") === args?.order?.status;

          if (isFirstPage && isSameStatus) {
            handleListAddEvent(args.order);
          }
        } else {
          setOrdersToDisplay(state => {
            const indexOforderToDelete = state.findIndex(
              order => order?.id === args?.order?.id
            );
            let newOrderToDisplay = state.map(order => order);
            if (indexOforderToDelete >= 0 && newOrderToDisplay.length > 0) {
              newOrderToDisplay.splice(indexOforderToDelete, 1);
              setTotalCountOfItem(total => total - 1);
            }
            return newOrderToDisplay;
          });
        }
      });
      return () => {
        socket.off("assignNewProvider");
      };
    }
  }, [searchParams, ordersToDisplay]);

  useEffect(() => {
    setOrdersToDisplay(data?.getAllOrdersForStaff.orders ?? []);
    setTotalCountOfItem(data?.getAllOrdersForStaff?.totalCount);
  }, [data]);

  return (
    <div>
      <Modal
        opened={openSendToSupplierModal}
        onClose={() => setOpenSendToSupplierModal(false)}
      >
        <p
          style={{ fontSize: "14px" }}
        >{`You are about to update the status of ${listOrderToUpdate.length} orders to  : ${OrderStatus.WAITING_FOR_PRODUCTION}`}</p>
        <SendOrderToSupplierModal
          suppliers={(allProviders?.getAllProviders ?? []).map(
            (provider: UserGroup) => {
              return { id: provider.id, name: provider.name };
            }
          )}
          plural={listOrderToUpdate.length}
          onSubmit={(selectedSupplierId: string) => {
            if (!selectedSupplierId) {
              notifications.show({
                title: "You must select a provider",
                color: "red",
                message: "",
              });
              return;
            }
            startProduction(listOrderToUpdate, selectedSupplierId);
            setOpenSendToSupplierModal(false);
          }}
        />
      </Modal>
      <div style={{ display: "flex" }}>
        <PrecedentComponent />
        <SearchBar
          setSearchText={value => {
            setSearchText(value);
            setPage(0);
          }}
          searchText={searchText}
        />
        <Menu>
          <Menu.Target>
            <MantimeButton>
              <IconClick style={{ marginRight: "5px" }} /> Actions
            </MantimeButton>
          </Menu.Target>
          <Menu.Dropdown>
            {Object.keys(OrderStatus)
              .filter(
                status =>
                  searchParams.get("status") !== status &&
                  (isUserStaff(user) ||
                    (isUserProvider(user) &&
                      [
                        OrderStatus.WAITING_FOR_PRODUCTION,
                        OrderStatus.PRODUCING,
                        OrderStatus.PRODUCING_IN_TRANSIT,
                        OrderStatus.SHIPPED,
                        OrderStatus.ON_HOLD,
                      ].includes(status as OrderStatus)))
              )
              .map((item: string, index: number) => (
                <Menu.Item
                  key={index}
                  onClick={() => {
                    if (
                      item === OrderStatus.WAITING_FOR_PRODUCTION &&
                      (searchParams.get("status") === OrderStatus.DRAFT ||
                        searchParams.get("status") === OrderStatus.CONFIRMED)
                    ) {
                      setOpenSendToSupplierModal(true);
                      return;
                    }
                    if (
                      confirm(
                        "You are about to update the status of " +
                          listOrderToUpdate.length +
                          " orders to  : " +
                          item
                      )
                    ) {
                      updateOrderStatus({
                        variables: {
                          where: {
                            id: {
                              in: listOrderToUpdate.map(order => order.id),
                            },
                          },
                          data: {
                            status: {
                              set: item,
                            },
                          },
                        },
                      }).then(() => {
                        refreshPastille({
                          variables: {
                            args: listOrderToUpdate.map(order => ({
                              userGroupId: order.providerGroupId,
                              previousUserGroupId: order.providerGroupId,
                              orderId: order.id,
                              previousStatus: order.status,
                              currentStatus: item,
                            })),
                          },
                        });
                        setListOrderToUpdate([]);
                        refetch();
                      });
                    }
                  }}
                >
                  {item}
                </Menu.Item>
              ))}
          </Menu.Dropdown>
        </Menu>
      </div>
      <OrderListTable
        user={user}
        data={ordersToDisplay}
        navigate={(url: string) => {
          /*
            Page do not reload on search because values are managed by react states. navigate(-1) and go(-1) and history.goBack() cannot be used with new search params.
            So I decided to add a new entry on browser history with the search params
            https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
          */
          if (searchText && !location.search.includes(searchText)) {
            window.history.pushState(
              "",
              "",
              `${location.search}&search=${searchText}`
            );
          }
          navigate(url);
        }}
        status={searchParams.get("status")}
        setListOrderToUpdate={setListOrderToUpdate}
        toggleList={toggleList}
        selectedValues={listOrderToUpdate.map(order => order.id)}
        selectAll={(orderIds: string[]) =>
          setListOrderToUpdate(
            ordersToDisplay.filter(order => orderIds.includes(order.id))
          )
        }
        searchText={searchText}
      />
      {ordersToDisplay.length > 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {page > 0 && (
              <Button
                icon={<IconArrowLeft style={{ marginRight: "5px" }} />}
                value="Previous page"
                onClick={() => {
                  navigate(`/home?status=${getStatus()}&page=${page - 1}`);
                  setPage(page - 1);
                }}
              />
            )}
          </div>
          <div style={{ marginTop: "1rem" }}>
            Page {parseInt(searchParams.get("page") ?? "0") + 1}, total:{" "}
            {totalCountOfItem} order{ordersToDisplay.length > 1 ? "s" : ""}{" "}
            {listOrderToUpdate.length !== 0 && (
              <span>
                , {listOrderToUpdate.length} selected out of{" "}
                {ordersToDisplay.length} order
                {ordersToDisplay.length > 1 ? "s" : ""}
              </span>
            )}
          </div>
          <div>
            {ordersToDisplay.length % LIMIT_PAGINATION_QUANTITY === 0 && (
              <Button
                icon={<IconArrowRight style={{ marginRight: "5px" }} />}
                value="Next page"
                onClick={() => {
                  navigate(`/home?status=${getStatus()}&page=${page + 1}`);
                  setPage(page + 1);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
